import React, {useEffect, useState} from "react";
import {Anchor, Breadcrumbs, Button, Divider, Flex, Stack, TextInput, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {DataTable, DataTableSortStatus} from "mantine-datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import dayjs from "dayjs";
import {DatePicker, DatesRangeValue} from "@mantine/dates";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync";
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons/faEmptySet";
import sortBy from "lodash/sortBy";
import {useDebouncedValue} from "@mantine/hooks";
import AugmentedFoodService from "../../services/apps/AugmentedFoodService";
import {genericError} from "../../functions/genericError";
import {useSelector} from "react-redux";
import AddPatient from "./components/AddPatient";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";
import {faWrench} from "@fortawesome/pro-regular-svg-icons/faWrench";
import {showNotification} from "@mantine/notifications";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";

const AllPatients: React.FC = () => {
    const navigate = useNavigate();

    const [fetchingConfigurations, setFetchingConfigurations] = useState<boolean>(false)
    const [patients, setPatients] = useState<any[]>([])
    const [records, setRecords] = useState(patients);
    const [daySearchRange, setDaySearchRange] = useState<DatesRangeValue>();
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({columnAccessor: 'id', direction: 'asc'});
    const {translations} = useSelector((state: any) => state.lang);
    const [patientOpened, setPatientOpened] = useState<boolean>(false);
    const [selectedPatient, setSelectedPatient] = useState<any>();

    useEffect(() => {
        setFetchingConfigurations(true)
        AugmentedFoodService.listPatients()
            .then((r) => {
                setPatients(r)
                setRecords(r)
            })
            .catch(genericError)
            .finally(() => setFetchingConfigurations(false))
    }, [])


    useEffect(() => {
        const data = sortBy(patients, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    useEffect(() => {
        setRecords(
            patients.filter(({code, created_at}) => {
                if (
                    debouncedQuery !== '' &&
                    !`${code}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }

                if (
                    daySearchRange &&
                    daySearchRange[0] &&
                    daySearchRange[1] &&
                    (dayjs(daySearchRange[0]).isAfter(created_at, 'day') ||
                        dayjs(daySearchRange[1]).isBefore(created_at, 'day'))
                ) {
                    return false;
                }

                return true;
            })
        );
    }, [debouncedQuery, daySearchRange]);

    const createPatientCallback = (r) => {
        setPatientOpened(false)
        setSelectedPatient(undefined)
        if (r) {
            const newPatients = [r, ...patients.filter((p) => p.id !== r.id)];
            setPatients(newPatients)
            setRecords(newPatients)
            showNotification({
                title: 'Done',
                color: 'teal',
                message: '',
                icon: <FontAwesomeIcon icon={faCheck}/>,
                autoClose: 7000
            })
        }
    }

    const initForm = () => {

    }

    return <>
        <Breadcrumbs>
            <Anchor href={'#'} onClick={() => navigate('/apps')}>
                {translations.breadcrumbsAllApps ?? 'translations.breadcrumbsAllApps'}
            </Anchor>
            <Anchor href={'#'} onClick={() => navigate('/apps/food_exposure')}>
                Food Exposure
            </Anchor>
            <span>{translations.breadcrumbPatients ?? 'translations.breadcrumbPatients'}</span>
        </Breadcrumbs>
        <Divider mt={12} mb={12}/>

        <AddPatient opened={patientOpened} close={createPatientCallback} selectedPatient={selectedPatient}/>

        <Flex justify={'flex-end'}>
            <Button onClick={() => {
                setSelectedPatient(undefined);
                setPatientOpened(true)
            }} size={'sm'} variant={'outline'}>
                <FontAwesomeIcon icon={faPlus}/>
            </Button>
        </Flex>

        <Title mb={24}>{translations.breadcrumbPatients ?? 'translations.breadcrumbPatients'}</Title>
        <DataTable
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            columns={
                [
                    {accessor: 'id', sortable: true,},
                    {
                        accessor: 'code',
                        title: translations.tableCode ?? 'translations.tableCode',
                        sortable: true,
                        // @ts-ignore
                        filter: (
                            <TextInput
                                label="Code"
                                placeholder="Search by code..."
                                icon={<FontAwesomeIcon icon={faSearch}/>}
                                value={query}
                                onChange={(e) => setQuery(e.currentTarget.value)}
                            />
                        ),
                        filtering: query !== '',
                    },
                    {
                        accessor: 'created_at',
                        title: translations.tableCreateAt ?? 'translations.tableCreateAt',
                        render: ({created_at}) => dayjs(created_at).format('MMM DD YYYY'),
                        filter: ({close}) => (
                            <Stack>
                                <DatePicker
                                    maxDate={new Date()}
                                    type="range"
                                    value={daySearchRange}
                                    onChange={setDaySearchRange}
                                />
                                <Button
                                    disabled={!daySearchRange}
                                    color="red"
                                    onClick={() => {
                                        setDaySearchRange(undefined);
                                        close();
                                    }}
                                >
                                    Reset
                                </Button>
                            </Stack>
                        ),
                        filtering: Boolean(daySearchRange),
                    },
                    {
                        accessor: 'actions',
                        title: translations.tableActions ?? 'translations.tableActions',
                        width: 200,
                        render: (i) => {
                            return <>
                                <Button variant={'subtle'} color={'green'} title={'Show'}
                                        disabled={i.status !== 'completed'}
                                >
                                    <FontAwesomeIcon icon={faEye}/>
                                </Button>
                                <Button variant={'subtle'} color={'orange'} title={'Edit'} ml={4}
                                        onClick={() => {
                                            setSelectedPatient(i);
                                            setPatientOpened(true);
                                        }}
                                >
                                    <FontAwesomeIcon icon={faWrench}/>
                                </Button>
                            </>
                        }
                    }
                ]
            }
            records={records}
            minHeight={400}
            noRecordsIcon={
                <FontAwesomeIcon icon={fetchingConfigurations ? faSync : faEmptySet}
                                 spin={fetchingConfigurations}
                                 size={'xl'} style={{marginBottom: 12}}/>
            }
            noRecordsText={
                fetchingConfigurations ? (translations.fetchLoading ?? "translations.fetchLoading") : (translations.fetchNoResults ?? "translations.fetchNoResults")
            }
        />
    </>
}
export default AllPatients;