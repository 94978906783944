import React, {forwardRef, useEffect, useState} from "react";
import CourseService from "../../../services/CourseService";
import {useParams} from "react-router-dom";
import {IFeCourseQuestion} from "../../../interfaces/IFeCourseQuestion";
import {genericError} from "../../../functions/genericError";
import {Box, Button, Divider, Flex, List, Loader, Radio, Text, Title} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "@mantine/form";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import {ICourse} from "../../../interfaces/ICourse";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";


const CourseQuestions = forwardRef((props: {
    course: ICourse,
    callback: any
    goBack: any,
}, cpRef) => {

    const {id} = useParams();
    const [fetching, setFetching] = useState<boolean>(false)
    const [questions, setQuestions] = useState<IFeCourseQuestion[]>([])
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const form = useForm({
        initialValues: {
            questions
        },
        validate: {
            questions: (value: IFeCourseQuestion[]) => {
                if (value.length === 0 || value.length < questions.length) {
                    showNotification({
                        message: 'Prima di inviare il questionario devi rispondere a tutte le domande',
                        color: 'red',
                        autoClose: 12000
                    });
                    return 'Prima di inviare il questionario devi rispondere a tutte le domande';
                }
            }
        }
    });

    const formReset = useForm({
        initialValues: {},
    });

    useEffect(() => {
        if (!props.course.user_has_course.answered_the_questions) {
            setFetching(true)
            CourseService.getCourseQuestions(id)
                .then(setQuestions)
                .catch(genericError)
                .finally(() => setFetching(false))
        }
    }, [])


    const submitReset = () => {
        setIsSaving(true)
        CourseService.courseProgressReset(parseInt(id))
            .then((data) => {
                showNotification({
                    message: data.message,
                    color: 'teal',
                    autoClose: 12000
                })
                props.callback(data.uhc, data.data)

            })
            .catch((e) => {
                genericError(e)
                if (e.response && e.response.data) {
                    props.callback(e.response.data.uhc, e.response.data.data)
                }
            })
            .finally(() => setIsSaving(false))
    }

    const confirmSubmitReset = () => openConfirmModal({
        title: 'Sei sicur* di voler procedere al reset del corso?',
        children: (<>
                <Text size="sm">
                    Sei nella modalità <b>"ECM"</b>, per poter sostenere di nuovo la prova dovrai procedere al reset del
                    corso e rivedere tutte le
                    lezioni.</Text>
            </>
        ),
        labels: {confirm: "Procedi con il reset", cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'teal'},
        onCancel: () => showNotification({
            color: 'teal',
            icon: <FontAwesomeIcon icon={faCheck}/>,
            title: 'Success',
            message: 'Azione annullata'
        }),
        onConfirm: () => submitReset(),
    });

    const submit = (values) => {
        const answers = [];
        for (let x = 0; x < questions.length; x++) {
            answers.push({id: questions[x].id, value: values.questions[x]})
        }
        setIsSaving(true)
        CourseService.answerTheQuestions({answers}, parseInt(id))
            .then((data) => {
                showNotification({
                    message: data.message,
                    color: 'teal',
                    autoClose: 12000
                })
                props.callback(data.uhc, data.data)

            })
            .catch((e) => {
                genericError(e)
                if (e.response && e.response.data) {
                    props.callback(e.response.data.uhc, e.response.data.data)
                }
            })
            .finally(() => setIsSaving(false))
    }

    const confirmSubmit = (values: any) => openConfirmModal({
        title: 'Sei sicur* di voler inviare le risposte?',
        children: (<>
                <Text size="sm">
                    Sei nella modalità <b>"ECM"</b>: il non superamento della prova ti costringerà a rivedere tutte le
                    lezioni.</Text>
                <Text size="sm">
                    Sono ammessi 5 tentativi. Se dovessi finire tutte le possibilità contatta il nostro
                    supporto.
                </Text>
                <Text size="sm">Questo è il tuo tentativo
                    n. <strong>{props.course?.user_has_course?.number_of_attempts + 1}</strong> di
                    <strong> 5</strong></Text>
            </>
        ),
        labels: {confirm: "Procedi con l'invio delle risposte", cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'teal'},
        onCancel: () => showNotification({
            color: 'teal',
            icon: <FontAwesomeIcon icon={faCheck}/>,
            title: 'Success',
            message: 'Azione annullata'
        }),
        onConfirm: () => submit(values),
    });

    return <div>

        {fetching ?
            <div style={{textAlign: 'center', marginTop: 60}}>
                <Title order={2}>Generazione domande in corso...</Title>
                <Loader variant={'bars'} size="xl" style={{margin: '36px auto'}}/>
            </div> : <>

                <Flex justify={'space-between'} align={'center'} my={24}>
                    <Title order={2}>Test di fine corso</Title>
                    <Button variant={'outline'}
                            leftIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            onClick={props.goBack}
                    >Torna alle lezioni</Button>
                </Flex>
                {props.course.user_has_course.answered_the_questions ?
                    <Text>Hai già inviato il test per questo corso</Text>
                    : null

                }

                {props.course.user_has_course.wrong_test_answers ? <>      <Flex direction={'column'} gap={'xl'}>
                    <Flex direction={'column'} gap={'md'}>
                        <Title color={'red'} order={3}>Test non superato</Title>
                        <Text fz={'sm'}>
                            Sei nella modalità "ECM": non avendo superato la prova, per poterla sostenere di nuovo
                            dovrai procedere al reset del corso
                            e rivedere tutte le lezioni.
                        </Text>
                        {!props.course.user_has_course.feedback_sent ? <>
                            <Text fz={'sm'}>
                                Ti ricordiamo che, nonostante il reset del corso, il questionario di gradimento può
                                essere compilato in qualsiasi
                                momento.
                            </Text>
                        </> : null
                        }
                    </Flex>
                    <form onSubmit={formReset.onSubmit(confirmSubmitReset)}>
                        <Button color={'red'} type={'submit'} disabled={isSaving} loading={isSaving} size={'md'}>Reset
                            del corso</Button>

                    </form>
                </Flex></> : <></>}
                {!props.course.user_has_course.answered_the_questions && !props.course.user_has_course.wrong_test_answers ? <>
                    <Box w={'100%'} mb={60}>

                        <form onSubmit={form.onSubmit(confirmSubmit)}>
                            <List type="ordered">
                                {questions.map((q: IFeCourseQuestion, index: number) => <List.Item
                                    key={`'q-${index}`}
                                    mb={24}>
                                    <Text>{q.description}</Text>
                                    <Radio.Group
                                        {...form.getInputProps(`questions.${index}`, {type: 'checkbox'})}
                                        //      orientation="vertical"
                                        withAsterisk
                                    >
                                        {q.answers.map((a: string, i2: number) => <Radio key={`'q-${index}-a-${i2}`}
                                                                                         mt={6}
                                                                                         mb={6}
                                                                                         value={a}
                                                                                         label={a}/>)}
                                    </Radio.Group>
                                </List.Item>)}
                            </List>

                            <Divider mt={12} mb={24}/>

                            <Flex justify={'flex-end'}>
                                <Button type={'submit'} disabled={isSaving} loading={isSaving}>Invia le
                                    risposte</Button>
                            </Flex>
                        </form>
                    </Box>
                </> : <></>}

            </>
        }
    </div>
});

export default CourseQuestions