import React from "react";
import {
    ActionIcon,
    Burger, Flex,
    Header,
    MediaQuery,
    useMantineTheme
} from "@mantine/core";
import Logo from "./Logo";
import Customization from "../components/Customization";
import {useDispatch, useSelector} from "react-redux";
import {toggleSidebar, toggleSidebarSize} from "../store/theme/themeSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSidebar} from "@fortawesome/pro-regular-svg-icons/faSidebar";

const CHeader: React.FC = () => {

    const {user} = useSelector((state: any) => state.auth)
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const {sidebarOpened} = useSelector((state: any) => state.theme)

    return <Header height={{base: 50, md: 70}} p="md" className={'no-print'}>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between'
        }}>
            <MediaQuery largerThan="lg" styles={{display: 'none'}}>
                <Burger
                    className={'no-print'}
                    opened={sidebarOpened}
                    onClick={() => dispatch(toggleSidebar())}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                />
            </MediaQuery>

            <Flex>
                {user &&
                <MediaQuery smallerThan="lg" styles={{display: 'none'}}>
                    <ActionIcon onClick={() => dispatch(toggleSidebarSize())}>
                        <FontAwesomeIcon icon={faSidebar}/>
                    </ActionIcon>
                </MediaQuery>
                }
                <Logo/>
            </Flex>

            <MediaQuery smallerThan="lg" styles={{display: 'none'}}>
                <div>
                    <Customization/>
                </div>
            </MediaQuery>
        </div>
    </Header>
}
export default CHeader