import React, {useEffect, useState} from "react";
import {genericError} from "../../functions/genericError";
import {Divider, Loader, Title} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TestService from "../../services/admin/TestService";
import {faXmarkCircle} from "@fortawesome/pro-regular-svg-icons/faXmarkCircle";
import {useParams} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {faEngineWarning} from "@fortawesome/pro-regular-svg-icons/faEngineWarning";
import {ICourse} from "../../interfaces/ICourse";


const TestCourse = () => {

    const [fetching, setFetching] = useState<boolean>(false);
    const [course, setCourse] = useState<ICourse>();
    const [logs, setLogs] = useState<any>();
    const {id} = useParams();

    useEffect(() => {
        setFetching(true)
        TestService.checkCourse(parseInt(id))
            .then((r) => {
                setCourse(r.course)
                const parseLog = JSON.parse(r.log)
                setLogs(parseLog)
            })
            .catch(genericError)
            .finally(() => {
                setFetching(false)
            })
    }, [])

    return <>
        {
            fetching ?
                <div style={{textAlign: 'center', flex: 1, marginTop: 24}}>
                    <Title order={2}>Recupero corso...</Title>
                    <Loader variant={'bars'} size="xl" style={{margin: '36px auto'}}/>
                </div> :
                <>
                    <Title order={1} mb={12}>Corso {course?.name}</Title>
                    <Title order={3} mb={24}>ERRORS: {logs?.errors} and WARNINGS: {logs?.warnings}</Title>

                    <DataTable
                        striped
                        withBorder
                        records={logs?.course}
                        columns={[
                            {
                                accessor: 'level', title: 'Level', render: (e: any) => {
                                    return <FontAwesomeIcon
                                        color={e.level === 'critical' ? 'red' : 'yellow'}
                                        icon={e.level === 'critical' ? faXmarkCircle : faEngineWarning}/>
                                }
                            },
                            {accessor: 'message', title: 'Message'}
                        ]
                        }/>

                    <Divider mb={24} mt={24}/>
                    <Title order={2} mb={24}>Sezioni</Title>
                    {logs ?
                        Object.keys(logs.sections).map((test, index) =>
                            <div key={'table-sections' + index} style={{marginBottom: 18}}>
                                <Title order={4}>{test}</Title>
                                <DataTable
                                    withBorder
                                    records={logs.sections[test]}
                                    columns={[
                                        {
                                            accessor: 'level', title: 'Level', render: (e: any) => {
                                                return <FontAwesomeIcon
                                                    color={e.level === 'critical' ? 'red' : 'yellow'}
                                                    icon={e.level === 'critical' ? faXmarkCircle : faEngineWarning}/>
                                            }
                                        },
                                        {accessor: 'message', title: 'Message'}
                                    ]
                                    }/>
                            </div>
                        )
                        : null
                    }
                    <br/>
                    <Title order={2} mb={24}>Domande</Title>
                    {logs ?
                        Object.keys(logs.questions).map((test, index) =>
                            <div key={'table-questions' + test + index} style={{marginBottom: 18}}>
                                <Title order={4}>{test}</Title>
                                <DataTable
                                    withBorder
                                    records={logs.questions[test]}
                                    columns={[
                                        {
                                            accessor: 'level', title: 'Level', render: (e: any) => {
                                                return <FontAwesomeIcon
                                                    color={e.level === 'critical' ? 'red' : 'yellow'}
                                                    icon={e.level === 'critical' ? faXmarkCircle : faEngineWarning}/>
                                            }
                                        },
                                        {accessor: 'message', title: 'Message'}
                                    ]
                                    }/>
                            </div>
                        )
                        : null
                    }
                </>
        }
        <br/>
    </>
}
export default TestCourse;