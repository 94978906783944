import React from "react";
import { Button, Card, Flex, Grid, Image, Title, } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useDocumentTitle, useFavicon, useMediaQuery } from "@mantine/hooks";
import { useSelector } from "react-redux";


const CoursePage: React.FC = () => {
  const { translations } = useSelector((state: any) => state.lang);
  const navigate = useNavigate();
  useDocumentTitle('BECOME | Tutte le app');
  useFavicon('/favicon.ico');
  const matches = useMediaQuery('(max-width: 900px)');

  const apps = [
    {
      image: '/images/Become_Augmented_Performance_Logo.png',
      title: 'Augmented Performance',
      button: translations.buttonEsplora ?? "translations.buttonEsplora",
      link: `./augmented_performance`,
      enabled: true
    },
    {
      image: '/images/Become_Augmented_Exposure_Logo.jpg',
      title: 'Augmented Food Exposure',
      button: translations.buttonEsplora ?? "translations.buttonEsplora",
      link: `./food_exposure`,
      enabled: true
    },
    {
      image: '/images/AugmentedLifeSuite.png',
      title: 'Augmented Life Experience',
      button: translations.buttonEsplora ?? "translations.buttonEsplora",
      link: `./augmented_lifesuite`,
      enabled: true
    },
  ];

  return <>
    <div style={{ display: 'relative' }}>

      <Title mb={36}>{translations.allApps ?? "translations.allApps"}</Title>

      <Grid justify={matches ? 'center' : 'flex-start'}>
        {apps.map((app: any, index: number) =>
            <Grid.Col key={'app-' + index} span={'content'}>
              <Card withBorder className={'flex-card'}>
                <Card.Section style={{ flex: 1 }}>
                  <Flex justify={'center'} direction={'column'}
                        style={{ flex: 1 }} align={'center'}>

                    <Image src={app.image} alt={app.title} height={200}
                           width={200}
                           radius={12}/>
                    <Button onClick={() => navigate(app.link)}
                            variant="light"
                            color="blue"
                            disabled={!app.enabled}
                            fullWidth
                            mt="md" radius="md">
                      {app.button}
                    </Button>


                  </Flex>
                </Card.Section>
              </Card>
            </Grid.Col>
        )}


      </Grid>


    </div>
  </>
}

export default CoursePage;