import {IUser} from "../interfaces/IUser";
import {IProfessional} from "../interfaces/IProfessional";
import {Interceptor} from "../pages/auth/interceptor";

export default class UserService {


    static get = async (): Promise<IUser> => {
        const url = `${process.env["REACT_APP_URL"]}/api/user`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static oauthShortLivedToken = async (device_name: string): Promise<string> => {
        const url = `${process.env["REACT_APP_URL"]}/api/auth/deviceOauthInit`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {
                    device_name
                }).then((res: any) => {
                    return res.data;
                });
            });
    }

    static update = async (id: number, data: any[]): Promise<IUser> => {
        const url = `${process.env["REACT_APP_URL"]}/api/user/updateUserInfo/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.put(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static updateProfessional = async (data: any[]): Promise<IProfessional> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/updateUserProfessional`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static getProfessional = async (): Promise<IProfessional> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/getUserProfessional`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static getMaterials = async (lang: string): Promise<any[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/getMaterials?lang=${lang}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }


    static connectDevice = async (source: number, destination: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/connect`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {source, destination}).then((res: any) => {
                    return res.data;
                });
            });
    }

    static disconnectDevice = async (source: number, destination: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/removeDevice`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {source, destination}).then((res: any) => {
                    return res.data;
                });
            });
    }
}