import React, {useEffect, useState} from "react";
import {Button, List, Notification, Title} from "@mantine/core";
import UserService from "../services/UserService";
import CourseService from "../services/CourseService";
import {genericError} from "../functions/genericError";
import {useDocumentTitle, useFavicon} from "@mantine/hooks";
import {useSelector} from "react-redux";

const Materials: React.FC = () => {

    useDocumentTitle('BECOME | Materiali');
    useFavicon('/favicon.ico');
    const [items, setItems] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const [generating, setGenerating] = useState<boolean>(false)
    const [wait, setWait] = useState<boolean>(false)
    const [downloading, setDownloading] = useState<string>('')
    const {lang, translations} = useSelector((state: any) => state.lang);

    const fetch = async () => {
        setFetching(true)
        UserService.getMaterials(lang)
            .then((r) => {
                setItems(r)
            })
            .finally(() => setFetching(false))
    }

    useEffect(() => {
        fetch();
    }, [lang])

    const generateCert = (id) => {
        setGenerating(true)

        CourseService.generateCertificate(parseInt(id))
            .then(() => {
                setWait(true)
            })
            .catch(genericError)
            .finally(() => {
                setTimeout(() => {
                    setGenerating(false);
                }, 10000);
            })
    }

    const downloadCert = (id) => {
        setGenerating(true)

        CourseService.downloadCertificate(parseInt(id))
            .then((data: { url: string, name: string }) => {
                const link = document.createElement('a');
                link.href = data.url;
                link.target = '_blank';
                link.download = data.name;
                link.dispatchEvent(new MouseEvent('click'));
            })
            .catch(genericError)
            .finally(() => {
                setGenerating(false);
            })
    }

    const download = (name: string, id: number) => {
        setDownloading(name)
        CourseService.download(name, id)
            .then((url: string) => {
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = name;
                link.dispatchEvent(new MouseEvent('click'));
            })
            .catch(genericError)
            .finally(() => {
                setDownloading('');
            })
    }

    return <>
        <Title>{translations.sezione ?? "translations.sezione"}</Title>

        {wait &&
            <Notification title={translations.notification ?? "translations.notification"}>
                {translations.notificationText ?? "translations.notificationText"}<br/>
                {translations.notificationTextTwo ?? "translations.notificationTextTwo"}
            </Notification>
        }

        <List spacing={30} mt={32}>
            {items.map(({certificate, materials, course, id}, index: number) =>
                <List.Item key={'i.' + index}>
                    <span style={{fontWeight: "bold"}}>
                    {course}</span>
                    <List spacing={20}>
                        <List.Item>
                            {translations.certificato ?? "translations.certificato"}
                            <Button loading={generating}
                                    disabled={generating}
                                    ml={24}
                                    compact
                                    onClick={() => {
                                        if (certificate) {
                                            downloadCert(id)
                                        } else {
                                            generateCert(id)
                                        }
                                    }
                                    }>{certificate ? (translations.scarica ?? "translations.scarica") : (translations.genera ?? "translations.genera")}</Button>
                        </List.Item>
                        {materials.map(({name}: any, n: number) => <List.Item key={'i.' + index + '-' + n}>

                            {name}: <Button
                            ml={24}
                            compact
                            loading={downloading === name}
                            disabled={downloading === name}
                            onClick={() => download(name, id)}>{translations.scarica ?? "translations.scarica"}</Button>
                        </List.Item>)}
                    </List>

                </List.Item>
            )}

        </List>
    </>
}

export default Materials;