import {
    Button,
    Container,
    createStyles,
    LoadingOverlay,
    Paper,
    PasswordInput,
    Popover,
    Progress,
    rem,
    Stack,
    Text,
    Title,
} from '@mantine/core';
import {IconArrowLeft} from '@tabler/icons-react';
import {useForm} from "@mantine/form";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {getStrength, PasswordRequirement} from "./registration";
import {logout, setUser} from "../../store/auth/authSlice";
import AuthService from "../../services/AuthService";
import {genericError} from "../../functions/genericError";
import md5 from "crypto-js/md5";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));

export default function ForceChangePw() {

    const dispatch = useDispatch();
    const {classes} = useStyles();
    const {tempKey, tempValue, email} = useSelector((store: any) => store.auth);
    const [popoverOpened, setPopoverOpened] = useState(false);
    const {translations} = useSelector((state: any) => state.lang)
    const [inProgress, setInProgress] = useState<boolean>(false);

    const form: any = useForm({
        initialValues: {
            password: "",
            confirm_password: "",
        },

        validate: {
            password: (value) => (/[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[$&+,:;=?@#|'<>.^*()%!-]/.test(value) ? null : (translations.validatePw ?? "translations.validatePw")),
            confirm_password: (value) => (value === form.values.password ? null : (translations.pwNotMatch ?? "translations.pwNotMatch")),
        },
    });

    const requirements = [
        {re: /[0-9]/, label: 'Includes number', translationKey: 'reqPwLabelOne'},
        {re: /[a-z]/, label: 'Includes lowercase letter', translationKey: 'reqPwLabelTwo'},
        {re: /[A-Z]/, label: 'Includes uppercase letter', translationKey: 'reqPwLabelThree'},
        {re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol', translationKey: 'reqPwLabelFour'},
    ];

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={translations[requirement.translationKey] ?? "reqPwLabel da 1 a 4"}
                             meets={requirement.re.test(form.values.password)}/>
    ));
    const strength = getStrength(form.values.password);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    const onSubmit = (values: any) => {
        setInProgress(true)
        const hash = md5(values.password).toString();

        AuthService.forceChangePw({
            key: tempKey,
            value: tempValue,
            email: email,
            password: hash
        })
            .then(response => {
                if (response.token && response.user && response.state) {
                    dispatch(setUser({
                        authState: response.state,
                        token: response.token,
                        user: response.user,
                    }))
                }
            })

            .catch((error) => {
                genericError(error)

            })
            .finally(() => setInProgress(false))
    }


    return (
        <Container size={460} my={30}>
            <LoadingOverlay visible={inProgress} overlayBlur={2} transitionDuration={1000}/>
            <Title className={classes.title} align="center">
                {translations.insertPw ?? "translations.insertPw"}
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
                {translations.insertPwText ?? "translations.insertPwText"}
            </Text>

            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <LoadingOverlay visible={inProgress} overlayBlur={2}/>

                        <Popover opened={popoverOpened} position="bottom" width="target"
                                 transitionProps={{transition: 'pop'}}>
                            <Popover.Target>
                                <div
                                    onFocusCapture={() => setPopoverOpened(true)}
                                    onBlurCapture={() => setPopoverOpened(false)}
                                >
                                    <PasswordInput
                                        withAsterisk
                                        label={translations.pwLabel ?? "translations.pwLabel"}
                                        placeholder={translations.pwLabel ?? "translations.pwLabel"}
                                        {...form.getInputProps('password')}
                                        className={classes.controls}
                                        style={{minWidth: 250}}
                                    />
                                    <PasswordInput
                                        withAsterisk
                                        label={translations.confirmPwLabel ?? "translations.confirmPwLabel"}
                                        placeholder={translations.confirmPwLabel ?? "translations.confirmPwLabel"}
                                        {...form.getInputProps('confirm_password')}
                                    />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Progress color={color} value={strength} size={5} mb="xs"/>
                                <PasswordRequirement label={translations.passwordLabel ?? "translations.passwordLabel"}
                                                     meets={form.values.password.length > 5}/>
                                {checks}
                            </Popover.Dropdown>

                        </Popover>


                        <Button className={classes.control} type="submit"
                                fullWidth>{translations.loginFCPw ?? "translations.loginFCPw"}</Button>
                    </Stack>
                </form>

                <Button variant={'link'}
                        mt={24}
                        leftIcon={<IconArrowLeft size={rem(12)} stroke={1.5}/>}
                        size="sm"
                        onClick={() => {
                            AuthService.logout()
                                .then(() => dispatch(logout()))
                                .catch(genericError)
                        }}>
                    <Text fz='sm' weight={200}>{translations.backLogin ?? "translations.backLogin"}</Text>
                </Button>
            </Paper>
        </Container>
    );
}