import React, {forwardRef} from "react";
import {ActionIcon, Button, Flex, Group, Select, Text, Tooltip} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWifi} from "@fortawesome/pro-regular-svg-icons/faWifi";
import {faWifiSlash} from "@fortawesome/pro-regular-svg-icons/faWifiSlash";
import {setAuthState} from "../store/auth/authSlice";
import {faMoon} from "@fortawesome/pro-regular-svg-icons/faMoon";
import {faSunAlt} from "@fortawesome/pro-regular-svg-icons/faSunAlt";
import {faMagnifyingGlassMinus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMinus";
import {faMagnifyingGlassPlus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus";
import {useNetwork} from "@mantine/hooks";
import {useDispatch, useSelector} from "react-redux";
import {setFontSize, toggleColor} from "../store/theme/themeSlice";
import {useNavigate} from "react-router-dom";
import {setLanguage} from "../store/lang/langSlice";

const Customization: React.FC<{ isSidebar?: boolean }> = ({isSidebar = false}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const networkStatus = useNetwork();
    const {retrievingLang, lang, translations} = useSelector((state: any) => state.lang)
    const {user, state} = useSelector((state: any) => state.auth)
    const {color, fontSize} = useSelector((state: any) => state.theme);


    const updateTheme = async (newColor: string) => {
        dispatch(toggleColor(newColor))
        /* TODO
        Auth.currentAuthenticatedUser().then(async (u) => {
            await Auth.updateUserAttributes(u, {'custom:theme': newColor})
            await Auth.currentUserInfo();
        })*/
    }

    const updateFontSize = async (size: number) => {
        dispatch(setFontSize(size))
        /* TODO
        Auth.currentAuthenticatedUser().then(async (u) => {
            await Auth.updateUserAttributes(u, {'custom:fontSize': size.toString()})
            await Auth.currentUserInfo();
        }) */
    }

    const changeLanguage = async (lang: any) => {
        dispatch(setLanguage(lang))
    }

    const languages = [{label: "Italiano", value: "it", icon: "it"}, {
        label: "English",
        icon: "gb",
        value: "en",
    }, {
        label: "Portugués",
        value: "pt",
        icon: "pt",
    }, {
        label: "Portugués (BR)",
        value: "br",
        icon: "br",
    }, {
        label: "Español",
        value: "es",
        icon: "es",
    },
    ]

    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        label: string;
        value: string;
        icon: string;
    }

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({label, icon, value, ...others}: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <span className={"fi fi-" + icon}></span>
                    <Text>{label}</Text>
                </Group>
            </div>
        )
    );

    return <Flex direction={isSidebar ? "column" : "row"} gap={"md"}>
        <Flex align={"center"} bg={color === 'light' ? 'white' : 'dark'}
              justify={isSidebar ? 'center' : 'flex-start'}
              style={{padding: '4px 12px', borderRadius: 4, maxWidth: 365}}>
            {!retrievingLang ? (user ?
                    <Text size={"sm"} mr={16} color={'secondary'}>
                        <FontAwesomeIcon
                            style={{marginRight: 12}}
                            icon={networkStatus.online ? faWifi : faWifiSlash}
                            color={networkStatus.online ? 'teal' : 'red'}/>
                        {networkStatus.online ? 'Online' : 'Offline'}
                    </Text> : (
                        state === 'SIGN_UP' ?
                            <Button mr={16} size={'xs'}
                                    color={color === 'light' ? 'dark' : 'white'}
                                    onClick={() => dispatch(setAuthState('SIGN_IN'))}>
                                Login
                            </Button> :
                            <Button mr={16} size={'xs'}
                                    color={color === 'light' ? 'dark' : 'white'}
                                    onClick={() => navigate('/register')}>
                                {translations.createAccount}
                            </Button>
                    )
            ) : null}
        </Flex>
        <Flex align={"center"} bg={color === 'light' ? 'white' : 'dark'}
              justify={isSidebar ? 'center' : 'flex-start'}
              style={{padding: '4px 12px', borderRadius: 4, maxWidth: 365}}>
            <Tooltip withinPortal
                     label={`${translations.selectTheme} ${color === 'light' ? translations.themeDark : translations.themeLight}`}>

                <ActionIcon variant="outline"
                            color={color === 'light' ? 'dark' : 'white'}
                            onClick={() => {
                                updateTheme(color === 'dark' ? 'light' : 'dark');
                            }}>
                    <FontAwesomeIcon
                        icon={color === 'light' ? faMoon : faSunAlt}/>
                </ActionIcon>
            </Tooltip>

            <Tooltip withinPortal
                     label={translations.fontDecrease}>
                <ActionIcon variant="outline"
                            ml={12}
                            color={color === 'light' ? 'dark' : 'white'}
                            onClick={() => {
                                updateFontSize(fontSize - 1);
                            }}>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlassMinus}/>
                </ActionIcon>
            </Tooltip>

            <Tooltip withinPortal
                     label={translations.fontIncrease}>
                <ActionIcon variant="outline"
                            ml={12}
                            color={color === 'light' ? 'dark' : 'white'}
                            onClick={() => {
                                updateFontSize(fontSize + 1);
                            }}>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlassPlus}/>
                </ActionIcon>
            </Tooltip>
            <Tooltip withinPortal
                     label={translations.selectLanguage}
            >
                <Select data={languages}
                        itemComponent={SelectItem}
                        dropdownPosition={isSidebar ? "top" : "bottom"}
                        value={lang}
                        onChange={changeLanguage}
                        icon={<span
                            className={"fi fi-" + languages.find(item => item.value === lang)?.icon}
                        ></span>}
                        style={{marginLeft: 12}}
                />
            </Tooltip>

        </Flex>
    </Flex>
}
export default Customization;