import {ICourse} from "../interfaces/ICourse";
import {IUserCourseProgress} from "../interfaces/IUserCourseProgress";
import {IFeCourseQuestion} from "../interfaces/IFeCourseQuestion";
import {Interceptor} from "../pages/auth/interceptor";

export default class CourseService {


    static list = async (): Promise<ICourse[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/ecm`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data.data;
                });
            });
    }

    static get = async (id: string): Promise<ICourse> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/get/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    //   static updateSponsor = async (course_id: number, values: any): Promise<string> => {
    //       const url = `${process.env["REACT_APP_URL"]}/api/courses/updateSponsor/${course_id}`;
    //       const sponsorValue = {sponsor: values,}
    //       return await Interceptor().then(
    //           (ax) => {
    //               return ax.post(url, sponsorValue).then((res: any) => {
    //                   return res.data;
    //               });
    //           });
    //   }

    static getCurrentUserCourseProgress = async (id: string): Promise<IUserCourseProgress> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/getCurrentUserCourseProgress/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static getCourseQuestions = async (id: string): Promise<IFeCourseQuestion[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/getCourseQuestions/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }


    static store = async (
        course_id: number,
        step: number,
        current_time: number,
    ): Promise<IUserCourseProgress> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/progress/${course_id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {
                    step, current_time
                }).then((res: any) => {
                    return res.data;
                });
            });
    }


    static generateCertificate = async (course_id: number): Promise<string> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/certificate/${course_id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static downloadCertificate = async (course_id: number): Promise<{ url: string, name: string }> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/certificate/${course_id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }


    static sendFeedback = async (values: any, course_id: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/sendFeedback/${course_id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, values).then((res: any) => {
                    return res.data;
                });
            });
    }

    static answerTheQuestions = async (values: any, course_id: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/answerTheQuestions/${course_id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, values).then((res: any) => {
                    return res.data;
                });
            });
    }

    static courseProgressReset = async (course_id: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/courseProgressReset/${course_id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static download = async (name: string, courseId: number): Promise<string> => {
        const url = `${process.env["REACT_APP_URL"]}/api/courses/downloadMaterial/${courseId}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {name}).then((res: any) => {
                    return res.data;
                });
            });
    }


}