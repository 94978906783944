import React, {useState} from "react";
import {ActionIcon, Group, SimpleGrid, Text, Title,} from '@mantine/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {faYoutube} from "@fortawesome/free-brands-svg-icons/faYoutube";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons/faLinkedin";
import {ContactIconsList} from "../components/ContactIcons";
import {useDocumentTitle, useFavicon} from "@mantine/hooks";
import {useForm} from "@mantine/form";
import {useSelector} from "react-redux";


const social = [{
    icon: faTwitter,
    url: 'https://twitter.com/BecomeHub'
}, {
    icon: faYoutube,
    url: 'https://www.youtube.com/channel/UCpVJQlNHglufxvOYFo3agmQ'
}, {
    icon: faInstagram,
    url: 'https://www.instagram.com/become.hub'
}, {
    icon: faLinkedin,
    url: 'https://www.linkedin.com/company/become-hub/'
}];

const Support: React.FC = () => {
    const {translations} = useSelector((state: any) => state.lang)
    const [inProgress, setInProgress] = useState<boolean>(false);

    useDocumentTitle('BECOME | Support');
    useFavicon('/favicon.ico');
    const form = useForm({
        initialValues: {} as any,
        validate: {}
    });
    const onSubmit = (values) => {
        console.log('values', values)
    }

    const icons = social.map((social, index) => (
        <ActionIcon key={index} size={28} variant="transparent" onClick={() => window.open(social.url, '_blank')}>
            <FontAwesomeIcon icon={social.icon} size={'lg'}/>
        </ActionIcon>
    ));

    return (
        <div>
            <SimpleGrid cols={2} spacing={50} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                <div>
                    <Title>{translations.contattaci ?? "translations.contattaci"}</Title>
                    <Text mt="sm" mb={30}>
                        {translations.sendEmail ?? "translations.sendEmail"}
                    </Text>

                    <ContactIconsList/>

                    <Group mt="xl">{icons}</Group>
                </div>
                {/*
                <div>
                    <Title mb={16}><FontAwesomeIcon icon={faBug} style={{marginRight: 10}}/>{translations.bug ?? "translations.bug"}</Title>
                    <form onSubmit={form.onSubmit(onSubmit)}>
                        <Stack>
                            <TextInput label={translations.titleBug ?? "translations.titleBug"}   {...form.getInputProps('name')}/>
                            <Textarea label={translations.descriptionBug}
                                      {...form.getInputProps('comments')}
                                      minRows={8} autosize/>
                            <Flex justify={'flex-end'}>
                                <Button type={'submit'}>{translations.sendReport ?? "translations.sendReport"}</Button>
                            </Flex>
                        </Stack>
                    </form>
                </div>
                */}
            </SimpleGrid>
        </div>
    );
}
export default Support;