import {Interceptor} from "../../pages/auth/interceptor";


export default class TestService {


    static checkCourses = async (): Promise<any[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/checkCourses`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static checkCourse = async (id: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/checkCourse/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static launchTests = async (): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/testAll`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url).then((res: any) => {
                    return res.data;
                });
            });
    }

}