import React, {useEffect, useState} from "react";
import {Alert, Button, Group, Modal, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import AugmentedFoodService from "../../../services/apps/AugmentedFoodService";
import {genericError} from "../../../functions/genericError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import {useSelector} from "react-redux";

const AddPatient: React.FC<{
    opened: any,
    close: any,
    selectedPatient?: any
}> = ({opened, close, selectedPatient}) => {
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const {translations} = useSelector((state: any) => state.lang);
    const form = useForm({
            initialValues: {
                code: '',
                baseline: 30,
                variation: 5
            },

            validate:
                {
                    code: (value) => value.length < 3 ? translations.validateCode : null
                }
            ,
        })
    ;

    useEffect(() => {
        if (opened) {
            form.reset();
            if (selectedPatient) {
                form.setValues({
                    code: selectedPatient.code,
                    baseline: selectedPatient.baseline ?? 0,
                    variation: selectedPatient.variation ?? 0
                });
            }
        }
    }, [opened])

    const onSubmit = (values: any) => {
        setIsSaving(true)
        if (selectedPatient && selectedPatient.id) {
            AugmentedFoodService
                .updatePatient(selectedPatient.id, values)
                .then((r) => {
                    close(r);
                })
                .catch(genericError)
                .finally(() => setIsSaving(false))
        } else {
            AugmentedFoodService
                .createPatient(values)
                .then((r) => {
                    close(r);
                })
                .catch(genericError)
                .finally(() => setIsSaving(false))
        }
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => close(null)}
                title={translations.modalTitleP ?? "translations.modalTitleP"}
                zIndex={999}
                lockScroll={false}
                closeOnClickOutside={false}>
                <Alert icon={<FontAwesomeIcon icon={faCircleInfo}/>}
                       title={translations.alertWarning ?? "translations.alertWarning"} color="yellow"
                       withCloseButton
                       mb={12}
                       variant="outline">
                    {translations.patientAlert ?? "translations.patientAlert"}
                </Alert>
                <form onSubmit={form.onSubmit(onSubmit)} style={{position: 'relative'}}>
                    <TextInput
                        label={translations.labelCode ?? "translations.labelCode"}
                        required    {...form.getInputProps('code')}/>

                    <Group position="right" mt="md">
                        <Button type="submit"
                                loading={isSaving}
                                disabled={isSaving}
                        >
                            {translations.buttonCreate ?? "translations.buttonCreate"}
                        </Button>
                    </Group>
                </form>
            </Modal>
        </>
    );
}
export default AddPatient;