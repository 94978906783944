import React from "react";
import {Title, Card, Flex} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWandSparkles} from "@fortawesome/pro-regular-svg-icons/faWandSparkles";


const Tests = () => {

    const navigate = useNavigate();

    return <>
        <Title mb={24}>TESTS</Title>

        <Card withBorder style={{maxWidth: 250, cursor: "pointer"}} onClick={() => navigate('testCourses')} title={''}>
            <Flex align={'center'}>
                <FontAwesomeIcon icon={faWandSparkles} size={'5x'} style={{marginRight: 24}}/>
                <Title order={3}>Courses</Title>
            </Flex>
        </Card>
    </>
}

export default Tests;