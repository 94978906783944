import {PaginatedResponse} from "../interfaces/PaginatedResponse";
import {IUserDevice} from "../interfaces/IUserDevice";
import {Interceptor} from "../pages/auth/interceptor";

export default class UserDevicesService {


    static list = async (page: number): Promise<PaginatedResponse> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices?page=${page}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static search = async (value: string, page: number): Promise<PaginatedResponse> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/search?page=${page}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {value: value}).then((res: any) => {
                    return res.data;
                });
            });
    }

    static create = async (data: any): Promise<IUserDevice> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static update = async (data: any, id: number): Promise<IUserDevice> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.put(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static destroy = async (id: number): Promise<void> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.delete(url).then((res: any) => {
                    return res;
                });
            });
    }

    static listUserDevices = async (): Promise<IUserDevice[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/listUserDevices`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static listAvailableDevices = async (): Promise<IUserDevice[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/listAvailableDevices`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static connect = async (source_id: number, destination_id: number): Promise<void> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/connectDevice`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {
                    source_id, destination_id
                }).then((res: any) => {
                    return res;
                });
            });
    }

    static disconnect = async (source_id: number, destination_id: number): Promise<void> => {
        const url = `${process.env["REACT_APP_URL"]}/api/userDevices/disconnectDevice`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {
                    source_id, destination_id
                }).then((res: any) => {
                    return res;
                });
            });
    }

}