import React, {useEffect, useState} from "react";
import {useDocumentTitle, useFavicon, useMediaQuery} from "@mantine/hooks";
import {Anchor, Avatar, Breadcrumbs, Button, Divider, Flex, Grid, Modal, Text, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Fitbit from "./components/Fitbit";
import {useSelector} from "react-redux";
import {faArrowUpRightFromSquare} from "@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare";
import {IUserDevice} from "../../interfaces/IUserDevice";
import UserDevicesService from "../../services/UserDevicesService";
import {genericError} from "../../functions/genericError";
import AugmentedPerformanceService from "../../services/apps/AugmentedPerformanceService";
import AddPatient from "./components/AddPatient";
import NewConfigurationPerformance from "./components/NewConfigurationPerformance";
import AppCard from "./components/AppCard";


const AugmentedPerformance: React.FC = () => {

    const TOTAL_PATIENTS = 150;
    const TOTAL_DEVICES = 5;
    const TOTAL_CONFIGURATIONS = 5000;

    useDocumentTitle('BECOME | Augmented Performance');
    useFavicon('/favicon.ico');

    const navigate = useNavigate();
    const {translations} = useSelector((state: any) => state.lang);
    const matches = useMediaQuery('(max-width: 900px)');

    const [userDevices, setUserDevices] = useState<IUserDevice[]>([])
    const [fetchingTokens, setFetchingTokens] = useState<boolean>(false)

    const [patients, setPatients] = useState<any[]>([])
    const [fetchingPatients, setFetchingPatients] = useState<boolean>(false)
    const [patientOpened, setPatientOpened] = useState<boolean>(false);

    const [fetchingConfigurations, setFetchingConfigurations] = useState<boolean>(false)
    const [configurations, setConfigurations] = useState<any[]>([])
    const [configurationsOpened, setConfigurationsOpened] = useState<boolean>(false);

    const [showFitbit, setShowFitbit] = useState<boolean>(false)


    useEffect(() => {
        setFetchingTokens(true);
        UserDevicesService.listUserDevices()
            .then(setUserDevices)
            .catch(genericError)
            .finally(() => setFetchingTokens(false))

        setFetchingPatients(true)
        AugmentedPerformanceService.listPatients()
            .then(setPatients)
            .catch(genericError)
            .finally(() => setFetchingPatients(false))


        setFetchingConfigurations(true)
        AugmentedPerformanceService.listConfigurations()
            .then(setConfigurations)
            .catch(genericError)
            .finally(() => setFetchingConfigurations(false))
    }, [])


    const createPatientCallback = (r) => {
        setPatientOpened(false)
        if (r) {
            setPatients([r, ...patients])
        }
    }

    const createConfigurationCallback = (r) => {
        setConfigurationsOpened(false)
        if (r) {
            setConfigurations([r, ...configurations])
        }
    }


    return <>
        <div style={{display: 'relative'}}>
            <Breadcrumbs mb={12}>
                <Anchor href={'#'} onClick={(e) => {
                    e.preventDefault();
                    navigate('/apps')
                }}>
                    {translations.breadcrumbsAllApps ?? "translations.breadcrumbsAllApps"}
                </Anchor>
                <span>Augmented Performance</span>
            </Breadcrumbs>
            <Flex align={'center'}>
                <Avatar
                    mr={24}
                    src={'/images/Become_Augmented_Performance_Logo.png'} size={'lg'}/>
                <Title>Augmented Performance</Title>
            </Flex>
            <Divider mt={12} mb={12}/>

            <AddPatient opened={patientOpened} close={createPatientCallback}/>
            <NewConfigurationPerformance opened={configurationsOpened} close={createConfigurationCallback}/>

            <Grid justify={matches ? 'center' : 'flex-start'} mt={12}>


                <Grid.Col span={'auto'}>
                    <AppCard
                        loading={fetchingTokens}
                        title={translations.deviceTotal}
                        completed={userDevices.length}
                        total={TOTAL_DEVICES}
                        buttons={<>
                            <Button
                                color={'gray'}
                                fullWidth={true}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                onClick={() => window.open('/devices', '_blank')} variant={'outline'}>
                                {translations.goToDevice ?? "translations.goToDevice"}
                            </Button>
                        </>}
                    />
                </Grid.Col>

                <Grid.Col span={'auto'}>
                    <AppCard
                        loading={fetchingPatients}
                        title={translations.totalPatients}
                        completed={patients.length}
                        total={TOTAL_PATIENTS}
                        buttons={<>
                            <Button
                                fullWidth={true}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                variant={'outline'}
                                color={'gray'}
                                onClick={() => window.open('/apps/food_exposure/patients', '_blank')}>
                                {translations.allPatients ?? "translations.allPatients"}
                            </Button>
                            <Button
                                fullWidth={true}
                                onClick={() => setPatientOpened(true)}>
                                {translations.addPatient ?? "translations.addPatient"}
                            </Button>
                        </>}
                    />
                </Grid.Col>


                <Grid.Col span={'auto'}>
                    <AppCard
                        loading={fetchingConfigurations}
                        title={translations.sessioni}
                        completed={configurations.length}
                        total={TOTAL_CONFIGURATIONS}
                        buttons={<>
                            <Button
                                fullWidth={true}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                color={'gray'}
                                onClick={() => window.open('/apps/augmented_performance/configurations', '_blank')}
                                variant={'outline'}>
                                {translations.allSessionsB ?? "translations.allSessionsB"}
                            </Button>

                            {(patients.length === 0) || (userDevices.length === 0) ?
                                <>
                                    <Flex mt={-8} justify={'space-between'}>
                                        <Text size={'xs'}>{translations.onePatient ?? "translations.onePatient"}</Text>
                                        {patients.length > 0 ?
                                            <Text size={'xs'}
                                                  color={'teal'}>{translations.answerYes ?? "translations.answerYes"}</Text>
                                            :
                                            <Text size={'xs'}
                                                  color={'red'}>{translations.answerNo ?? "translations.answerNo"}</Text>}
                                    </Flex>

                                    <Flex mt={-16} justify={'space-between'}>
                                        <Text size={'xs'}
                                              mr={6}>{translations.oneDevice ?? "translations.oneDevice"}</Text>
                                        {userDevices.length > 0 ?
                                            <Text size={'xs'}
                                                  color={'teal'}>{translations.answerYes ?? "translations.answerYes"}</Text>
                                            :
                                            <Text size={'xs'}
                                                  color={'red'}>{translations.answerNo ?? "translations.answerNo"}</Text>}
                                    </Flex>
                                </> :
                                <>
                                    <Button
                                        fullWidth={true}
                                        onClick={() => setConfigurationsOpened(true)}
                                    >
                                        {translations.newSession ?? "translations.newSession"}
                                    </Button>
                                </>}
                        </>}
                    />
                </Grid.Col>
            </Grid>
        </div>

        <Fitbit configurations={configurations}/>

        <Modal opened={showFitbit}
               closeOnClickOutside={false}
               zIndex={999}
               lockScroll={false}
               size={'100%'}
               onClose={() => setShowFitbit(false)}>
            <Fitbit/>
        </Modal>


    </>
}
export default AugmentedPerformance;