import React, {useEffect, useState} from "react";
import {Anchor, Avatar, Breadcrumbs, Button, Divider, Flex, Grid, Text, Title,} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDocumentTitle, useFavicon, useMediaQuery} from "@mantine/hooks";
import {genericError} from "../../functions/genericError";
import AddPatient from "./components/AddPatient";
import NewFoodList from "./components/NewFoodList";
import AugmentedFoodService from "../../services/apps/AugmentedFoodService";
import {faArrowUpRightFromSquare} from "@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare";
import NewConfiguration from "./components/NewConfiguration";
import UserDevicesService from "../../services/UserDevicesService";
import {IUserDevice} from "../../interfaces/IUserDevice";
import Fitbit from "./components/Fitbit";
import {useSelector} from "react-redux";
import AppCard from "./components/AppCard";

const FoodExposure: React.FC = () => {

    const TOTAL_PATIENTS = 150;
    const TOTAL_DEVICES = 5;
    const TOTAL_FOOD_LISTS = 100;
    const TOTAL_CONFIGURATIONS = 5000;

    useDocumentTitle('BECOME | Food Exposure');
    useFavicon('/favicon.ico');

    const navigate = useNavigate();
    const {translations} = useSelector((state: any) => state.lang);
    const matches = useMediaQuery('(max-width: 900px)');

    const [userDevices, setUserDevices] = useState<IUserDevice[]>([])
    const [fetchingTokens, setFetchingTokens] = useState<boolean>(false)

    const [patients, setPatients] = useState<any[]>([])
    const [fetchingPatients, setFetchingPatients] = useState<boolean>(false)
    const [patientOpened, setPatientOpened] = useState<boolean>(false);

    const [fetchingFoodLists, setFetchingFoodLists] = useState<boolean>(false)
    const [foodLists, setFoodLists] = useState<any[]>([])
    const [newFoodListOpened, setNewFoodListOpened] = useState<boolean>(false);

    const [fetchingConfigurations, setFetchingConfigurations] = useState<boolean>(false)
    const [configurations, setConfigurations] = useState<any[]>([])
    const [configurationsOpened, setConfigurationsOpened] = useState<boolean>(false);


    useEffect(() => {
        setFetchingTokens(true);
        UserDevicesService.listUserDevices()
            .then(setUserDevices)
            .catch(genericError)
            .finally(() => setFetchingTokens(false))

        setFetchingPatients(true)
        AugmentedFoodService.listPatients()
            .then(setPatients)
            .catch(genericError)
            .finally(() => setFetchingPatients(false))

        setFetchingFoodLists(true)
        AugmentedFoodService.listFoodLists()
            .then(setFoodLists)
            .catch(genericError)
            .finally(() => setFetchingFoodLists(false))

        setFetchingConfigurations(true)
        AugmentedFoodService.listConfigurations()
            .then(setConfigurations)
            .catch(genericError)
            .finally(() => setFetchingConfigurations(false))
    }, [])

    const createPatientCallback = (r) => {
        setPatientOpened(false)
        if (r) {
            setPatients([...patients, r])
        }
    }

    const createFoodListCallback = (r) => {
        setNewFoodListOpened(false)
        if (r) {
            setFoodLists([...foodLists, r])
        }
    }

    const createConfigurationCallback = (r) => {
        setConfigurationsOpened(false)
        if (r) {
            setConfigurations([...configurations, r])
        }
    }


    return <>
        <div style={{display: 'relative'}}>

            <Breadcrumbs mb={12}>
                <Anchor href={'#'} onClick={() => navigate('/apps')}>
                    {translations.breadcrumbsAllApps ?? "translations.breadcrumbsAllApps"}
                </Anchor>
                <span>Food Exposure</span>
            </Breadcrumbs>
            <Flex align={'center'}>
                <Avatar
                    mr={24}
                    src={'/images/Become_Augmented_Exposure_Logo.jpg'} size={'lg'}/>
                <Title>Food Exposure</Title>
            </Flex>

            <Divider mt={12} mb={12}/>

            <AddPatient opened={patientOpened} close={createPatientCallback}/>
            <NewFoodList opened={newFoodListOpened} close={createFoodListCallback}/>
            <NewConfiguration opened={configurationsOpened} close={createConfigurationCallback}/>


            <Grid justify={matches ? 'center' : 'flex-start'} mt={12}>

                <Grid.Col span={'auto'}>
                    <AppCard
                        loading={fetchingTokens}
                        title={translations.deviceTotal}
                        completed={userDevices.length}
                        total={TOTAL_DEVICES}
                        buttons={<>
                            <Button
                                color={'gray'}
                                fullWidth={true}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                onClick={() => window.open('/devices', '_blank')} variant={'outline'}>
                                {translations.goToDevice ?? "translations.goToDevice"}
                            </Button>
                        </>}
                    />
                </Grid.Col>

                <Grid.Col span={'auto'}>
                    <AppCard
                        title={translations.totalPatients}
                        completed={patients.length}
                        total={TOTAL_PATIENTS}
                        loading={fetchingPatients}
                        buttons={<>
                            <Button
                                fullWidth={true}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                variant={'outline'}
                                color={'gray'}
                                onClick={() => window.open('/apps/food_exposure/patients', '_blank')}>
                                {translations.allPatients ?? "translations.allPatients"}
                            </Button>
                            <Button
                                fullWidth={true}
                                onClick={() => setPatientOpened(true)}>
                                {translations.addPatient ?? "translations.addPatient"}
                            </Button>
                        </>}
                    />
                </Grid.Col>

                <Grid.Col span={'auto'}>
                    <AppCard
                        loading={fetchingFoodLists}
                        title={translations.totatListsFood}
                        completed={foodLists.length}
                        total={TOTAL_FOOD_LISTS}
                        buttons={<>
                            <Button
                                fullWidth={true}
                                color={'gray'}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                onClick={() => window.open('/apps/food_exposure/food_lists', '_blank')}
                                variant={'outline'}>
                                {translations.goToLists ?? "translations.goToLists"}
                            </Button>
                            <Button
                                fullWidth={true}
                                onClick={() => setNewFoodListOpened(true)}>
                                {translations.createList ?? "translations.createList"}
                            </Button>
                        </>}
                    />
                </Grid.Col>

                <Grid.Col span={'auto'}>
                    <AppCard
                        loading={fetchingConfigurations}
                        title={translations.sessioni}
                        completed={configurations.length}
                        total={TOTAL_CONFIGURATIONS}
                        buttons={<>
                            <Button
                                fullWidth={true}
                                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                color={'gray'}
                                onClick={() => window.open('/apps/food_exposure/configurations', '_blank')}
                                variant={'outline'}>
                                {translations.allSessionsB ?? "translations.allSessionsB"}
                            </Button>

                            {(patients.length === 0) || (userDevices.length === 0) || (foodLists.length === 0) ?
                                <>
                                    <Flex mt={-8} justify={'space-between'}>
                                        <Text size={'xs'}>{translations.onePatient ?? "translations.onePatient"}</Text>
                                        {patients.length > 0 ?
                                            <Text size={'xs'}
                                                  color={'teal'}>{translations.answerYes ?? "translations.answerYes"}</Text>
                                            :
                                            <Text size={'xs'}
                                                  color={'red'}>{translations.answerNo ?? "translations.answerNo"}</Text>}
                                    </Flex>

                                    <Flex mt={-16} justify={'space-between'}>
                                        <Text size={'xs'}
                                              mr={6}>{translations.oneDevice ?? "translations.oneDevice"}</Text>
                                        {userDevices.length > 0 ?
                                            <Text size={'xs'}
                                                  color={'teal'}>{translations.answerYes ?? "translations.answerYes"}</Text>
                                            :
                                            <Text size={'xs'}
                                                  color={'red'}>{translations.answerNo ?? "translations.answerNo"}</Text>}
                                    </Flex>

                                    <Flex mt={-16} justify={'space-between'}>
                                        <Text size={'xs'} mr={6}>{translations.oneList ?? "translations.oneList"}</Text>
                                        {foodLists.length > 0 ? <Text size={'xs'}
                                                                      color={'teal'}>{translations.answerYes ?? "translations.answerYes"}</Text> :
                                            <Text size={'xs'}
                                                  color={'red'}>{translations.answerNo ?? "translations.answerNo"}</Text>}
                                    </Flex>
                                </> :
                                <>
                                    <Button
                                        fullWidth={true}
                                        onClick={() => setConfigurationsOpened(true)}
                                    >
                                        {translations.newSession ?? "translations.newSession"}
                                    </Button>
                                </>}
                        </>}
                    />
                </Grid.Col>

            </Grid>

            <Fitbit configurations={configurations}/>
        </div>


    </>
}

export default FoodExposure;