import React from "react";
import {Outlet} from "react-router-dom";

const CoursesLayout: React.FC = () => {


    return <>
        <Outlet/>
    </>
}

export default CoursesLayout;