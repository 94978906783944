import {configureStore} from '@reduxjs/toolkit'
import authReducer from "./auth/authSlice";
import themeReducer from "./theme/themeSlice";
import langReducer from "./lang/langSlice"

export default configureStore({
    reducer: {
        auth: authReducer,
        theme: themeReducer,
        lang: langReducer,
    }
})