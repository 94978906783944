import {Button, Checkbox, Group, LoadingOverlay, Stack, Text, TextInput, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import UserService from "../services/UserService";
import {genericError} from "../functions/genericError";
import {useForm} from "@mantine/form";
import {IProfessional} from "../interfaces/IProfessional";
import {useDocumentTitle, useFavicon} from "@mantine/hooks";
import {useSelector} from "react-redux";

const Professional = () => {

    useDocumentTitle('BECOME | Professional');
    useFavicon('/favicon.ico');
    const [userInfo, setUserInfo] = useState<IProfessional>();
    const [fetching, setFetching] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const {translations} = useSelector((state: any) => state.lang);
    const form = useForm({
        initialValues: {} as IProfessional,
        validate: {}
    });

    useEffect(() => {
        setFetching(true)
        UserService.getProfessional()
            .then(setUserInfo)
            .catch(genericError)
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        if (userInfo) {
            form.setValues(userInfo)
        }
    }, [userInfo])
    const onSubmit = (values: any) => {
        setSaving(true)
        UserService.updateProfessional(values)
            .then(setUserInfo)
            .catch(genericError)
            .finally(() => setSaving(false))
    }


    return <>
        <Title>{translations.titleProfessional ?? "translations.titleProfessional"}</Title>
        <Text>{translations.professionalTitle ?? "translations.professionalTitle"} <a
            href={'https://www.discoverbecome.com/professionisti'}
            target={'_blank'}>www.discoverbecome.com/professionisti</a> </Text>

        <form style={{marginTop: 12, position: 'relative'}} onSubmit={form.onSubmit(onSubmit)}>
            <LoadingOverlay visible={saving || fetching}/>
            <Stack>
                <Checkbox
                    label={translations.labelProfile ?? "translations.labelProfile"}    {...form.getInputProps('is_enabled')}/>
                <TextInput
                    label={translations.labelNAME ?? "translations.labelNAME"}    {...form.getInputProps('name')}/>
                <TextInput
                    label={translations.labelSurname ?? "translations.labelSurname"}    {...form.getInputProps('surname')}/>
                <TextInput
                    label={translations.labelAddress ?? "translations.labelAddress"}    {...form.getInputProps('address')}/>
                <TextInput
                    label={translations.labelState ?? "translations.labelState"}    {...form.getInputProps('region')}/>
                <TextInput
                    label={translations.labelProfession ?? "translations.labelProfession"}    {...form.getInputProps('profession')}/>
                <TextInput label={translations.labelBachelor ?? "translations.labelBachelor"}
                           placeholder={translations.placeholderBachelor ?? "translations.placeholderBachelor"}  {...form.getInputProps('title')}/>
                <TextInput label={translations.labelWebsite ?? "translations.labelWebsite"}
                           {...form.getInputProps('website')}/>
            </Stack>

            <Group position="right" mt="md">
                <Button type="submit" disabled={saving}
                        loading={saving}>{translations.SAVE ?? "translations.SAVE"}</Button>
            </Group>

        </form>
    </>
}
export default Professional;