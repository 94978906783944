import {Box, Button, Group, TextInput, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useDebouncedValue} from "@mantine/hooks";
import {PaginatedResponse} from "../interfaces/PaginatedResponse";
import {IUser} from "../interfaces/IUser";
import {DataTable} from "mantine-datatable";
import AdminUserService from "../services/admin/UserService";
import {genericError} from "../functions/genericError";
import UserModal from "./components/UserModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons/faEmptySet";
import {faUserMagnifyingGlass} from "@fortawesome/pro-regular-svg-icons/faUserMagnifyingGlass";
import AdminUserDevicesService from "../services/UserDevicesService";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";

const UserPage = () => {

    const [value, setValue] = useState<string>('');
    const [debounced] = useDebouncedValue(value, 600);
    const [response, setResponse] = useState<PaginatedResponse>();
    const [searching, setSearching] = useState<boolean>(false)

    const [page, setPage] = useState<number>(1)
    const [selectedUser, setSelectedUser] = useState<IUser>()

    useEffect(() => {

    }, []);

    useEffect(() => {
        setSearching(true)
        if (debounced === '') {
            AdminUserService.list(page)
                .then(setResponse)
                .catch(genericError)
                .finally(() => setSearching(false))
        } else {
            AdminUserService.search(debounced, page)
                .then(setResponse)
                .catch(genericError)
                .finally(() => {
                    setSearching(false)
                })
        }
    }, [debounced, page])

    return <>
        <Title>Users page</Title>

        <TextInput label={'Search user'} value={value} onChange={(e) => setValue(e.currentTarget.value)}/>

        <DataTable
            mt={24}
            minHeight={400}
            withBorder
            records={response?.data}
            columns={[
                {
                    accessor: 'id', title: 'ID'
                },
                {
                    accessor: 'email', title: 'Email'
                },
                {
                    accessor: 'name', title: 'Name', render: (e: any) => {
                        return e.info?.name
                    }
                },
                {
                    accessor: 'surname', title: 'Surname', render: (e: any) => {
                        return e.info?.surname
                    }
                },
                {
                    accessor: 'actions', title: 'Actions', render: (e: any) => {
                        return <Group>
                            <Button onClick={() => setSelectedUser(e)} size={'xs'}>
                                <FontAwesomeIcon icon={faEye}/>
                            </Button>
                        </Group>
                    }
                },
            ]}
            totalRecords={response?.total}
            recordsPerPage={response?.per_page}
            page={page}
            onPageChange={setPage}
            fetching={searching}
            striped
            highlightOnHover

            //idAccessor={'id'}
            noRecordsIcon={
                <Box
                    p={4}
                    mb={12}
                    sx={(theme) => ({
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
                        border: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4]}`,
                        borderRadius: theme.radius.md,
                        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
                    })}
                >
                    <FontAwesomeIcon icon={debounced === '' ? faUserMagnifyingGlass : faEmptySet} size={'3x'}/>
                </Box>
            }
            loadingText="Searching..."
            noRecordsText={"No result"}
            // uncomment the next line to use a custom pagination text
            // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
            // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
            // paginationColor="grape"
            // uncomment the next line to use a custom pagination size
            paginationSize="md"
        />

        <UserModal user={selectedUser} setUser={setSelectedUser} withinPortal={true}/>
    </>
}
export default UserPage;