import React, {FunctionComponent, useEffect, useState} from 'react';
import {Divider, MediaQuery, Menu, Navbar, ScrollArea, SegmentedControl} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavbarLink from "../components/NavbarLink";
import {faHome} from "@fortawesome/pro-regular-svg-icons/faHome";
import {faCommentsQuestion} from "@fortawesome/pro-regular-svg-icons/faCommentsQuestion";
import UserButton from "../components/UserButton";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {faGraduationCap} from "@fortawesome/pro-regular-svg-icons/faGraduationCap";
import {faGamepad} from "@fortawesome/pro-regular-svg-icons/faGamepad";
import {IRole} from "../interfaces/IRole";
import Customization from "../components/Customization";
import {faUserCrown} from "@fortawesome/pro-regular-svg-icons/faUserCrown";
import {faUser} from "@fortawesome/pro-regular-svg-icons/faUser";
import {useViewportSize} from "@mantine/hooks";
import {setSidebarOpened, setSidebarSize} from "../store/theme/themeSlice";
import {faShield} from "@fortawesome/pro-regular-svg-icons/faShield";
import {faChartSimple} from "@fortawesome/pro-regular-svg-icons/faChartSimple";
import {faLaravel} from "@fortawesome/free-brands-svg-icons/faLaravel";
import {faChartMixed} from "@fortawesome/pro-regular-svg-icons/faChartMixed";
import {faDiploma} from "@fortawesome/pro-regular-svg-icons/faDiploma";
import {faNetworkWired} from "@fortawesome/pro-regular-svg-icons/faNetworkWired";
import {faBallotCheck} from "@fortawesome/pro-regular-svg-icons/faBallotCheck";
import {faChartRadar} from "@fortawesome/pro-regular-svg-icons/faChartRadar";
import {faShelves} from "@fortawesome/pro-regular-svg-icons/faShelves";
import {faTasksAlt} from "@fortawesome/pro-regular-svg-icons/faTasksAlt";
import {logout} from "../store/auth/authSlice";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import AuthService from "../services/AuthService";
import {genericError} from "../functions/genericError";
import {faHeadSideGoggles} from "@fortawesome/pro-regular-svg-icons/faHeadSideGoggles";
import {faMessages} from "@fortawesome/pro-regular-svg-icons/faMessages";


interface OwnProps {
    isAdmin?: boolean,
    isSupervisor?: boolean
}

type Props = OwnProps;

const Sidebar: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(props.isAdmin ?? false);
    const [isSupervisor, setIsSupervisor] = useState(props.isSupervisor ?? false);
    const [adminOrSupervisor, setAdminOrSupervisor] = useState(false);
    const {user} = useSelector((state: any) => state.auth)
    const {lang} = useSelector((state: any) => state.lang)
    const {sidebarOpened, sidebarSmall} = useSelector((state: any) => state.theme)
    const {translations} = useSelector((state: any) => state.lang);
    const {width} = useViewportSize();
    const [userWithRole, setUserWithRole] = useState("");

    useEffect(() => {
        if (width < 1200) {
            dispatch(setSidebarOpened(false))
        }
    }, [])

    useEffect(() => {
        if (width < 1200) {
            dispatch(setSidebarSize(false))
        }
    }, [width])

    useEffect(() => {
        if (user?.roles?.some((r: IRole) => r.name === 'Admin')) {
            setUserWithRole("admin");
            setAdminOrSupervisor(true)
            setIsAdmin(true)
            setIsSupervisor(false)
        } else if (user?.roles?.some((r: IRole) => r.name === 'Supervisor')) {
            setUserWithRole("supervisor");
            setAdminOrSupervisor(true)
            setIsAdmin(false)
            setIsSupervisor(true)
        } else {
            setUserWithRole("");
            setAdminOrSupervisor(false)
            setIsAdmin(false)
            setIsSupervisor(false)
        }
    }, [user]);

    let segmentValue = "";
    let segmentData = [];
    let segmentOnChange = (value: 'account' | 'admin' | 'supervisor'): any => {
    };
    if (userWithRole === "admin") {
        segmentValue = isAdmin ? userWithRole : 'account';
        segmentData = [
            {label: sidebarSmall ? <FontAwesomeIcon icon={faUser}/> : 'Account', value: 'account'},
            {label: sidebarSmall ? <FontAwesomeIcon icon={faUserCrown} color={'orange'}/> : 'Admin', value: 'admin'},
        ];
        segmentOnChange = (value: 'account' | 'admin'): any => {
            setIsAdmin(value === 'admin');
            setAdminOrSupervisor(value === 'admin')
        };
    } else if (userWithRole === "supervisor") {
        segmentValue = isSupervisor ? userWithRole : 'account';
        segmentData = [
            {label: sidebarSmall ? <FontAwesomeIcon icon={faUser}/> : 'Account', value: 'account'},
            {
                label: sidebarSmall ? <FontAwesomeIcon icon={faUserCrown} color={'orange'}/> : 'Supervisor',
                value: 'supervisor'
            },
        ]
        segmentOnChange = (value: 'account' | 'supervisor'): any => {
            setIsSupervisor(value === 'supervisor');
            setAdminOrSupervisor(value === 'supervisor')
        };
    } else {
        segmentValue = "";
        segmentData = [];
        segmentOnChange = (value: 'account' | 'admin' | 'supervisor'): any => {
        };
    }
    return (user && <Navbar p="sm"
                            hiddenBreakpoint="lg"
                            hidden={!sidebarOpened}
                            width={{sm: sidebarSmall ? 75 : 400, lg: sidebarSmall ? 75 : 400}}>
        <Navbar.Section mt="xs">
            {
                (userWithRole !== "") && <>
                    <SegmentedControl
                        orientation={sidebarSmall ? 'vertical' : 'horizontal'}
                        mb={12}
                        fullWidth
                        style={{maxWidth: 365}}
                        value={segmentValue}
                        data={segmentData}
                        onChange={segmentOnChange}
                    />
                </>
            }

            {!adminOrSupervisor ? <>
                <NavbarLink onClick={() => {
                    navigate('/')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'orange'}
                            path={'/'}
                            icon={<FontAwesomeIcon icon={faHome}/>}
                            label={translations.sidebarHome}/>

                <NavbarLink onClick={() => {
                    navigate('/courses')
                    dispatch(setSidebarOpened(false))
                }}
                            path={'/courses'}
                            color={'orange'}
                            icon={<FontAwesomeIcon icon={faGraduationCap}/>}
                            label={translations.sidebarCourses}/>

                <NavbarLink onClick={() => {
                    navigate('/apps')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'orange'}
                            icon={<FontAwesomeIcon icon={faGamepad}/>}
                            label={translations.sidebarApp}/>

                <NavbarLink onClick={() => {
                    navigate('/materials')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'orange'}
                            path={'/materials'}
                            icon={<FontAwesomeIcon icon={faDiploma}/>}
                            label={translations.sidebarMaterials}/>


                {lang === 'it' ?
                    <NavbarLink onClick={() => {
                        navigate('/professional')
                        dispatch(setSidebarOpened(false))
                    }}
                                color={'orange'}
                                path={'/professional'}
                                icon={<FontAwesomeIcon icon={faNetworkWired}/>}
                                label={translations.sidebarProfessionals}/> : null}

                <NavbarLink onClick={() => {
                    navigate('/devices')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'orange'}
                            path={'/devices'}
                            icon={<FontAwesomeIcon icon={faHeadSideGoggles}/>}
                            label={translations.sidebarDevices}/>


                <NavbarLink onClick={() => {
                    navigate('/security')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'orange'} disabled
                            path={'/security'}
                            icon={<FontAwesomeIcon icon={faShield}/>}
                            label={translations.sidebarSecurity}/>

                <NavbarLink onClick={() => {
                    navigate('/support')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'orange'}
                            path={'/support'}
                            icon={<FontAwesomeIcon icon={faCommentsQuestion}/>}
                            label={translations.sidebarSupport}/>
            </> : <>
                {userWithRole === "admin" ?
                    <NavbarLink onClick={() => window.open('http://documentation.become-hub.com/', '_blank')}
                                color={'red'}
                                icon={<FontAwesomeIcon icon={faShelves}/>}
                                label={'Documentation'}/> : <></>}
                {userWithRole === "admin" ? <NavbarLink
                    onClick={() => window.open('https://linear.app/become-hub/team/BEC/projects/active', '_blank')}
                    color={'red'}
                    path={'/admin/tasks'}
                    icon={<FontAwesomeIcon icon={faTasksAlt}/>}
                    label={'Tasks'}/> : <></>}
                {userWithRole === "admin" ? <NavbarLink onClick={() => {
                    navigate('/admin/tokens')
                    dispatch(setSidebarOpened(false))
                }}
                                                        color={'red'}
                                                        path={'/admin/tokens'}
                                                        icon={<FontAwesomeIcon icon={faHeadSideGoggles}/>}
                                                        label={'User devices'}/> : <></>}
                {userWithRole === "admin" ? <NavbarLink onClick={() => {
                    navigate('/admin/pageViews')
                    dispatch(setSidebarOpened(false))
                }}
                                                        color={'red'}
                                                        path={'/admin/pageViews'}
                                                        icon={<FontAwesomeIcon icon={faChartSimple}/>}
                                                        label={'Page views'}/> : <></>}
                <NavbarLink onClick={() => {
                    navigate('/admin/user')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'red'}
                            path={'/admin/user'}
                            icon={<FontAwesomeIcon icon={faUsers}/>}
                            label={'Users'}/>

                <NavbarLink onClick={() => {
                    navigate('/admin/feedbacks')
                    dispatch(setSidebarOpened(false))
                }}
                            color={'red'}
                            path={'/admin/feedbacks'}
                            icon={<FontAwesomeIcon icon={faMessages}/>}
                            label={'Feedbacks'}/>


                {userWithRole === "admin" ? <NavbarLink onClick={() => {
                    navigate('/admin/tests')
                    dispatch(setSidebarOpened(false))
                }}
                                                        color={'red'}
                                                        path={'/admin/tests'}
                                                        icon={<FontAwesomeIcon icon={faBallotCheck}/>}
                                                        label={'Tests'}/> : <></>}
                {userWithRole === "admin" ? <NavbarLink
                    onClick={() => window.open(`https://become-0r.sentry.io/issues/?referrer=sidebar`, '_blank')}
                    color={'red'}
                    icon={<FontAwesomeIcon icon={faChartRadar}/>}
                    label={'Sentry'}/> : <></>}
                {userWithRole === "admin" ? <NavbarLink
                    onClick={() => window.open('https://vapor.laravel.com/app/projects/42114/environments/production/commands', '_blank')}
                    color={'red'}
                    icon={<FontAwesomeIcon icon={faLaravel}/>}
                    label={'Vapor'}/> : <></>}
                {userWithRole === "admin" ?
                    <NavbarLink onClick={() => window.open('https://analytics.google.com/analytics/web/#/', '_blank')}
                                color={'red'}
                                icon={<FontAwesomeIcon icon={faChartMixed}/>}
                                label={'GA'}/> : <></>}
            </>
            }

        </Navbar.Section>
        <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            {/* scrollable content here */}
        </Navbar.Section>
        <Navbar.Section>
            <Divider/>
            <MediaQuery largerThan="md" styles={{display: 'none'}}>
                <div style={{marginTop: 12}}>
                    <Customization isSidebar/>
                </div>
            </MediaQuery>
            <Menu withArrow
                  transitionProps={{transition: "rotate-right", duration: 150}}
                  width={300}
                  position={'top-end'}>
                <Menu.Target>
                    <UserButton/>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        onClick={() => {
                            AuthService.logout()
                                .then(() => dispatch(logout()))
                                .catch(genericError)
                        }}>
                        Logout
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Navbar.Section>
    </Navbar>);
};

export default Sidebar;
