import {Interceptor} from "../pages/auth/interceptor";
import {IVideo} from "../interfaces/IVideo";

export default class VideoService {

    //listVideo servizio list Admin
    static listVideo = async (): Promise<IVideo[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/listVideos`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data.data;
                });
            });
    }

    static videoUser = async (): Promise<IVideo[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/videoUser`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data.data;
                });
            });
    }
}