import { Card, Flex, Group, LoadingOverlay, RingProgress, Stack, Text, useMantineTheme } from '@mantine/core';
import React from "react";
import { useSelector } from "react-redux";
//import classes from './AppCard.module.css';


const AppCard = (props) => {

    const { translations } = useSelector((state: any) => state.lang);
    const { title, completed, total, buttons, loading } = props;

    const theme = useMantineTheme();

    return <Card withBorder p="md" radius="md"
                 style={{
                     textAlign: 'center',
                     position: 'relative',
                     height: '100%',
                     display: 'flex',
                     flexDirection: 'column'
                 }}>
        <LoadingOverlay visible={loading}/>
        <div style={{ flex: 1 }}>
            <Flex align={'center'} justify={'space-around'}>
                <Text fz="xl" m={-4}>
                    {title}
                </Text>
                <RingProgress
                    roundCaps
                    thickness={6}
                    size={120}
                    sections={[{ value: (completed / total) * 100, color: theme.primaryColor }]}
                    label={
                        <div>
                            <Text ta="center" fz="md">
                                {((completed / total) * 100).toFixed(0)}%
                            </Text>
                            <Text ta="center" fz="xs" c="dimmed">
                                Completed
                            </Text>
                        </div>
                    }
                />
            </Flex>

            <Group mt="6">
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <Text>{completed}</Text>
                    <Text size="xs" c="dimmed">
                        {translations.active ?? 'Active'}
                    </Text>
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <Text>{total}</Text>
                    <Text size="xs" c="dimmed">
                        {translations.total ?? 'Total'}
                    </Text>
                </div>
            </Group>
        </div>

        <Stack mt={12}>
            {buttons}
        </Stack>
    </Card>
}
export default AppCard;