import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation} from "react-router-dom";
import ProtectedLayout from "../layouts/ProtectedLayout";
import PublicLayout from "../layouts/PublicLayout";
import Homepage from "../pages/Homepage";
import AdminTokenPage from "../admin/tokens/AdminTokenPage";
import AnalyticsService from "../services/AnalyticsService";
import CoursesLayout from "../layouts/CoursesLayout";
import CoursePage from "../pages/courses/CoursePage";
import CoursePageECM from "../pages/courses/CoursePageECM";
import SingleCourseECM from "../pages/courses/SingleCourseECM";
import Page404 from "../pages/Page404";
import FeedbackAgenas from "../pages/courses/FeedbackAgenas";
import PageView from "../admin/analytics/PageView";
import Support from "../pages/Support";
import Materials from "../pages/Materials";
import AppIndex from "../pages/apps/AppIndex";
import FoodExposure from "../pages/apps/FoodExposure";
import Professional from "../pages/Professional";
import UserPage from "../admin/UserPage";
import Tests from "../admin/Tests";
import TestCourses from "../admin/tests/TestCourses";
import TestCourse from "../admin/tests/TestCourse";
import AugmentedPerformance from "../pages/apps/AugmentedPerformance";
import AllFoodLists from "../pages/apps/AllFoodLists";
import AllConfigurations from "../pages/apps/AllConfigurations";
import AllPatients from "../pages/apps/AllPatients";
import ForceChangePw from "../pages/auth/force_change_pw";
import MFA from "../pages/auth/mfa";
import Login from "../pages/auth/login";
import ConfirmRegistration from "../pages/auth/confirm_registration";
import ForgotPassword from "../pages/auth/forgot_password";
import Security from "../pages/auth/security";
import Registration from "../pages/auth/registration";
import {IRole} from "../interfaces/IRole";
import Page403 from "../pages/Page403";
import DevicesPage from "../pages/devices/DevicesPage";
import {setTranslations} from "../store/lang/langSlice";
import PerformanceAllConfigurations from "../pages/apps/PerformanceAllConfigurations";
import {enTranslations} from "../lang/en/translations";
import {itTranslations} from "../lang/it/translations";
import ResetPassword from "../pages/auth/reset_password";
import {esTranslations} from "../lang/es/translations";
import {brTranslations} from "../lang/br/translations";
import {ptTranslations} from "../lang/pt/translations";
import AugmentedWellbeing from "../pages/apps/AugmentedWellbeing";
import FeedbacksPage from "../admin/FeedbacksPage";

const AuthRouterWrapper: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {lang} = useSelector((state: any) => state.lang);
    const {user, authState} = useSelector((store: any) => store.auth);


    useEffect(() => {
        if (user) {
            AnalyticsService.pageView(location.pathname);
        }
    }, [location, user])

    useEffect(() => {
        switch (lang) {
            case 'it':
                dispatch(setTranslations(itTranslations()))
                break;
            case 'en':
                dispatch(setTranslations(enTranslations()))
                break;
            case 'es':
                dispatch(setTranslations(esTranslations()))
                break;
            case 'br':
                dispatch(setTranslations(brTranslations()))
                break;
            case 'pt':
                dispatch(setTranslations(ptTranslations()))
                break;
        }
    }, [location, lang])


    const protectedRoute = (element: any, roles?: string[]) => {
        switch (authState) {
            case "SIGN_IN":
                return <Login/>;
            case 'FORCE_CHANGE_PW':
                return <ForceChangePw/>;
            case 'PW_EXPIRED':
                return <ForgotPassword expired/>;
            case 'CONFIRM_REGISTRATION':
                return <ConfirmRegistration/>;
            case 'MFA':
                return <MFA/>;
            case 'ASK_RESET_PW':
                return <ForgotPassword/>;
            case 'SETUP_MFA':
                return <Security/>;
            case 'SIGNED_IN':
                if (roles && roles.length > 0) {
                    if (user?.roles?.some((r: IRole) => r.name === 'Admin')) {
                        return element;
                    } else if (user?.roles?.some((r: IRole) => r.name === 'Supervisor')) {
                        return element;
                    }
                    return <Page403/>
                }
                return element;
            default:
                <>Loading...</>
        }
    }

    return <Routes>
        <Route path={'/'} element={<ProtectedLayout/>}>
            <Route index element={protectedRoute(<Homepage/>)}/>
            <Route path="register" element={user ? <Homepage/> : <Registration/>}/>
            <Route path="reset" element={user ? <Homepage/> : <ResetPassword/>}/>

            <Route path={'devices'} element={protectedRoute(<DevicesPage/>)}/>

            {/* <Route path={'courses'} element={protectedRoute(<Courses/>)}/> */}
            <Route path={'courses'} element={protectedRoute(<CoursesLayout/>)}>
                <Route index element={<CoursePage/>}/>
                <Route path={'ecm'} element={<CoursePageECM/>}/>
                <Route path={'ecm/:id'} element={<SingleCourseECM/>}/>
                <Route path={'feedback/:id'} element={<FeedbackAgenas/>}/>
            </Route>

            <Route path={'apps'} element={protectedRoute(<AppIndex/>)}/>
            <Route path={'apps/food_exposure'} element={protectedRoute(<FoodExposure/>)}/>
            <Route path={'apps/food_exposure/food_lists'} element={protectedRoute(<AllFoodLists/>)}/>
            <Route path={'apps/food_exposure/patients'} element={protectedRoute(<AllPatients/>)}/>
            <Route path={'apps/food_exposure/configurations'} element={protectedRoute(<AllConfigurations/>)}/>
            <Route path={'apps/augmented_performance'} element={protectedRoute(<AugmentedPerformance/>)}/>
            <Route path={'apps/augmented_lifesuite'} element={protectedRoute(<AugmentedWellbeing/>)}/>

            <Route path={'apps/augmented_performance/configurations'}
                   element={protectedRoute(<PerformanceAllConfigurations/>)}/>

            <Route path={'professional'} element={protectedRoute(<Professional/>)}/>
            <Route path={'materials'} element={protectedRoute(<Materials/>)}/>

            <Route path={'support'} element={protectedRoute(<Support/>)}/>
            <Route path={'security'} element={protectedRoute(<Security/>)}/>


            <Route path={'/admin'}>
                <Route path={'user'} element={protectedRoute(<UserPage/>, ['admin' || 'supervisor'])}/>
                <Route path={'feedbacks'} element={protectedRoute(<FeedbacksPage/>, ['admin' || 'supervisor'])}/>
                <Route path={'tokens'} element={protectedRoute(<AdminTokenPage/>, ['admin'])}/>
                <Route path={'pageViews'} element={protectedRoute(<PageView/>, ['admin'])}/>
                <Route path={'tests'} element={protectedRoute(<Tests/>, ['admin'])}/>
                <Route path={'tests/testCourses'} element={protectedRoute(<TestCourses/>, ['admin'])}/>
                <Route path={'tests/testCourses/:id'} element={protectedRoute(<TestCourse/>, ['admin'])}/>
            </Route>
        </Route>

        <Route path={'shared'} element={<PublicLayout/>}/>


        <Route path="*" element={<Page404/>}/>

    </Routes>
}
export default AuthRouterWrapper;