import React, {useEffect, useState} from "react";
import {LoadingOverlay, Title, useMantineTheme} from "@mantine/core";
import {genericError} from "../../functions/genericError";
import {DataTable} from "mantine-datatable";
import {ResponsiveBar} from '@nivo/bar'
import AnalyticsService from "../../services/admin/AnalyticsService";
import {useSelector} from "react-redux";


const AdminTokenPage: React.FC = () => {

    const theme = useMantineTheme();
    const {color, fontSize} = useSelector((state: any) => state.theme);

    const [fetching, setFetching] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0)
    const [pages, setPages] = useState<any[]>([])

    useEffect(() => {
        setFetching(true);
        AnalyticsService.pageViews()
            .then(setPages)
            .catch(genericError)
            .finally(() => setFetching(false))
    }, [])


    return <>
        <div style={{display: 'relative'}}>
            <LoadingOverlay visible={fetching}/>

            <Title>Page views</Title>

            <div style={{height: 600}}>
                <ResponsiveBar
                    theme={{
                        textColor: color === 'light' ? theme.colors.dark[4] : theme.colors.gray[2],
                        fontSize: fontSize,
                    }}
                    data={pages}
                    keys={['total_visits']}
                    indexBy="path"
                    margin={{top: 50, right: 130, bottom: 50, left: 60}}
                    padding={0.3}
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={{scheme:  color === 'light' ? 'brown_blueGreen' : 'yellow_orange_brown'}}
                    defs={[
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}

                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                color === 'light' ?  'brighter' : 'darker',
                                3
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'visits',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                color === 'light' ?  'brighter' : 'darker',
                                3
                            ]
                        ]
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    role="application"
                    ariaLabel="Page views"
                    barAriaLabel={function (e) {
                        return e.formattedValue + ", views: " + e.indexValue
                    }}
                />
            </div>

            <DataTable
                mt={24}
                minHeight={400}
                withBorder
                records={pages}
                idAccessor={'path'}
                columns={[
                    {
                        accessor: 'path', title: 'Path'
                    },
                    {
                        accessor: 'total_visits', title: 'Visits'
                    },
                ]}
                totalRecords={pages.length}
                recordsPerPage={100}
                page={page}
                onPageChange={setPage}
                striped
                highlightOnHover
                loadingText="Caricamento in corso..."
                noRecordsText="Nessuna informazione disponibile"
            />
        </div>
    </>
}
export default AdminTokenPage