import {
    Anchor,
    Button,
    Checkbox,
    Container,
    Group,
    Image,
    LoadingOverlay,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import {useForm} from "@mantine/form";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import CryptoJS from 'crypto-js';
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";
import {setAuthState, setForceChangePwState, setTokenAndState, setUser} from '../../store/auth/authSlice';
import {genericError} from "../../functions/genericError";


const encrypt = ((pw: string, val: string, iv: string) => {
    const clearPw = CryptoJS.enc.Latin1.parse(pw.padEnd(32, '\0'));
    const clearIv = CryptoJS.enc.Latin1.parse(iv)
    return CryptoJS.AES.encrypt(val, clearPw, {
        mode: CryptoJS.mode.CBC,
        iv: clearIv,
        keySize: 256,
        padding: CryptoJS.pad.NoPadding
    }).toString();
});


export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {translations} = useSelector((state: any) => state.lang);


    const form = useForm({
        initialValues: {
            email: "",
            password: "",
            remember_me: false,
        },

        validate: {
            email: (value) => (value && value.length > 1 ? null : (translations.loginValidateEmail ?? "translations.loginValidateEmail")),
        },
    });


    const onSubmit = (values: any) => {
        setInProgress(true)
        AuthService.initChallenge({email: values.email})
            .then(r => {
                if (r.state) {
                    AuthService.checkAgainstCognito({
                        email: values.email,
                        password: values.password,
                    }).then(r => {
                        dispatch(setForceChangePwState({
                            tempKey: r.key,
                            tempValue: r.value,
                            email: values.email
                        }))
                    })
                        .catch(genericError)
                        .finally(() => setInProgress(false))
                    return;
                }

                const challengeId = r.challenge_id;
                const pw = CryptoJS.MD5(values.password).toString();
                const challenge = encrypt(pw, r.challenge, r.iv)

                AuthService.verifyChallenge({
                    challenge_id: challengeId,
                    challenge: challenge,
                    email: values.email,
                    remember_me: values.remember_me,
                })
                    .then(response2 => {

                        switch (response2.state) {
                            case "MFA":
                                dispatch(setTokenAndState({
                                    authState: response2.state,
                                    token: response2.token,
                                }))
                                break;
                            case "SETUP_MFA":
                                dispatch(setTokenAndState({
                                    authState: response2.state,
                                    token: response2.token,
                                }))
                                break;
                            case "FORCE_CHANGE_PW":
                                // TODO VERIFICARE SE METTERE setForceChangePwState
                                dispatch(setTokenAndState({
                                    authState: response2.state,
                                    token: response2.token,
                                }))
                                break;
                            default:
                                if (response2.state && response2.token && response2.user) {
                                    dispatch(setUser({
                                        authState: response2.state,
                                        token: response2.token,
                                        user: response2.user,
                                    }))
                                } else {
                                    console.error('Invalid response', response2)
                                }
                        }
                        /*
                        AuthService.setupMfa()
                            .then(b => {
                                console.log("setup mfa", b)
                                AuthService.checkMfa({code: "123456"})
                                AuthService.confirmMfa({code: "123456"})
                            })

                        */


                    })
                    .catch((error2) => {
                        genericError(error2)
                        switch (error2.response.data.state) {
                            case "CONFIRM_REGISTRATION":
                            case "ASK_RESET_PW":
                            case "PW_EXPIRED":
                                dispatch(setAuthState(error2.response.data.state))
                                break;
                            default:
                            // TODO MANTINE SHOW Wrong credentials
                        }
                        /*  ASK_RESET_PW
                          CONFIRM_REGISTRATION
                          */
                    })
                    .finally(() => setInProgress(false))

            })
            .catch((e) => {
                genericError(e)
                setInProgress(false);
            })

    }

    return (
        <Container size={420} my={40}>
            <Image
                width={120}
                height={120}
                ml={'auto'}
                mr={'auto'}
                mb={12}
                src={'https://images.squarespace-cdn.com/content/v1/5eb2b51167fdef19fd2e97f2/1591627430987-54ZRIMAKRRBYYEYITTR4/Become_Augmented_Life_Logo.png?format=1500w'}/>
            <Title
                align="center"
                sx={(theme) => ({fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900})}
            >
                {translations.welcome ?? "translations.welcome"}
            </Title>
            <Text color="dimmed" size="sm" align="center" mt={5}>
                {translations.noAccount ?? "translations.noAccount"}{' '}
                <Anchor size="sm" component="button" onClick={() => navigate('/register')}>
                    {translations.signUp ?? "translations.signUp"}
                </Anchor>
            </Text>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <LoadingOverlay visible={inProgress} overlayBlur={2}/>
                    <TextInput label={translations.emailL ?? "translations.emailL"} placeholder="paolo.rossi@gmail.com"
                               required {...form.getInputProps('email')}/>
                    <PasswordInput label={translations.passwordL ?? "translations.passwordL"}
                                   placeholder={translations.passwordLPH ?? "translations.passwordLPH"} required
                                   mt="md" {...form.getInputProps('password')}
                                   style={{}}/>
                    <Group position="apart" mt="lg">
                        <Checkbox
                            label={translations.rememberMeL ?? "translations.rememberMeL"} {...form.getInputProps('remember_me')}/>
                    </Group>
                    <Button fullWidth mt="xl" type={"submit"}>
                        {translations.entra ?? "translations.entra"}
                    </Button>
                </form>
                <Text color="dimmed" size="sm" align="center" mt={12}>
                    <Anchor size="sm" ml={6} component="button" onClick={() => dispatch(setAuthState("ASK_RESET_PW"))}>
                        {translations.forgotPw ?? "translations.forgotPw"}
                    </Anchor>
                </Text>
            </Paper>
        </Container>
    );
}