import React, {forwardRef} from 'react';
import {Avatar, Group, Text, UnstyledButton} from '@mantine/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import {useSelector} from "react-redux";
import {getColor} from "../functions/stringToColor";

const UserButton = forwardRef(
    ({icon, ...others}: any, ref: any) => {
        const {user} = useSelector((state: any) => state.auth)
        const {sidebarSmall} = useSelector((state: any) => state.theme)
        const generateSlices = (names: any) => {
            if (!names) return '';

            const checkSpaces = names.split(' ');
            if (checkSpaces.length > 1) {
                return checkSpaces[0].slice(0, 1) + checkSpaces[1].slice(0, 1);
            }
            return names.slice(0, 2);
        };


        return <UnstyledButton
            ref={ref}
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: sidebarSmall ? '6px 0' : theme.spacing.md,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                '&:hover': {
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            })}
            {...others}
        >

            <Group>
                {user ?
                    <Avatar radius="xl" style={{margin: 'auto'}}
                            color={getColor(user?.name)} tt="uppercase">{generateSlices(user.name)}</Avatar>
                    : null
                }

                {!sidebarSmall && <>
                    <div style={{flex: 1}}>
                        <Text size="sm" weight={500}>
                            {`${user?.name ?? ''} ${user.family_name ?? ''}`}
                        </Text>

                        <Text color="dimmed" size="xs">
                            {user?.email}
                        </Text>
                    </div>

                    {icon || <FontAwesomeIcon icon={faChevronRight}/>}
                </>
                }
            </Group>

        </UnstyledButton>
    }
);

export default UserButton;