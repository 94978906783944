import {ICourse} from "../../interfaces/ICourse";
import {AxiosInstance} from "axios";
import {Interceptor} from "../../pages/auth/interceptor";

export default class AugmentedPerformanceService {

    static listPatients = async (): Promise<ICourse[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/augmentedFood/patients`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                let res = await ax.get(url);
                return res.data;
            });
    }

    static createPatient = async (data: []): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/augmentedFood/patients`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                const res = await ax.post(url, data);
                return res.data;
            });
    }


    static listConfigurations = async (): Promise<any[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/performance/configurations`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                let res = await ax.get(url);
                return res.data;
            });
    }

    static showConfigurations = async (id): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/performance/configurations/${id}`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                let res = await ax.get(url);
                return res.data;
            });
    }

    static createConfiguration = async (data: any): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/performance/configurations`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                const res = await ax.post(url, data);
                return res.data;
            });
    }

    static deleteConfiguration = async (id: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/performance/configurations/${id}`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                const res = await ax.delete(url);
                return res.data;
            });
    }

    static download = async (id: number): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/performance/csv/${id}`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                const res = await ax.get(url, {
                    responseType: 'blob'
                });
                const url_2 = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url_2;
                link.setAttribute('download', `config_${id}_results.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                link.style.display = 'none';
                window.URL.revokeObjectURL(url_2);
                return;
            });
    }

}