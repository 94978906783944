const stringToColor = (value: string, saturation: number = 100, lightness: number = 75) => {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
        hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

const stringToColour = (str: string) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}

const nearestColor = (colorHex: string) => {
    const colors: string[] = ['#D9480F', '#5C940D', '#087F5B', '#862E9C', '#A61E4D', '#5F3DC4',
        '#364FC7', '#1864AB', '#0B7285', '#2B8A3E',
        '#E67700'];
    const hexToRgb = (hex: any) => hex.slice(1).replace(/^(.)(.)(.)$/gi, "$1$1$2$2$3$3").match(/.{2}/g).map((c: any) => parseInt(c, 16));
    const distance = (a: any, b: any): number => Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2) + Math.pow(a[2] - b[2], 2));

    let lowest = Number.POSITIVE_INFINITY;
    let tmp;
    let index = 0;
    colors.forEach((el, i) => {
        tmp = distance(hexToRgb(colorHex), hexToRgb(el))
        if (tmp < lowest) {
            lowest = tmp;
            index = i;
        }
    })
    return colors[index];

}

const getColor = (str: string) => {
    const mantineColors = [
        {
            name: 'orange',
            hex: '#D9480F'
        },
        {
            name: 'lime',
            hex: '#5C940D'
        },
        {
            name: 'teal',
            hex: '#087F5B'
        },
        {
            name: 'grape',
            hex: '#862E9C'
        },
        {
            name: 'pink',
            hex: '#A61E4D'
        },
        {
            name: 'violet',
            hex: '#5F3DC4'
        },
        {
            name: 'indigo',
            hex: '#364FC7'
        },
        {
            name: 'blue',
            hex: '#1864AB'
        },
        {
            name: 'cyan',
            hex: '#0B7285'
        },
        {
            name: 'green',
            hex: '#2B8A3E'
        },
        {
            name: 'yellow',
            hex: '#E67700'
        },

    ];

    const hex = nearestColor(str)

    return mantineColors.find((o: any) => o.hex === hex)?.name;
}

export {getColor, stringToColor, stringToColour}