import {Anchor, Button, Container, createStyles, Paper, rem, Stack, Text, TextInput, Title,} from '@mantine/core';
import {useForm} from "@mantine/form";
import {useDispatch, useSelector} from "react-redux";
import {setAuthState} from "../../store/auth/authSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import {useState} from "react";
import AuthService from "../../services/AuthService";
import {genericError} from "../../functions/genericError";
import {showNotification} from "@mantine/notifications";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));


export default function ForgotPassword(props) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const {translations} = useSelector((state: any) => state.lang)
    const [inProgress, setInProgress] = useState<boolean>(false);

    const form = useForm({
        initialValues: {
            email: "",
        },

        validate: {
            email: (value) => (value && value.length > 1 ? null : (translations.validateEmail ?? "translations.validateEmail")),
        },
    });

    const onSubmit = (values: any) => {
        setInProgress(true);
        AuthService.askResetPassword(values)
            .then(() => showNotification({
                color: 'teal',
                message: translations.resetPassword,
                icon: <FontAwesomeIcon icon={faCheck}/>,
                title: translations.success,
                autoClose: false
            }))
            .catch(genericError)
            .finally(() => setInProgress(false))
    }


    return (
        <Container size={460} my={30}>
            <Title className={classes.title} align="center">
                {props.expired ? translations.expired : translations.forgotPw}
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
                {translations.insertEmail ?? "translations.insertEmail"}
            </Text>

            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <TextInput label={translations.emailLabel ?? "translations.emailLabel"}
                                   placeholder="paolo.rossi@gmail.com"
                                   required {...form.getInputProps('email')}/>

                        <Button className={classes.control} fullWidth
                                loading={inProgress}
                                disabled={inProgress}
                                type="submit">{translations.resetPw ?? "translations.resetPw"}</Button>
                    </Stack>
                </form>
                <Text color="dimmed" size="sm" align="center" mt={12}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <Anchor size="sm" ml={12}
                            component="button" onClick={() => dispatch(setAuthState("SIGN_IN"))}>
                        {translations.backLogin ?? "translations.backLogin"}
                    </Anchor>
                </Text>
            </Paper>

        </Container>
    );
}