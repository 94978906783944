import {
    Button,
    Center,
    Container,
    createStyles,
    Flex,
    Group,
    LoadingOverlay,
    Paper,
    PasswordInput,
    rem,
    Text,
    Title,
} from '@mantine/core';
import {modals} from "@mantine/modals";
import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {useForm} from "@mantine/form";
import CryptoJS from "crypto-js";
import AuthService from "../../../services/AuthService";
import {updateUser} from "../../../store/auth/authSlice";
import {genericError} from "../../../functions/genericError";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));

const encrypt = ((pw: string, val: string, iv: string) => {
    const clearPw = CryptoJS.enc.Latin1.parse(pw.padEnd(32, '\0'));
    const clearIv = CryptoJS.enc.Latin1.parse(iv)
    return CryptoJS.AES.encrypt(val, clearPw, {
        mode: CryptoJS.mode.CBC,
        iv: clearIv,
        keySize: 256
    }).toString();
});

export default function DisableMFA() {

    const {classes} = useStyles();
    const dispatch = useDispatch();
    const {user} = useSelector((store: any) => store.auth);
    const {translations} = useSelector((state: any) => state.lang)
    const [inProgress, setInProgress] = useState<boolean>(false);

    const form = useForm({
        initialValues: {
            password: "",
        },

        validate: {
            password: (value) => (/[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[$&+,:;=?@#|'<>.^*()%!-]/.test(value) ? null : (translations.validatePw ?? "translations.validatePw")),
        },
    });


    const openModal = () => modals.openConfirmModal({
        title: translations.modalRemoveMfa ?? "translations.modalRemoveMfa",
        children: (
            <Text size="sm">
                {translations.modalRemoveConfirm ?? "translations.modalRemoveConfirm"}
            </Text>
        ),
        labels: {
            confirm: translations.modalLabelConfirm ?? "translations.modalLabelConfirm",
            cancel: translations.modalLabelCancel ?? "translations.modalLabelCancel"
        },
        onConfirm: () => {
            setInProgress(true)
            AuthService.initChallenge({email: user.email})
                .then(r => {
                    const challengeId = r.challenge_id;
                    const pw = CryptoJS.MD5(form.values.password).toString();
                    const challenge = encrypt(pw, r.challenge, r.iv)

                    AuthService.disableMfa({
                        challenge_id: challengeId,
                        challenge: challenge,
                        email: user.email
                    })
                        .then(() => {
                            dispatch(updateUser({
                                user: {
                                    ...user,
                                    two_factor_confirmed_at: null
                                },
                            }))
                        })
                        .catch((error2) => {
                            genericError(error2)
                        })
                        .finally(() => setInProgress(false))
                })
                .catch((error) => {
                    genericError(error)
                    setInProgress(false)
                })
        }
    });

    return (
        <>
            <Container size={460} my={30}>
                <LoadingOverlay visible={inProgress} overlayBlur={2} transitionDuration={1000}/>
                <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                    <Title className={classes.title} align="center">
                        {translations.mfaSetup ?? "translations.mfaSetup"}
                    </Title>
                    <Text c="dimmed" fz="sm" ta="center" mt={5}>
                        {translations.mfaSetupText ?? "translations.mfaSetupText"}
                    </Text>
                </Paper>
                <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                    <Title className={classes.title} align="center">
                        {translations.removeMfa ?? "translations.removeMfa"}
                    </Title>
                    <Text c="dimmed" fz="sm" ta="center" mt={10}>
                        {translations.removeMfaInstruction ?? "translations.removeMfaInstruction"}
                    </Text>
                    <Center mt={12}>
                        <form>
                            <Flex justify="center"
                                  align="center"
                                  direction="column"
                                  gap={24}
                                  mt={32}>
                                <PasswordInput
                                    autoComplete={"off"}
                                    withAsterisk
                                    label={translations.pwLabel ?? "translations.pwLabel"}
                                    placeholder={translations.pwLabel ?? "translations.pwLabel"}
                                    {...form.getInputProps('password')}
                                    className={classes.controls}
                                    style={{minWidth: 250}}
                                />
                                <Group position="center">
                                    <Button className={classes.control}
                                            onClick={openModal}
                                            loading={inProgress}
                                            disabled={!form.values.password || inProgress}>
                                        Remove MFA</Button>
                                </Group>
                            </Flex>
                        </form>
                    </Center>
                </Paper>
            </Container>
        </>
    )
}