import React from 'react';
import QRCode from "react-qr-code";
import {Center, Modal} from "@mantine/core";
import {IUserDevice} from "../../interfaces/IUserDevice";
import {useSelector} from "react-redux";

const TokenLogin = (props: {
    setSelectedToken: any,
    opened: boolean,
    setOpened: any,
    token: IUserDevice
}) => {

    const {translations} = useSelector((state: any) => state.lang);

    return <Modal
        title={translations.loginWithQR}
        opened={props.opened}
        onClose={() => {
            props.setSelectedToken(undefined)
            props.setOpened(false)
        }}>

        <Center mt={24} mb={24}>
            <QRCode
                value={JSON.stringify({
                    device_name: props.token.device_name,
                    one_time_password: props.token.one_time_password
                })}/>
        </Center>
    </Modal>

}

export default TokenLogin;