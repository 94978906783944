import React, {useEffect, useState} from "react";
import AdminUserService from "../services/admin/UserService";
import {DataTable} from "mantine-datatable";
import {Text, Title} from "@mantine/core";

interface Feedback {
    id: number;
    relevance: number;
    quality: number;
    utility: number;
    time: number;
    sponsor: string;
    notes: string;
    course_id: number;
    updated_at: string;
}

interface FeedbackByYear {
    [year: string]: Feedback[];
}

const FeedbacksPage = () => {
    const [feedbackByYear, setFeedbackByYear] = useState<FeedbackByYear>({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: any = await AdminUserService.retrieveFeedbacks(); // Assuming retrieveFeedbacks returns a promise with JSON data
                setFeedbackByYear(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <Title>All Users' Feedbacks by Years</Title>
            {feedbackByYear ? Object.entries(feedbackByYear).map(([year, feedbackList]) => (
                <div key={year}>
                    <h2>{year}</h2>
                    <DataTable
                        records={feedbackList}
                        columns={[
                            {accessor: 'relevance', title: 'Relevance'},
                            {accessor: 'quality', title: 'Quality'},
                            {accessor: 'utility', title: 'Utility'},
                            {accessor: 'time', title: 'Time'},
                            {accessor: 'sponsor', title: 'Sponsor'},
                            {accessor: 'notes', title: 'Notes'},
                            {accessor: 'course_id', title: 'Course ID'},
                            {
                                accessor: 'updated_at',
                                title: 'Date',
                                render: (r: any) => <Text>{r.updated_at.slice(0, 10)}</Text>
                            },
                        ]}
                    />
                </div>
            )) : <Text>Nessun Feedback presente</Text>}
        </div>
    );
};
export default FeedbacksPage;