import {IUser} from "../../interfaces/IUser";
import {Interceptor} from "../../pages/auth/interceptor";


export default class AnalyticsService {


    static pageViews = async (): Promise<IUser[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/page_view`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

}