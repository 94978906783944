import VerificationInput from "react-verification-input";
import {
    Button,
    Checkbox,
    Container,
    createStyles,
    Flex,
    Group,
    LoadingOverlay,
    Paper,
    rem,
    Text,
    Title,
} from '@mantine/core';
import {IconArrowLeft} from '@tabler/icons-react';
import {useForm} from "@mantine/form";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";


import AuthService from "../../services/AuthService";
import {genericError} from "../../functions/genericError";
import {logout, setUser} from "../../store/auth/authSlice";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));

export default function MFA() {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {translations} = useSelector((state: any) => state.lang)

    const form = useForm({
        initialValues: {
            code: "",
            remember_me: false,
        },

        validate: {
            code: (value) => (value && value.length === 6 ? null : (translations.mfaValidate ?? "translations.mfaValidate")),
        },
    });

    const onSubmit = (values: any) => {
        setInProgress(true)
        AuthService.checkMfa({code: values.code, remember_me: values.remember_me})
            .then(response => {
                dispatch(setUser({
                    authState: response.state,
                    token: response.token,
                    user: response.user,
                }))
            })

            .catch((error) => {
                genericError(error)

            })
            .finally(() => setInProgress(false))
    }

    return (
        <Container size={460} my={30}>
            <Title className={classes.title} align="center">
                {translations.mfaTitle ?? "translations.mfaTitle"}
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
                {translations.mfaText ?? "translations.mfaText"}
            </Text>

            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <LoadingOverlay visible={inProgress} overlayBlur={2}/>
                    <Flex justify="center"
                          align="center"
                          direction="column">
                        <VerificationInput validChars="0-9"
                                           inputProps={{inputMode: "numeric"}} {...form.getInputProps('code')}/>
                        <Checkbox label="Remember me" {...form.getInputProps('remember_me')} mt={12}/>
                    </Flex>
                    <Group position="apart" mt="lg" className={classes.controls}>
                        <Button variant={'link'}
                                leftIcon={<IconArrowLeft size={rem(12)} stroke={1.5}/>}
                                size="sm"
                                onClick={() => {
                                    AuthService.logout()
                                        .then(() => dispatch(logout()))
                                        .catch(genericError)
                                }}>
                            {translations.mfaBackLogin ?? "translations.mfaBackLogin"}
                        </Button>

                        <Button className={classes.control}
                                type="submit">{translations.mfaConfirm ?? "translations.mfaConfirm"}</Button>
                    </Group>
                </form>
            </Paper>
        </Container>
    );
}