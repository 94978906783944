import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Divider, Image, Loader, SimpleGrid, Text, Title } from "@mantine/core";
import axios from "axios";
import { useDocumentTitle, useFavicon } from "@mantine/hooks";
import advancedLang from "../functions/advancedLang";


const Homepage: React.FC = () => {

    const { user } = useSelector((state: any) => state.auth)
    const [items, setItems] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const { translations } = useSelector((state: any) => state.lang);

    useDocumentTitle('BECOME | Dashboard');
    useFavicon('/favicon.ico');

    useEffect(() => {
        setFetching(true)
        axios.get('https://s3.eu-central-1.amazonaws.com/become-hub.com/blog.json')
            .then((r) => {
                setItems(r.data.items)
            })
            .finally(() => setFetching(false))
    }, [])


    return <>
        <Title>{translations.benvenuto ? advancedLang(translations.benvenuto, [user?.name]) : "translations.benvenuto"}</Title>


        <Divider mt={24} mb={24}/>

        <Title order={2}>{translations.articoli ?? "translations.articoli"}</Title>

        <SimpleGrid cols={4} spacing="md" breakpoints={[
            { maxWidth: 980, cols: 3, spacing: 'md' },
            { maxWidth: 755, cols: 2, spacing: 'sm' },
            { maxWidth: 600, cols: 1, spacing: 'sm' },
        ]} mt={24} style={{ position: "relative" }}>
            {fetching && <Loader style={{ margin: '50px auto' }}/>}

            {items.map((i, index: number) => <Card
                key={'k-' + index}
                shadow="sm"
                p="xl"
                component="a"
                href={`https://discoverbecome.com` + i.fullUrl.replace('blog', 'it/blog')}
                target="_blank"
            >
                <Card.Section>
                    <Image
                        src={i.assetUrl}
                        height={160}
                        alt="No way!"
                    />
                </Card.Section>

                <Text weight={500} size="lg" mt="md">
                    {i.title}
                </Text>

                <Text mt="xs" color="dimmed" size="sm" dangerouslySetInnerHTML={{ __html: i.excerpt }}/>
            </Card>)
            }

        </SimpleGrid>
    </>
}

export default Homepage;