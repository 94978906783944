import React from "react";
import {Button, Card, Flex, Group, Image, Text, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useDocumentTitle, useFavicon} from "@mantine/hooks";
import {shallowEqual, useSelector} from "react-redux";


const CoursePage: React.FC = () => {

    const navigate = useNavigate();
    const {lang, translations} = useSelector((state: any) => state.lang, shallowEqual);

    useDocumentTitle(translations.docTitle ?? "translations.docTitle");
    useFavicon('/favicon.ico');

    return <>
        <div style={{display: 'relative'}}>
            <Title>{translations.eLearning ?? "translations.eLearning"}</Title>
            <Text>{translations.inThisPage ?? "translations.inThisPage"}</Text>

            <Flex mt={50} gap={32} direction={{base: 'column', sm: 'row'}}>

                {lang === 'it' ?
                    <Card title={'Corsi ECM'} withBorder style={{flex: 1}}>
                        <Card.Section style={{
                            cursor: 'pointer',
                            backgroundImage: 'url(./images/background.png)',
                            minHeight: 250,
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} onClick={() => navigate('./ecm')}>
                            <Image src={'/images/ecm.svg'} alt={'Img'}
                                   width={250}/>
                        </Card.Section>
                        <Group position="apart" mt="xl">
                            <Text size="lg" weight={700}>
                                Corsi di formazione asincroni con ECM
                            </Text>
                        </Group>
                        <Text size="sm" color="dimmed">
                            Qui puoi trovare i corsi in modalità ECM. Maggiori informazioni: <a
                            href={'https://ape.agenas.it/provider/provider-login.aspx'} target={'_blank'}
                            rel={'noreferrer'}>AGENAS</a>
                        </Text>

                        <Button onClick={() => navigate('./ecm')} variant="light" color="blue" fullWidth mt="md"
                                radius="md">
                            Vai ai corsi ECM
                        </Button>
                    </Card> : null}

                <Card title={translations.currentDisabled} withBorder style={{flex: 1}}>
                    <Card.Section style={{
                        cursor: 'pointer',
                        minHeight: 250,
                        backgroundImage: 'url(./images/background.png)',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                    }} onClick={() => navigate('./free')}>
                        <Image src={'/images/free.svg'}
                               alt={'Img'} width={230}/>
                    </Card.Section>
                    <Group position="apart" mt="xl">
                        <Text size="lg" weight={700}>
                            {translations.corsiNoECM ?? "translations.corsiNoECM"}
                        </Text>
                    </Group>
                    <Text size="sm" color="dimmed">
                        {translations.whatYouFind ?? "translations.whatYouFind"}
                    </Text>


                    <Button onClick={() => navigate('./free')}
                            disabled
                            variant="light" color="blue" fullWidth mt="md"
                            radius="md">
                        {translations.goCourses ?? "translations.goCourses"}
                    </Button>

                </Card>
            </Flex>
        </div>
    </>
}

export default CoursePage;