import {AxiosInstance} from "axios";
import {Interceptor} from "../../pages/auth/interceptor";

export default class VideoService {

    static listVideos = async (): Promise<any[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/videos`;
        return await Interceptor().then(
            async (ax: AxiosInstance) => {
                let res = await ax.get(url);
                return res.data?.data;
            });
    }


}