import React, {forwardRef, useState} from "react";
import {Button, Notification, Title} from "@mantine/core";
import CourseService from "../../../services/CourseService";
import {useParams} from "react-router-dom";
import {genericError} from "../../../functions/genericError";

const Certificate = forwardRef((props: {
    userHasCourse: any
}, cpRef) => {

    const {id} = useParams();
    const [generating, setGenerating] = useState<boolean>(false)
    const [wait, setWait] = useState<boolean>(false)

    const generateCert = () => {
        if (!id) return;
        setGenerating(true)

        CourseService.generateCertificate(parseInt(id))
            .then(() => {
                setWait(true)
            })
            .catch(genericError)
            .finally(() => {
                setGenerating(false);
            })
    }

    const downloadCert = () => {
        if (!id) return;
        setGenerating(true)

        CourseService.downloadCertificate(parseInt(id))
            .then((data: {
                url: string,
                name: string
            }) => {
                const link = document.createElement('a');
                link.href = data.url;
                link.target = '_blank';
                link.download = data.name;
                link.dispatchEvent(new MouseEvent('click'));
            })
            .catch(genericError)
            .finally(() => {
                setGenerating(false);
            })
    }


    return <>
        Pagina certificato

        {props.userHasCourse.certificate_downloaded ?
            <>
                <Title order={2}>Scarica il certificato di partecipazione al corso.</Title>

                <Button onClick={downloadCert} disabled={generating} loading={generating} mt={24} mb={24}>
                    Scarica il certificato
                </Button>


            </>
            :
            <>
                <Title order={2}>Genera il certificato di partecipazione al corso.</Title>

                <Button onClick={generateCert} disabled={generating || wait} loading={generating || wait} mt={24}
                        mb={24}>
                    Genera il certificato
                </Button>

                {wait &&
                    <Notification title="Ti arriverà una notifica appena il certificato sarà pronto" mt={15} mb={15}>
                        Abbiamo preso in carico la tua richiesta. Ti arriverà una notifica sulla pagina tra pochi
                        minuti.<br/>
                        Se non dovesse arrivarti prova a ricaricare questa pagina.
                    </Notification>
                }
            </>
        }
    </>
})

export default Certificate