import {Interceptor} from "../pages/auth/interceptor";


export default class AnalyticsService {


    static pageView = async (path: string): Promise<null> => {
        const url = `${process.env["REACT_APP_URL"]}/api/analytics/page_view`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {path: path}).then((res: any) => {
                    return res.data;
                });
            });
    }

}