import React from "react";
import {Button, Container, createStyles, Group, Image, Title} from '@mantine/core';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: 24,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: 24,
    },
}));
const Page403: React.FC = () => {

    const navigate = useNavigate();
    const {classes} = useStyles();
    const {translations} = useSelector((state: any) => state.lang);


    return <Container className={classes.root}>
        <div className={classes.label}>403</div>
        <Image src={'/NoPowerHere.gif'} width={600} mx="auto" radius={'md'} alt={'You have no power here'}/>
        <Title mt={24} className={classes.title}>{translations.noPower ?? "translations.noPower"}</Title>
        <Group position="center">
            <Button variant="subtle" size="md" onClick={() => navigate('/')}>
                {translations.backHome ?? "translations.backHome"}
            </Button>
        </Group>
    </Container>
}
export default Page403;