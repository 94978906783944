import React from "react";
import {useSelector} from "react-redux";
import {Image, Title} from '@mantine/core';

const Logo: React.FC = () => {

    const {color} = useSelector((state: any) => state.theme);

    return <>{/*
        <Image
            width={34}
            alt={'BECOME. logo'}
            src={'https://images.squarespace-cdn.com/content/v1/5eb2b51167fdef19fd2e97f2/1591627430987-54ZRIMAKRRBYYEYITTR4/Become_Augmented_Life_Logo.png?format=1500w'}/>
            */}
        <Title ml={10} order={3}>BECOME.</Title>
    </>
}

export default Logo;