import {IUser} from "../../interfaces/IUser";
import {Interceptor} from "../../pages/auth/interceptor";
import {PaginatedResponse} from "../../interfaces/PaginatedResponse";


export default class AdminUserService {


    static retrieveFeedbacks = async (): Promise<any[]> => {
        const url = `${process.env["REACT_APP_URL"]}/api/retrieveFeedbacks`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static list = async (page: number): Promise<PaginatedResponse> => {
        const url = `${process.env["REACT_APP_URL"]}/api/users?page=${page}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }


    static getUser = async (id: number): Promise<IUser> => {
        const url = `${process.env["REACT_APP_URL"]}/api/users/${id}`;
        return await Interceptor().then(
            (ax) => {
                return ax.get(url).then((res: any) => {
                    return res.data;
                });
            });
    }

    static search = async (value: string, page: number): Promise<PaginatedResponse> => {
        let url = `${process.env["REACT_APP_URL"]}/api/users/search?page=${page}`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, {value: value}).then((res: any) => {
                    return res.data;
                });
            });
    }


    static addCourseToUser = async (data: any): Promise<IUser> => {
        const url = `${process.env["REACT_APP_URL"]}/api/addCourseToUser`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static updateUserHasCourse = async (data: any): Promise<number> => {
        const url = `${process.env["REACT_APP_URL"]}/api/admin/updateUserHasCourse`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static addOrDeleteRole = async (data: any): Promise<number> => {
        const url = `${process.env["REACT_APP_URL"]}/api/admin/addOrDeleteRole`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static addVideoToUser = async (data: any): Promise<IUser> => {
        const url = `${process.env["REACT_APP_URL"]}/api/addVideoToUser`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static updateUserHasVideo = async (data: any): Promise<number> => {
        const url = `${process.env["REACT_APP_URL"]}/api/admin/updateUserHasVideo`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }

    static retrieveCourseTests = async (data: any): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/retrieveCourseTests`;
        return await Interceptor().then(
            (ax) => {
                return ax.post(url, data).then((res: any) => {
                    return res.data;
                });
            });
    }


}