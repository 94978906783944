import VerificationInput from "react-verification-input";
import {
    Anchor,
    Button,
    Container,
    createStyles,
    LoadingOverlay,
    Paper,
    rem,
    Stack,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import {useForm} from "@mantine/form";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import AuthService from "../../services/AuthService";
import {setAuthState, setUser} from "../../store/auth/authSlice";
import {genericError} from "../../functions/genericError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons/faArrowLeft";


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));

export default function ConfirmRegistration() {

    const {classes} = useStyles();
    const dispatch = useDispatch();
    const {email} = useSelector((store: any) => store.auth);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {translations} = useSelector((state: any) => state.lang)

    const form = useForm({
        initialValues: {
            code: "",
            email: email ?? "",
        },

        validate: {
            code: (value) => (value && value.length === 6 ? null : (translations.validateCode ?? "translations.validateCode")),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : (translations.validateEmail ?? "translations.validateEmail")),
        },
    });

    const onSubmit = (values: any) => {
        setInProgress(true)
        AuthService.verify({code: values.code, email: values.email})
            .then(response => {
                dispatch(setUser({
                    authState: response.state,
                    token: response.token,
                    user: response.user,
                }))
            })

            .catch((error) => {
                genericError(error)

            })
            .finally(() => setInProgress(false))
    }


    return (
        <Container size={460} my={30}>
            <LoadingOverlay visible={inProgress} overlayBlur={2} transitionDuration={1000}/>
            <Title className={classes.title} align="center">
                {translations.insertCodeTitle ?? "translations.insertCodeTitle"}
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
                {translations.intestCodeText ?? "translations.intestCodeText"}
            </Text>

            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <LoadingOverlay visible={inProgress} overlayBlur={2}/>
                    <Stack>

                        <TextInput label={translations.emailLabel ?? "translations.emailLabel"}
                                   placeholder="paolo.rossi@gmail.com"
                                   required {...form.getInputProps('email')}
                        />
                        <Text size={'sm'}>{translations.codeField ?? "translations.codeField"}</Text>
                        <VerificationInput validChars="'A-Za-z0-9'"
                                           {...form.getInputProps('code')}/>


                        <Button mt={12} className={classes.control} type="submit"
                                fullWidth>{translations.confirmReg ?? "translations.confirmReg"}</Button>

                    </Stack>
                </form>
                <Text color="dimmed" size="sm" align="center" mt={12}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <Anchor size="sm" ml={12}
                            component="button" onClick={() => dispatch(setAuthState("SIGN_IN"))}>
                        {translations.backLogin ?? "translations.backLogin"}
                    </Anchor>
                </Text>
            </Paper>
        </Container>
    );
}