import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Group,
  Modal,
  NumberInput,
  Select,
  Stack
} from "@mantine/core";
import { useForm } from "@mantine/form";
import AugmentedFoodService from "../../../services/apps/AugmentedFoodService";
import { genericError } from "../../../functions/genericError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import UserDevicesService from "../../../services/UserDevicesService";
import { useSelector } from "react-redux";
import AugmentedPerformanceService
  from "../../../services/apps/AugmentedPerformanceService";

const NewConfigurationPerformance: React.FC<{
  opened: any,
  close: any
}> = ({ opened, close }) => {

  const [patients, setPatients] = useState<any[]>([])
  const [fetchingPatients, setFetchingPatients] = useState<boolean>(false)
  const [tokens, setTokens] = useState<any[]>([])
  const [fetchingTokens, setFetchingTokens] = useState<boolean>(false)
  const { translations } = useSelector((state: any) => state.lang);
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const form = useForm({
    initialValues: {
      hr_min: 5,
      hr_max: (300),
      difficulty: 60,
      patient_id: -1,
      room: '',
      user_device_id: -1,
      is_recording: true,
      status: 'queue',
      start: 0,
    },

    validate: {
      hr_min: (value) => value === null ? (translations.hrMinIsRequired ?? 'translations.hrMinIsRequired') : null,
      hr_max: (value) => value === null ? (translations.hrMaxIsRequired ?? 'translations.hrMaxIsRequired') : null,
      difficulty: (value) => value === null ? (translations.difficultyIsRequired ?? 'translations.difficultyIsRequired') : null,
      patient_id: (value) => value <= 0 ? (translations.patientID ?? "translations.patientID") : null,
      user_device_id: (value) => value <= 0 ? (translations.deviceID ?? "translations.deviceID") : null,
      room: (value) => value !== 'OceanMap' && value !== 'VOLCANO' && value !== 'Stadium' ? (translations.stanza ?? "translations.stanza") : null,
      status: (value) => value !== 'draft' && value !== 'queue' ? (translations.statusStatus ?? "translations.statusStatus") : null,
    },
  });

  useEffect(() => {
    setFetchingPatients(true)
    AugmentedFoodService.listPatients()
        .then(setPatients)
        .catch(genericError)
        .finally(() => setFetchingPatients(false))

    setFetchingTokens(true);
    UserDevicesService.listUserDevices()
        .then(setTokens)
        .catch(genericError)
        .finally(() => setFetchingTokens(false))
  }, [opened])

  useEffect(() => {
    if ( opened ) {
      form.reset();
    }
  }, [opened])

  const onSubmit = (values: any) => {
    const c = structuredClone(values);
    setIsSaving(true)
    if ( !c.is_recording ) {
      delete c.is_recording;
    }
    AugmentedPerformanceService
        .createConfiguration(c)
        .then((r) => {
          close(r);
        })
        .catch(genericError)
        .finally(() => setIsSaving(false))
  }


  return (
      <>
        <Modal opened={opened}
               onClose={() => close(null)}
               title={translations.newConfig ?? "translations.newConfig"}
               closeOnClickOutside={false}
               zIndex={999}
               lockScroll={false}
               size={500}>

          <form onSubmit={form.onSubmit(onSubmit)}>

            <Stack>


              <Select
                  icon={fetchingPatients ?
                      <FontAwesomeIcon icon={faSpinner} spin/> : null}
                  disabled={fetchingPatients}
                  required
                  withinPortal
                  zIndex={1001}
                  {...form.getInputProps('patient_id')}
                  label={translations.selectPatient ?? "translations.selectPatient"}
                  searchable
                  clearable
                  placeholder={translations.searchPatient ?? "translations.searchPatient"}
                  data={patients.map(p => ({ value: p.id, label: p.code }))}
              />


              <Select
                  required
                  withinPortal
                  zIndex={1001}
                  {...form.getInputProps('room')}
                  label={translations.labelRoom ?? "translations.labelRoom"}
                  placeholder={translations.selectRoom ?? "translations.selectRoom"}
                  data={[
                    {
                      value: 'VOLCANO',
                      label: translations.volcano ?? "translations.volcano"
                    },
                    {
                      value: 'OceanMap',
                      label: translations.ocean ?? "translations.ocean"
                    },
                    {
                      value: 'Stadium',
                      label: "Stadio"
                    },
                  ]}
              />


              <Select
                  required
                  withinPortal
                  zIndex={1001}
                  icon={fetchingTokens ?
                      <FontAwesomeIcon icon={faSpinner} spin/> : null}
                  disabled={fetchingTokens}
                  {...form.getInputProps('user_device_id')}
                  label={translations.selectDevice ?? "translations.selectDevice"}
                  searchable
                  clearable
                  placeholder={translations.pickOne ?? "translations.pickOne"}
                  data={tokens.filter(t => t.device_type === 'oculus').map((t: any) => ({
                    value: t.id,
                    label: t.device_name
                  }))}
              />


              <NumberInput label={translations.hrMin ?? "translations.hrMin"}
                           {...form.getInputProps('hr_min')}/>

              <NumberInput label={translations.hrMax ?? "translations.hrMax"}
                           {...form.getInputProps('hr_max')}/>

              <NumberInput
                  label={translations.difficulty ?? "translations.difficulty"}
                  {...form.getInputProps('difficulty')}/>

              <NumberInput
                  min={-2}
                  max={2}
                  label={translations.startFromLevel ?? "translations.startFromLevel"}
                  {...form.getInputProps('start')}/>
            </Stack>


            <Divider mt={24} mb={24}/>
            <Stack>

              <Select
                  required
                  withinPortal
                  zIndex={1001}
                  {...form.getInputProps('status')}
                  label={translations.labelStatus ?? "translations.labelStatus"}
                  readOnly={true}
                  data={[
                    {
                      value: 'draft',
                      label: translations.bozza ?? "translations.bozza"
                    },
                    {
                      value: 'queue',
                      label: translations.coda ?? "translations.coda"
                    },
                  ]}
              />
              <Group position="right" mt="md">
                <Button type="submit"
                        loading={isSaving}
                        disabled={isSaving}
                >
                  {translations.buttonCreate ?? "translations.buttonCreate"}
                </Button>
              </Group>
            </Stack>
          </form>
        </Modal>
      </>
  );
}
export default NewConfigurationPerformance;