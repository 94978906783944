import React from "react";
import {Outlet} from "react-router-dom";

const PublicLayout: React.FC = () => {
    return <>
        <div>Public layout</div>

        <Outlet />
    </>
}

export default PublicLayout;