import VerificationInput from "react-verification-input";
import {
    Box,
    Button,
    Container,
    createStyles,
    Flex,
    Group,
    LoadingOverlay,
    Paper,
    rem,
    Stepper,
    Text,
    Title,
} from '@mantine/core';
import {useForm} from "@mantine/form";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import AuthService from "../../../services/AuthService";
import {setUser} from "../../../store/auth/authSlice";
import {genericError} from "../../../functions/genericError";


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));


export default function SetupMFA() {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [svg, setSvg] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [active, setActive] = useState(0);
    const {translations} = useSelector((state: any) => state.lang)

    const form = useForm({
        initialValues: {
            code: "",
        },

        validate: {
            code: (value) => (value && value.length === 6 ? null : (translations.validateCode ?? "translations.validateCode")),
        },
    });


    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    const onSubmit = (values: any) => {
        setInProgress(true)
        AuthService.confirmMfa({code: values.code})
            .then(response2 => {
                dispatch(setUser({
                    authState: response2.state,
                    token: response2.token,
                    user: response2.user,
                }))
            })

            .catch((error2) => {
                genericError(error2)

            })
            .finally(() => setInProgress(false))
    }


    const setup = () => {
        setInProgress(true)
        AuthService.setupMfa()
            .then(response => {
                setSvg(response.svg)
                setUrl(response.url)
                setCode(response.code)
                nextStep()
            })

            .catch((error) => {
                genericError(error)

            })
            .finally(() => setInProgress(false))
    }


    return (
        <>
            <Container size={600} my={30}>
                <LoadingOverlay visible={inProgress} overlayBlur={2} transitionDuration={1000}/>
                <Stepper active={active} onStepClick={setActive} breakpoint="sm" allowNextStepsSelect={false}>
                    <Stepper.Step label={translations.firstStep ?? "translations.firstStep"}
                                  description={translations.firstStepDesc ?? "translations.firstStepDesc"}>

                        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                            <Title className={classes.title} align="center">
                                {translations.setupMfaTitle ?? "translations.setupMfaTitle"}
                            </Title>
                            <Text c="dimmed" fz="sm" ta="center">
                                {translations.firstStepText ?? "translations.firstStepText"}
                            </Text>
                            <Flex justify="center"
                                  direction="column"
                                  mt={32}
                                  gap={24}
                                  align="center">
                                <Button className={classes.control}
                                        onClick={setup}>{translations.firstStepButton ?? "translations.firstStepButton"}</Button>
                            </Flex>
                        </Paper>
                    </Stepper.Step>
                    <Stepper.Step label={translations.secondStep ?? "translations.secondStep"}
                                  description={translations.connectAuth ?? "translations.connectAuth"}>
                        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                            <Title className={classes.title} align="center">
                                {translations.connectAuthApp ?? "translations.connectAuthApp"}
                            </Title>
                            <Flex justify="center"
                                  align="center"
                                  direction="column"
                                  gap={24}
                                  mt={32}>
                                {url ? <>
                                    <Text c="dimmed" fz="sm"
                                          ta="center">{translations.clickLink ?? "translations.clickLink"}</Text>
                                    <a href={url}>{translations.mobileLink ?? "translations.mobileLink"}</a>
                                    <Group position="center">
                                        <Text c="dimmed" fz="sm"
                                              ta="center">{translations.manualConnection ?? "translations.manualConnection"}</Text>
                                        <Text fw={700}>{code}</Text>
                                    </Group></> : null}
                                {svg ? <><Text c="dimmed" fz="sm"
                                               ta="center">{translations.scanQR ?? "translations.scanQR"}</Text><Box
                                    dangerouslySetInnerHTML={{__html: svg}}/>
                                    <Text c="dimmed" fz="md" ta="center"
                                          fw={500}>{translations.authRequired ?? "translations.authRequired"}</Text>
                                </> : null}
                            </Flex>
                        </Paper>
                        <Group position="center" mt="xl">
                            <Button variant="default"
                                    onClick={prevStep}>{translations.backMfa ?? "translations.backMfa"}</Button>
                            <Button
                                onClick={nextStep}>{translations.goVerification ?? "translations.goVerification"}</Button>
                        </Group>
                    </Stepper.Step>
                    <Stepper.Step label={translations.lastStep ?? "translations.lastStep"}
                                  description={translations.lastStepDesc ?? "translations.lastStepDesc"}>
                        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">

                            <Title className={classes.title} align="center">
                                {translations.verifyMfa ?? "translations.verifyMfa"}
                            </Title>
                            <Text c="dimmed" fz="sm" ta="center">
                                {translations.insertMfa ?? "translations.insertMfa"}
                            </Text>
                            <form onSubmit={form.onSubmit(onSubmit)}>
                                <LoadingOverlay visible={inProgress} overlayBlur={2}/>
                                <Flex justify="center"
                                      align="center"
                                      direction="column"
                                      gap={24}
                                      mt={32}>
                                    <VerificationInput validChars="0-9"
                                                       inputProps={{inputMode: "numeric"}} {...form.getInputProps('code')}/>
                                    <Button className={classes.control}
                                            type={"submit"}>{translations.mfaConfirm ?? "translations.mfaConfirm"}</Button>
                                </Flex>
                            </form>
                            <Text c="dimmed" fz="md" ta="center" fw={500}
                                  mt={24}>{translations.alertMfa ?? "translations.alertMfa"}</Text>
                        </Paper>
                        <Group position="center" mt="xl">
                            <Button variant="default"
                                    onClick={prevStep}>{translations.backMfa ?? "translations.backMfa"}</Button>
                        </Group>
                    </Stepper.Step>
                </Stepper>

            </Container>
        </>
    );


}