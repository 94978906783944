export function ptTranslations() {
  return {
    "created": "",
    "queue": "Queue",
    "completed": "Completado",
    "active": "Ativo",
    "IAccept": "Eu li e aceito os",
    "termsAndConditions": "termos e condições",
    "privacyPolicy": "política de privacidade",
    "restaurant": "Restaurante",
    "stadium": "Estádio",
    "aCActions": "Acções",
    "aCAlert": "A eliminação desta sessão também elimina todos os resultados relacionados.",
    "aCBreadcrumbsApps": "Todas as aplicações",
    "aCBreadcrumbsConfig": "Sessões",
    "aCCancel": "Cancelar",
    "accedi": "Iniciar sessão",
    "aCCompleted": "Concluída em",
    "aCCreatedAt": "Criado em:",
    "aCDelete": "Eliminar",
    "aCDeleteConf": "Confirmar o cancelamento da sessão através do id:",
    "aCDevice": "Dispositivo",
    "aCDownload": "Descarregar CSV",
    "aCFood": "Alimentos",
    "aCFoodList": "Nome da lista de alimentos",
    "aCfoodListT": "Lista de alimentos",
    "aCLevel": "Nível",
    "aCLoading": "A carregar...",
    "aCNoResults": "Nenhum resultado",
    "aCPatient": "Código do doente",
    "aCPatients": "Doentes",
    "aCPatientsSearch": "Pesquisar paciente...",
    "aCPaziente": "Doente",
    "aCREC": "REG",
    "aCRoom": "Quarto",
    "aCSelectPH": "Filtrar por estado...",
    "aCSessionId": "ID da sessão:",
    "aCStatus": "Estado",
    "actionId": "Id:",
    "actionName": "Nome:",
    "addCart": "Adicionar ao carrinho",
    "addPatient": "Adicionar paciente",
    "addressS": "Endereço",
    "affogato": "Afogado",
    "alertMfa": "Só pode verificar o seu código depois de ligar com êxito um Autenticador",
    "alertUseApp": "Para começar a utilizar esta aplicação, contacte o nosso apoio em",
    "alertWarning": "Atenção!",
    "allApps": "Todas as aplicações",
    "allPatients": "Todos os pacientes",
    "allSessionsB": "Todas as sessões",
    "allSessionsLabel": "Adicione pelo menos um doente e crie uma lista de alimentos antes de criar uma sessão",
    "ambitoDelCorso": "Tópicos do curso",
    "anguria": "Melancia",
    "answerNo": "Não",
    "answerYes": "Sim",
    "applicazioni": "Aplicação",
    "appSubTitle": "As nossas ferramentas inovadoras de Realidade Virtual e Biosensor para profissionais de saúde mental - psicólogos, médicos, psicoterapeutas, neurocientistas, investigadores - estão constantemente a ser actualizadas.",
    "appSubTwoTitle": "Descubra as nossas aplicações, experiências imersivas e psicotecnologias para a prática clínica, de reabilitação, organizacional, desportiva, escolar e de bem-estar.",
    "appTitle": "AS NOSSAS APLICAÇÕES DE REALIDADE VIRTUAL",
    "arachidi": "Amendoins",
    "arancia": "Laranja",
    "arrosto": "Assado",
    "articoli": "Artigos mais recentes",
    "authRequired": "Só depois de ligar um Autenticador é que pode prosseguir com a Verificação",
    "azienda": "É uma empresa?",
    "baba": "Baba",
    "backHome": "Voltar à página inicial",
    "backLogin": "Voltar ao início de sessão",
    "backMfa": "Voltar",
    "banana": "Banana",
    "barrette": "Barras de cereais",
    "baseline": "Linha de base",
    "becomeText": "A BECOME cria soluções inovadoras de tecnologia e formação para a saúde mental",
    "benvenuto": "Bem-vindo* ### à BECOME.",
    "biscotti": "Biscoitos",
    "bistecca": "Bife",
    "bombolone": "Bombolone",
    "bozza": "Guardar como rascunho",
    "breadcrumbFoodList": "Listas de alimentos",
    "breadcrumbPatients": "Doentes",
    "breadcrumbsAllApps": "Todas as aplicações",
    "bresaola": "Bresaola",
    "bruschetta": "Bruschetta",
    "bug": "Comunicar um erro",
    "burger": "Hambúrguer",
    "buttonCreate": "Criar",
    "buttonEsplora": "Explorar",
    "buyNow": "Comprar agora",
    "calzone": "Calzone",
    "cannolo": "Cannoli",
    "cannoncino": "Cannoncino",
    "caramelle": "Doces",
    "carote": "Cenouras",
    "categoryS": "Categoria selecionada:",
    "certificati": "Certificados:",
    "certificato": "Certificado:",
    "chips": "Batatas fritas",
    "ciliege": "Cerejas",
    "cioccolato": "Chocolate",
    "clickLink": "Clique na ligação para concluir a autenticação",
    "coda": "Guardar e colocar em fila de espera",
    "codeField": "Código de confirmação",
    "confirmPw": "Confirmar palavra-passe",
    "confirmPwLabel": "A sua palavra-passe",
    "confirmReg": "Confirmar",
    "connectAuth": "Ligar um Autenticador",
    "connectAuthApp": "Ligar uma aplicação de autenticador",
    "connectTitle": "Ligar um novo dispositivo",
    "cono": "Cone",
    "contattaci": "Contactar-nos",
    "cookies": "Cookies",
    "corn_flakes": "Flocos de milho",
    "corsi": "Cursos",
    "corsiNoECM": "Cursos de formação assíncronos sem CME",
    "corsiTitle": "OS NOSSOS CURSOS ECM",
    "corsiTitleTwo": "Subscreva a nossa newsletter para não perder os próximos cursos para psicólogos, médicos, psicoterapeutas, neurocientistas, investigadores e estudantes universitários.",
    "coscia_di_pollo": "Coxa de frango",
    "cotoletta": "Schnitzel",
    "courseContent": "Tópicos do curso",
    "courseDescr": "Descrição do curso",
    "courseDetailsAuthor": "Autores:",
    "courseDetailsLastUpdate": "Atualizado em:",
    "crackers": "Crackers",
    "createAcc": "Criar uma conta",
    "createAccount": "Criar uma conta gratuita",
    "createList": "Criar nova lista",
    "crocchette": "Croquetes",
    "croissant": "Croissants",
    "crostata": "Torta",
    "cucina": "Cozinha",
    "bedroom": "Quarto",
    "cupcake": "Cupcakes",
    "currentDisabled": "Secção temporariamente desactivada",
    "deleteBCancel": "Cancelar",
    "deleteBDelete": "Apagar",
    "deleteConfirm": "Confirmar a anulação? ###",
    "deleteWarning": "Ao eliminar a lista de alimentos, elimina também todas as configurações e os respectivos resultados.",
    "descriptionBug": "Descrição",
    "dettagli": "Detalhes",
    "deviceID": "Tem de existir um dispositivo",
    "deviceName": "Nome:",
    "devicesMain": "Dispositivos",
    "devicesMainText": "Nesta página pode encontrar os tokens de acesso à aplicação Become.",
    "deviceTotal": "Dispositivos",
    "diurna": "Ativar o modo diurno",
    "docTitle": "BECOME | Todos os cursos",
    "donut": "Donut",
    "durata": "Duração do curso",
    "eLearning": "Plataforma de e-Learning | CME",
    "emailL": "Correio eletrónico",
    "emailLabel": "Correio eletrónico",
    "emailR": "Correio eletrónico",
    "emailS": "Correio eletrónico",
    "entra": "Iniciar sessão",
    "expired": "Palavra-passe expirada",
    "fetchingLoading": "Carregando...",
    "fetchingNoResults": "Nenhum resultado",
    "fetchLoading": "Carregando...",
    "fetchNoResults": "Sem resultados",
    "fette_biscottate": "Biscoitos",
    "filteR": "Filtros",
    "firstStep": "Primeiro passo",
    "firstStepButton": "Definir AMF",
    "firstStepDesc": "Definir a MFA",
    "firstStepText": "Aumente a segurança da sua conta através da autenticação multi-fator",
    "focaccia": "Focaccia",
    "fontDecrease": "Diminuir o tamanho da letra",
    "fontIncrease": "Aumentar o tamanho da letra",
    "food": "Alimentos",
    "foodList": "Selecionar lista de alimentos",
    "foodListCreate": "Criar lista",
    "foodListID": "Tem de existir uma lista de alimentos",
    "foodListName": "Nome da lista",
    "forgotPw": "Esqueceu-se da sua palavra-passe?",
    "formaggio": "Queijo",
    "fritto_misto": "Misto frito",
    "frutti_di_bosco": "Bagas",
    "fullName": "Nome completo",
    "gamberi": "Camarão",
    "genera": "Gerar",
    "getStarted": "Começar agora",
    "goCourses": "Ir para cursos gratuitos",
    "gorgonzola": "Gorgonzola",
    "goToCart": "Ver todo o carrinho de compras",
    "goToDevice": "Ir para dispositivos",
    "goToLists": "Ir para listas",
    "goVerification": "Ir para a verificação",
    "hamburger": "Hambúrguer",
    "heartRate": "Batimento cardíaco",
    "hot_dog": "Cachorros quentes",
    "inCart": "Já no carrinho",
    "includeApp": "Aplicação incluída",
    "infoD": "Informações",
    "insalata": "Salada",
    "insalata_orzo": "Salada de cevada",
    "insertCodeText": "Para completar o registo, introduza o código que enviámos para o seu endereço eletrónico.",
    "insertCodeTitle": "Introduzir o código de confirmação",
    "insertEmail": "Introduza o seu e-mail, receberá um código para prosseguir com a reposição",
    "insertMfa": "Introduza o código de confirmação para concluir a configuração do MFA.",
    "insertPw": "Introduza uma nova palavra-passe.",
    "insertPwText": "Escolha uma palavra-passe complexa e única para segurança.",
    "inThisPage": "Nesta página, pode ver os cursos oferecidos pela BECOME.",
    "invalidName": "Nome inválido",
    "invalidPw": "Palavra-passe inválida",
    "kebab": "Kebab",
    "labelAddress": "Endereço",
    "labelBachelor": "Título",
    "labelCode": "Código",
    "labelNAME": "Nome e apelido",
    "labelProfession": "Profissão",
    "labelProfile": "Gostaria de aparecer na página dos Profissionais Certificados?",
    "labelRecord": "Registar resultados Fitbit?",
    "labelRemoveCart": "Remover do carrinho",
    "labelRoom": "Selecionar quarto",
    "labelState": "Região",
    "labelStatus": "Estado",
    "labelSurname": "Último nome",
    "labelWebsite": "Sítio Web",
    "lasagne": "Lasanha",
    "lastStep": "Última etapa",
    "lastStepDesc": "Código de verificação",
    "liveDemo": "Demonstração em direto",
    "liveRegister": "Registo em direto",
    "loginFCPw": "Iniciar sessão",
    "loginFromMobile": "Iniciar sessão a partir do telemóvel",
    "loginValidateEmail": "Endereço de correio eletrónico inválido",
    "loginWithQR": "Inscrever-se em QRCODE.",
    "macarons": "Macarons",
    "macedonia": "Macedónia",
    "manualConnection": "Ou adicione manualmente o seguinte código na sua aplicação de autenticação",
    "meringa": "Merengue",
    "mfaBackLogin": "Voltar ao início de sessão",
    "mfaConfirm": "Confirmar código",
    "mfaSetup": "Configurou com êxito a autenticação de dois factores (MFA) na sua conta.",
    "mfaSetupText": "Reforçou a segurança da sua conta.",
    "mfaText": "Introduza o seu código MFA para concluir o início de sessão.",
    "mfaTitle": "Introduza o seu código MFA.",
    "mfaValidate": "O código MFA deve conter 6 números",
    "minestrone": "Minestrone",
    "mobileLink": "Ligação para dispositivos móveis",
    "modalConnect": "Ligar outro dispositivo",
    "modalConnectButton": "Ligar",
    "modalLabelCancel": "Cancelar",
    "modalLabelConfirm": "Desativar",
    "modalRemoveConfirm": "Clique para confirmar a sua escolha",
    "modalRemoveMfa": "Está prestes a remover a autenticação de dois factores (MFA) da sua conta.",
    "modalTitle": "Nova lista de alimentos",
    "modalTitleP": "Novo paciente",
    "more": "Mais",
    "muffin": "Muffin",
    "myDevices": "Os meus aparelhos",
    "myOrder": "A minha encomenda:",
    "nameTable": "Nome",
    "newConfig": "Nova configuração",
    "newSession": "Nova sessão",
    "nighiri": "Nighiri",
    "noAccount": "Não tem uma conta?",
    "noCertificati": "Sem certificado",
    "noPower": "Não tem autoridade aqui.",
    "notAuth": "Não autorizado",
    "notAuthText": "Não tem permissões para aceder a este conteúdo",
    "notFound": "Página não encontrada",
    "notFoundtext": "O endereço que seleccionou não existe ou foi removido.",
    "notification": "Será notificado assim que o certificado estiver pronto",
    "notificationText": "Tratámos do seu pedido. Será notificado dentro de alguns minutos.",
    "notificationTextTwo": "Se a notificação não chegar, tente recarregar esta página.",
    "notturna": "Ativar o modo noturno",
    "ocean": "Oceano",
    "oneDevice": "Tem pelo menos um dispositivo?",
    "oneList": "Tem pelo menos uma lista de alimentos?",
    "onePatient": "Tem pelo menos um paciente?",
    "orderSummary": "Resumo da encomenda:",
    "pACRooms": "Quartos",
    "paginaprincipale": "Casa",
    "panettone": "Panettone",
    "parmigiana": "Parmigiana",
    "passwordL": "Palavra-passe",
    "passwordLabel": "Por favor, introduza pelo menos 6 caracteres",
    "passwordLPH": "A sua palavra-passe",
    "passwordR": "Palavra-passe",
    "pasta": "Massa",
    "pasticcino_frutta": "Pastelaria de fruta",
    "patatine_fritte": "Batatas fritas",
    "patientAlert": "Por razões de segurança e privacidade, preferimos não armazenar os dados dos pacientes nos nossos servidores. Lembre-se de escrever o código do paciente num livro/agenda física.",
    "patientID": "Tem de haver um código de doente",
    "pere": "Peras",
    "pesce": "Peixe",
    "pesce_alla_griglia": "Peixe grelhado",
    "pesce_spada": "Espadarte",
    "pesche": "Pêssegos",
    "phoneS": "Telefone",
    "piattaforma": "Ir para a plataforma",
    "pickOne": "Selecionar um dispositivo",
    "pizza": "Pizza",
    "placeholderBachelor": "Dr*",
    "pollo_ai_ferri": "Frango grelhado",
    "pomodori": "Tomates",
    "praline": "Pralinés",
    "profAddress": "Nenhum endereço introduzido",
    "profes": "É um profissional?",
    "professionalTitle": "A partir desta secção, pode ativar o seu perfil público na página dos profissionais",
    "professione": "Profissão não indicada",
    "professionistiLabel": "Procurar",
    "professionistiPlaceholder": "Pesquisa por nome, endereço ou região...",
    "professionistiTitle": "Profissionais certificados",
    "profWebsite": "Site pessoal",
    "pwLabel": "A sua palavra-passe",
    "pwNotMatch": "As palavras-passe não coincidem",
    "queuedConfigurations": "Sessões em fila de espera",
    "recordingStart": "Iniciar gravação",
    "recordingStop": "Parar a gravação",
    "registerR": "Registar",
    "registrati": "Registar",
    "rememberMeL": "Lembrar",
    "removeCart": "Remover",
    "removeMfa": "Remover a MFA",
    "removeMfaInstruction": "Se pretender remover a autenticação de dois factores (MFA), introduza a sua palavra-passe e, em seguida, prima o botão abaixo",
    "reqPwLabelFour": "Inclui caracteres especiais",
    "reqPwLabelOne": "Inclui números",
    "reqPwLabelThree": "Inclui letras maiúsculas",
    "reqPwLabelTwo": "Incluir letras minúsculas",
    "resetFilter": "Limpar filtros",
    "resetPassword": "Verificar a sua caixa de correio",
    "resetPw": "Repor a palavra-passe",
    "ricerca": "Pesquisar",
    "ricotta": "Ricota",
    "riso": "Arroz",
    "ristorante": "Restaurante",
    "kitchen": "Cucina",
    "dining_room": "Sala de jantar",
    "sacher": "Sacher",
    "saint_honoré": "São Lourenço",
    "salame": "Salame",
    "salatini": "Salatini",
    "salsiccia": "Salsichas",
    "SAVE": "Guardar",
    "scanQR": "Ou digitalizar o código QR",
    "scarica": "Descarregar",
    "sClose": "Desconectado",
    "sClosing": "Desconectado",
    "sConnecting": "Tentativa de ligação",
    "searchByName": "Pesquisar por nome",
    "searchPatient": "Pesquisa por código de paciente",
    "secondStep": "Segundo passo",
    "selectDevice": "Selecionar um dispositivo",
    "selectLanguage": "Selecionar o idioma",
    "selectPatient": "Selecionar um doente",
    "selectRoom": "Selecionar um quarto",
    "selectTheme": "Ativar o modo",
    "sendEmail": "Escreva-nos um e-mail, será contactado no prazo de 24 horas.",
    "sendReport": "Enviar relatório",
    "serverConnect": "Ligação ao servidor:",
    "sessioni": "Sessões",
    "setUpDevice": "Configurar um novo dispositivo",
    "setupMfaTitle": "Configurar o seu código MFA.",
    "sezione": "Secção Certificados e materiais",
    "sidebarApp": "App",
    "sidebarCourses": "Cursos",
    "sidebarDevices": "Dispositivos",
    "sidebarHome": "Início",
    "sidebarMaterials": "Materiais",
    "sidebarProfessionals": "Página de profissionais",
    "sidebarSecurity": "Segurança",
    "sidebarSupport": "Apoio",
    "signUp": "Registo",
    "simmenthal": "Simmenthal",
    "snack": "Lanche",
    "snack_cioccolato": "Snack de chocolate",
    "soluzioni": "Soluções",
    "sOpen": "Ligados",
    "spiedini": "Espetos",
    "stanza": "Selecionar uma sala válida",
    "statusStatus": "Selecionar um estado válido",
    "stinco": "Canela",
    "subtotale": "Subtotal",
    "success": "Sucesso",
    "sUninstantiated": "Não instanciado",
    "sushi": "Sushi",
    "tableActions": "Acções",
    "tableCode": "Código",
    "tableConnect": "Ligar",
    "tableConnections": "Dispositivos ligados",
    "tableCreateAt": "Criado em",
    "tableDeviceName": "Nome do dispositivo",
    "tableDeviceType": "Tipo de palavra-passe",
    "tableOneTime": "Palavra-passe única",
    "tableQR": "Código QR",
    "taco": "Taco",
    "tartare_tonno": "Tártaro de atum",
    "tax": "#NAME?",
    "taxText": "Impostos",
    "taxToPay": "Calculado no checkout",
    "themeDark": "noite",
    "themeLight": "dia",
    "titleActions": "Acções",
    "titleBug": "Título",
    "titleDevice": "Dispositivo",
    "titleFoodList": "Lista de alimentos",
    "titlePatient": "Doente",
    "titleProfessional": "Página profissional",
    "titleRoom": "Sala",
    "toOrder": "Proceder à encomenda",
    "topic": "Assunto",
    "torta_margherita": "Bolo Margarida",
    "torta_paradiso": "Bolo Paraíso",
    "tortilla": "Tortilha",
    "totalConfigurationsTable": "Número de configurações",
    "totalFoodTable": "Número de alimentos",
    "totalPatients": "Pacientes",
    "totalPatientsLabel": "Precisa de mais pacientes? Actualize o seu plano atual",
    "totatListsFood": "Listas de alimentos",
    "totatListsFoodLabel": "Precisa de mais listas de alimentos? Atualizar o plano atual",
    "uovo_e_bacon": "Ovo e bacon",
    "upgradeLabelConfig": "Precisa de mais configurações? Actualize o seu plano atual",
    "upgradeLabelDevice": "Precisa de mais dispositivos? Atualizar o plano atual",
    "upgradePlan": "Atualizar plano",
    "uva": "Uva",
    "validate": "E-mail inválido",
    "validateCode": "O código de verificação deve ser composto por 6 letras",
    "validateEmail": "E-mail inválido",
    "validateName": "O nome tem de ter pelo menos 3 letras",
    "validatePw": "Palavra-passe inválida",
    "variation": "Variação",
    "verdure_grigliate": "Legumes grelhados",
    "verifyMfa": "Verifique o seu código MFA.",
    "videoPlayer": "Pré-visualização do curso",
    "viewCart": "Ver carrinho",
    "volcano": "Vulcano",
    "warningAlert": "Para se autenticar num dispositivo, introduza a ### One Time Password ### de um token (se estiver \"disponível\"). ### Se todos os tokens disponíveis se esgotaram e você precisa se autenticar em um dispositivo, entre em contato com nosso",
    "warningAlertSupport": "suporte",
    "warningText": "Uma vez criada a lista, já não será possível modificá-la, uma vez que isso invalidaria os resultados já armazenados.",
    "welcome": "Bem-vindo!",
    "whatYouFind": "Aqui pode encontrar cursos de acesso livre e rever os cursos CME que frequentou gratuitamente.",
    "whatYouWLearn": "O que vai aprender",
    "workS": "Horas de trabalho",
    "yogurt": "Iogurte",
    "youCart": "O seu carrinho de compras",
    "a_cake": "A. Bolo",
    "a_pizza": "A.Pizza",
    "a_chips": "A. Chips",
    "a_nuts": "A. Nozes",
    "a_cookies": "A. Biscoitos",
    "a_ice_cream": "A. Gelado",
    "a_chocolate": "A.Chocolate",
    "a_bread": "A. Pão",
    "a_donuts": "A. Donuts",
    "a_brownies": "A. Brownies",
    "a_spaghetti": "A. Espaguete",
    "b_pastel": "Pastel",
    "a_rice": "A. Arroz",
    "a_fruit": "A. Fruta",
    "a_salad": "A. Salada",
    "a_yogurt": "A. Iogurte",
    "a_pancakes": "A. Panquecas",
    "a_lean_meat": "A. Corte",
    "a_fried_chicken": "A. Frango frito",
    "a_peanut_butter": "A. Manteiga de amendoim",
    "a_snacks": "A. Lanches",
    "a_popcorn": "A. Pipocas",
    "a_pastries": "A. Pastelaria doce",
    "a_cereal": "A. Cereais",
    "a_bacon": "A.Bacon",
    "a_steak": "A. Bife",
    "a_baked_potato": "A. Batatas",
    "a_cupcakes": "A.Bolinhos",
    "b_brigadeiro": "B. Brigadeiro",
    "b_joelho": "B. Joelho",
    "b_orange_juice": "B. Orange Juice",
    "b_soft_drink": "B. Soft Drink",
    "b_spaghetti_bolognese": "B. Pasta",
    "b_açai_bowl": "B. Açai Bowl",
    "b_paçoca": "B. Paçoca",
    "b_pão_de_queijo": "B. Pão de Queijo",
    "b_oreo": "B. Oreo",
    "b_beef_stroganoff": "B. Beef Stroganoff",
    "b_empada": "B. Empada",
    "b_coxinha_de_galinha": "B. Coxinha de Galinha",
    "difficulty": "Tempo de registo da linha de base (em segundos)",
    "hrMin": "Alteração percentual",
    "hrMax": "Duração da sessão (em segundos)"
  }
}