import {createSlice} from '@reduxjs/toolkit'

export const langSlice = createSlice({
    name: 'lang',
    initialState: {
        retrievingLang: true,
        lang: 'it',
        translations: {},
    },
    reducers: {
        setRetrievingLang: (state, action) => {
            state.retrievingLang = action.payload;
        },
        setLanguage: (state, action) => {
            state.lang = action.payload;
            localStorage.setItem('lang', action.payload);
        },
        setTranslations: (state, action) => {
            state.translations = action.payload;
            state.retrievingLang = false;
        },
    },
});

export const {setLanguage, setTranslations, setRetrievingLang} = langSlice.actions

export default langSlice.reducer