import React from "react";
import {Flex, Stack, Text, ThemeIcon} from '@mantine/core';
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons";
/* import {faPhone} from "@fortawesome/pro-regular-svg-icons/faPhone"; */
import {faMapMarked} from "@fortawesome/pro-regular-svg-icons/faMapMarked";
import {faSun} from "@fortawesome/pro-regular-svg-icons/faSun";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useSelector} from "react-redux";


function ContactIcon(props: { icon: IconProp, translationTitle: string, description: string }) {
    const {translations} = useSelector((state: any) => state.lang);

    return (
        <Flex align={'center'}>
            <ThemeIcon size={40} radius="md">
                <FontAwesomeIcon icon={props.icon} size={'lg'}/>
            </ThemeIcon>


            <div style={{marginLeft: 24}}>
                <Text size="xs">
                    {translations[props.translationTitle]}
                </Text>
                <Text>{props.description}</Text>
            </div>
        </Flex>
    );
}


const MOCKDATA = [
    {title: 'Email', description: 'support@become-hub.com', translationTitle: 'emailS', icon: faEnvelope},
    // {title: 'Telefono', description: '+49 (800) 335 35 35', translationTitle: 'phoneS', icon: faPhone},
    {
        title: 'Indirizzo',
        description: 'Via Carlo Valvassori Peroni 74/2 - 20133 Milano',
        translationTitle: 'addressS',
        icon: faMapMarked
    },
    {title: 'Orario di lavoro', description: '8 a.m. – 7 p.m.', translationTitle: 'workS', icon: faSun},
];

export function ContactIconsList() {
    const items = MOCKDATA.map((item, index) => <ContactIcon key={index} {...item} />);
    return <Stack>{items}</Stack>;
}
