import React, { forwardRef, useEffect, useState } from "react";
import {
    ActionIcon,
    Avatar,
    Box,
    Button,
    Card,
    Center,
    Collapse,
    Flex,
    Group,
    List,
    LoadingOverlay,
    Modal,
    RingProgress,
    Select,
    Tabs,
    Text,
    TextInput,
    Title
} from "@mantine/core";
import { IUser } from "../../interfaces/IUser";
import AdminUserService from "../../services/admin/UserService";
import { genericError } from "../../functions/genericError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ICourse } from "../../interfaces/ICourse";
import { faLoader } from "@fortawesome/pro-regular-svg-icons/faLoader";
import { getColor } from "../../functions/stringToColor";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faUserTie } from "@fortawesome/pro-regular-svg-icons/faUserTie";
import {
    faPeopleGroup
} from "@fortawesome/pro-regular-svg-icons/faPeopleGroup";
import {
    faGraduationCap
} from "@fortawesome/pro-regular-svg-icons/faGraduationCap";
import { faVideo } from "@fortawesome/pro-regular-svg-icons/faVideo";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons/faCreditCard";
import { faShield } from "@fortawesome/pro-regular-svg-icons/faShield";
import {
    faHeadSideGoggles
} from "@fortawesome/pro-regular-svg-icons/faHeadSideGoggles";
import { DataTable } from "mantine-datatable";
import {
    faCheckCircle
} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faWrench } from "@fortawesome/pro-regular-svg-icons/faWrench";
import UserHasCourseModal from "./UserHasCourseModal";
import { showNotification } from "@mantine/notifications";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import UserHasVideoModal from "./UserHasVideoModal";
import { IVideo } from "../../interfaces/IVideo";
import CourseService from "../../services/CourseService";
import { useForm } from "@mantine/form";
import { DatePickerInput } from "@mantine/dates";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { format } from "date-fns";
import { IRole } from "../../interfaces/IRole";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import VideoService from "../../services/VideoService";
import {
    faBookOpenReader
} from "@fortawesome/pro-regular-svg-icons/faBookOpenReader";
import UserHasTestQuestionsModal from "./UserHasTestQuestionsModal";

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  category: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, category, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
          <Group noWrap>
            <Avatar src={image}/>
            <div>
              <Text size="sm">{label}</Text>
              <Text size="sm" c="dimmed">Category: {category}</Text>
            </div>
          </Group>
        </div>
    )
);
const UserModal: React.FC<{
  user: IUser | undefined,
  setUser: any,
  withinPortal?: boolean
}> = ({
        user,
        setUser,
        withinPortal = false
      }) => {

  const matches = useMediaQuery('(max-width: 900px)');
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingCourses, setIsLoadingCourses] = useState<boolean>(false)
  const [isLoadingVideos, setIsLoadingVideos] = useState<boolean>(false)
  const [advancedUser, setAdvancedUser] = useState<IUser>()
  const [advancedUserVideo, setAdvancedUserVideo] = useState<IUser>()
  const [courses, setCourses] = useState<ICourse[]>([])
  const [videos, setVideos] = useState<IVideo[]>([])
  const [selectedCourse, setSelectedCourse] = useState<string>('')
  const [selectedVideo, setSelectedVideo] = useState<string>('')
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [opened, { toggle }] = useDisclosure(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedUserCourseTests, setSelectedUserCourseTests] = useState<any>();
  const [selectedUserCourse, setSelectedUserCourse] = useState<ICourse>();
  const [selectedUserVideo, setSelectedUserVideo] = useState<IVideo>();
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [selectedTeam, setSelectedTeam] = useState<string>('')
  const [openedRole, setOpenedRole] = useState<boolean>(false);
  const [openedTeam, setOpenedTeam] = useState<boolean>(false);

  const form = useForm<any>({
    initialValues: {
      selectedVideo: null,
      valid_until: null,
      subscription: "0",
    },
    validate: {
      selectedVideo: (value: any) => {
        if ( !value ) {
          return 'selectedVideo is required';
        }
        return undefined;
      },
      valid_until: (value: any) => {
        if ( !value ) {
          return 'Valid until is required';
        }
        return undefined;
      },
      subscription: (value: any) => {
        if ( !value ) {
          return 'subscription is required';
        }
        return undefined;
      },
    }
  });

  useEffect(() => {
    if ( user ) {
      setIsLoading(true);
      AdminUserService.getUser(user.id)
          .then((r) => {
            setAdvancedUser(r)
            setAdvancedUserVideo(r)
          })
          .catch(genericError)
          .finally(() => setIsLoading(false))
      setIsLoadingCourses(true)
      CourseService.list()
          .then(setCourses)
          .catch(genericError)
          .finally(() => setIsLoadingCourses(false))
      VideoService.listVideo()
          .then(setVideos)
          .catch(genericError)
          .finally(() => setIsLoadingCourses(false))
    }
    return () => {
      setAdvancedUser(undefined)
      setAdvancedUserVideo(undefined)
      setSelectedCourse('')
      setSelectedVideo('')
    }
  }, [user])

  useEffect(() => {
  }, [advancedUser]);
  useEffect(() => {
  }, [advancedUserVideo]);
  useEffect(() => {
  }, [videos]);

  useEffect(() => {
    if ( selectedVideo ) {
    } else {
    }
    form.reset()
  }, [selectedVideo]);

  useEffect(() => {
    if ( advancedUser?.roles?.some((r: IRole) => r.name === 'Supervisor') ) {
      setIsSupervisor(true)
    }
  }, [user]);

  const addCourseToUser = () => {
    if ( selectedCourse === '' ) return;
    setIsSaving(true)
    AdminUserService.addCourseToUser({
      user_id: user.id,
      course_id: parseInt(selectedCourse)
    })
        .then(setAdvancedUser)
        .catch(genericError)
        .finally(() => setIsSaving(false))
  }

  const generateSlices = (names: any) => {
    if ( names.length >= 2 ) {
      return names.slice(0, 2);
    } else if ( names.length === 1 ) {
      return names.repeat(2);
    }
    return '';

  };

  const updateUserHasCourseCallback = (r) => {
    setAdvancedUser(r)
  }

  const updateUserHasVideoCallback = (r) => {
    setAdvancedUserVideo(r)
  }


  const addOrDeleteRole = (roleId: number = parseInt(selectedRole)) => {
    if ( user ) {
      setIsSaving(true);
      AdminUserService.addOrDeleteRole({ user_id: user.id, role_id: roleId })
          .then((r: any) => {
            showNotification({
              color: 'teal',
              icon: <FontAwesomeIcon icon={faCheck}/>,
              title: 'Success',
              message: `${r.user}`
            });
            setIsLoading(true)
            AdminUserService.getUser(user.id)
                .then(setAdvancedUser)
                .catch(genericError)
                .finally(() => setIsLoading(false))
          })
          .catch((e) => {
            genericError(e)
            setIsSaving(false)
          })
          .finally(() => setIsSaving(false))
    }
  }

  function formOnSubmit(values: any) {
    const clone = structuredClone(values);
    clone.video_id = values.selectedVideo;
    clone.user_id = user.id;
    clone.subscription = values.subscription;
    //TODO Prende "no sub" al momento...
    setIsSaving(true)
    if ( values.valid_until ) {
      clone.valid_until = format(values.valid_until, 'yyyy-MM-dd');
    }
    setIsSelectOpen(false)
    setIsLoadingVideos(true)
    AdminUserService.addVideoToUser(clone)
        .then((r: any) => {

          setAdvancedUserVideo(r)
          showNotification({
            color: 'teal',
            icon: <FontAwesomeIcon icon={faCheck}/>,
            title: 'Success',
            message: `Video Id ${r.videos[0].video.id} added to the UserID ${r.videos[0].user_id}`,
          })
        })
        .catch(genericError)
        .finally(() => {
          setIsSaving(false)
          setIsLoadingVideos(false)
        })

  }


  return <Modal opened={user !== undefined}
                closeOnClickOutside={false}
                closeOnEscape={false}
                fullScreen
                onClose={() => setUser(undefined)}
                title={
                  <Flex direction={'row'} gap={16} align={'center'}>
                    {user ?
                        <Avatar radius="xl" style={{ margin: 'auto' }}
                                color={getColor(user?.name)}
                                tt="uppercase">{generateSlices(user?.name)}</Avatar> : null}
                    <Text>ID: {user?.id}</Text>
                    <Text>Email: {user?.email}</Text>
                    <Text>Name: {user?.name}</Text>
                  </Flex>
                }>

    <LoadingOverlay visible={isLoading}/>


    <Flex direction={matches ? 'column' : 'row'} gap={16} wrap={'wrap'}>
      <Card style={{ minWidth: 300, flex: matches ? 1 : 0.5 }} withBorder>
        <Title order={4} mb={16}><FontAwesomeIcon icon={faUserTie}/> User
          info</Title>
        <Text>Info that will be shared with Agenas or published on the
          "Professionals" page</Text>
        <Tabs defaultValue="ecm">
          <Tabs.List>
            <Tabs.Tab value="ecm">ECM</Tabs.Tab>
            <Tabs.Tab value="professional">Professional Page</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="ecm" pt="xs">
            <Text size="xs" color="dimmed">
              <b>Title:</b> {advancedUser?.info?.title}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Name/surname:</b> {advancedUser?.info?.name} {advancedUser?.info?.surname}
            </Text>
            <Text size="xs" color="dimmed">
              <b>CF:</b> {advancedUser?.info?.fiscal_code}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Contract:</b> {advancedUser?.info?.contract}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Role:</b> {advancedUser?.info?.role}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Discipline:</b> {advancedUser?.info?.disciple} Profession: {advancedUser?.info?.profession}
            </Text>
          </Tabs.Panel>

          <Tabs.Panel value="professional" pt="xs">
            <Text size="xs" color="dimmed">
              <b>Title:</b> {advancedUser?.professional?.title}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Name/surname:</b> {advancedUser?.professional?.name} {advancedUser?.professional?.surname}
            </Text>
            <Text size="xs" color="dimmed">
              <b>CF:</b> {advancedUser?.professional?.address}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Region:</b> {advancedUser?.professional?.region}
            </Text>
            <Text size="xs" color="dimmed">
              <b>Profession:</b> {advancedUser?.professional?.profession}
            </Text>
          </Tabs.Panel>
        </Tabs>

      </Card>
      <Card style={{ minWidth: 300, flex: matches ? 1 : 0.5 }} withBorder>
        <Title order={4} mb={16}><FontAwesomeIcon icon={faPeopleGroup}/> Teams
          and roles</Title>
        <Flex justify={'space-between'}>
          <b>Roles:</b>
          <ActionIcon
              onClick={() => setOpenedRole(!openedRole)}><FontAwesomeIcon
              icon={openedRole ? faMinus : faPlus}/></ActionIcon>
        </Flex>
        <Collapse in={openedRole}>
          <Flex mb={12}>
            <Select withinPortal
                    value={selectedRole}
                    onChange={setSelectedRole}
                    data={[
                      { value: '1', label: 'Admin' },
                      { value: '2', label: 'Editor' },
                      { value: '3', label: 'Developer' },
                      { value: '4', label: 'Sales' },
                      { value: '5', label: 'Marketing' },
                      { value: '6', label: 'Supervisor' }
                    ].filter(x => !advancedUser?.roles?.find(y => y.name === x.label))}>
            </Select>
            <Button ml={16} onClick={() => addOrDeleteRole()}
                    disabled={isSaving}
                    loading={isSaving}>Add</Button>
          </Flex>
        </Collapse>
        <List spacing={'md'}>
          {
            advancedUser?.roles?.map((x: IRole) =>
                <List.Item
                    icon={
                      <ActionIcon onClick={() => addOrDeleteRole(x.id)}>
                        <FontAwesomeIcon icon={faTrash}/>
                      </ActionIcon>
                    }
                    key={'r' + x.id}>
                  {x.name}
                </List.Item>)
          }
        </List>

        <Flex justify={'space-between'}>
          <b>Teams:</b>
          <ActionIcon
              onClick={() => setOpenedTeam(!openedTeam)}><FontAwesomeIcon
              icon={opened ? faMinus : faPlus}/></ActionIcon>
        </Flex>
        <Collapse in={openedTeam}>
          <Flex mb={12}>
            <Select disabled withinPortal
                    value={selectedRole}
                    onChange={setSelectedRole}
                    data={[
                      { value: '1', label: 'Developing Team' },
                      { value: '2', label: 'Happy Team' },
                      { value: '3', label: 'Sad Team' },
                      { value: '4', label: 'Really Sad Team' },
                      { value: '5', label: 'Spicy Team' },
                      { value: '6', label: 'Goblin Team' }
                    ].filter(x => !advancedUser?.roles?.find(y => y.name === x.label))}>
            </Select>
            <Button ml={16} onClick={() => addOrDeleteRole()}
                    disabled={isSaving}
                    loading={isSaving}>Add</Button>
          </Flex>
        </Collapse>
      </Card>

    </Flex>

    <Flex direction={matches ? 'column' : 'row'} gap={16} wrap={'wrap'} mt={16}>

      <Card w={'100%'} style={{ flex: 1 }} withBorder>

        <Flex justify={'space-between'}>
          <Title order={4} mb={16}><FontAwesomeIcon
              icon={faGraduationCap}/> User courses</Title>
          <ActionIcon onClick={toggle}><FontAwesomeIcon
              icon={opened ? faMinus : faPlus}/></ActionIcon>
        </Flex>
        <Collapse in={opened}>
          <Flex mb={12}>
            <Select
                withinPortal={withinPortal}
                placeholder={'Search by name'}
                style={{ flex: 1 }}
                data={
                  courses
                      .filter((c: ICourse) => !advancedUser?.courses.some((uc: ICourse) => uc.id === c.id))
                      .map((c: ICourse) => ({
                        value: c.id.toString(),
                        label: c.name,
                        image: c.image
                      }))
                }
                value={selectedCourse}
                onChange={setSelectedCourse}
                itemComponent={SelectItem}
                maxDropdownHeight={400}
                searchable
                disabled={isLoadingCourses}
                icon={isLoadingCourses ?
                    <FontAwesomeIcon icon={faLoader} spin/> : null}>

            </Select>
            <Button ml={16} onClick={addCourseToUser}
                    disabled={isSaving || isSupervisor}
                    loading={isSaving}>Add</Button>
          </Flex>
        </Collapse>
        <Box w={'100%'}>
          <DataTable
              columns={[
                { accessor: 'id', title: 'ID' },
                {
                  accessor: 'icon',
                  title: 'Icon',
                  render: (r) => <Avatar src={r.image} size={24}/>
                },
                { accessor: 'name', title: 'Name' },
                { accessor: 'answered_the_questions', title: 'Questions?' },
                { accessor: 'feedback_sent', title: 'Feedback?' },
                { accessor: 'number_of_attempts', title: 'Attempts' },
                {
                  accessor: 'test_answers',
                  title: 'Test Answers',
                  render: (r: any) => <Button
                      onClick={() => setSelectedUserCourseTests(r)}><FontAwesomeIcon
                      icon={faBookOpenReader}/>
                  </Button>
                },
                {
                  accessor: 'step',
                  title: 'Current step',
                  render: (r: any) => <RingProgress
                      size={50}
                      thickness={2}
                      roundCaps
                      label={
                        <Center>
                          {r.completed_at ?
                              <FontAwesomeIcon icon={faCheckCircle}
                                               color={'teal'} size={'xl'}/>
                              :
                              <Text size="xs" align="center" px="xs"
                                    sx={{ pointerEvents: 'none' }}>
                                {r.step}/{r.sections}
                              </Text>
                          }
                        </Center>
                      }
                      sections={[
                        { value: r.step * 100 / r.sections, color: 'teal' },
                      ]}
                  />
                },
                { accessor: 'current_time', title: 'Current time' },
                {
                  accessor: 'actions',
                  title: 'Actions',
                  render: (r: ICourse) => {
                    return <>
                      <Button onClick={() => setSelectedUserCourse(r)}
                              size={'xs'}><FontAwesomeIcon
                          icon={faWrench}/></Button>
                    </>
                  }
                },
              ]}
              records={advancedUser?.courses}/>

        </Box>

      </Card>
      <UserHasCourseModal
          user_id={user?.id}
          callback={updateUserHasCourseCallback}
          setSelectedUserCourse={setSelectedUserCourse}
          selectedUserCourse={selectedUserCourse}/>
      <UserHasTestQuestionsModal
          user_id={user?.id}
          setSelectedUserCourseTests={setSelectedUserCourseTests}
          selectedUserCourseTests={selectedUserCourseTests}
      />
    </Flex>

    <Flex direction={matches ? 'column' : 'row'} gap={16} wrap={'wrap'} mt={16}>

      <Card w={'100%'} style={{ flex: matches ? 1 : 0.5 }} withBorder>

        <Flex justify={'space-between'}>
          <Title order={4} mb={16}><FontAwesomeIcon icon={faVideo}/> User videos</Title>
          <ActionIcon
              onClick={() => setIsSelectOpen(!isSelectOpen)}><FontAwesomeIcon
              icon={isSelectOpen ? faMinus : faPlus}/></ActionIcon>
        </Flex>

        <Modal opened={isSelectOpen} onClose={() => {
          setSelectedVideo(undefined)
          setIsSelectOpen((prevIsSelectOpen) => !prevIsSelectOpen);
        }}
               title={"Add Video to User"}>
          <Card mih={525}>
            <form onSubmit={form.onSubmit(formOnSubmit)}>
              {advancedUserVideo?.videos ?
                  <>
                    <Select
                        withinPortal={withinPortal}
                        placeholder={'Search by name'}
                        style={{ flex: 1 }}
                        data={
                          videos
                              .filter((c: IVideo) => !advancedUserVideo?.videos.some((userVideo: any) => userVideo.video.id === c.id))
                              .map((c: IVideo) => ({
                                value: c.id.toString(),
                                label: c.name,
                                image: c.image,
                                category: c.category
                              }))
                        }
                        value={selectedVideo}
                        onChange={setSelectedVideo}
                        itemComponent={SelectItem}
                        maxDropdownHeight={400}
                        searchable
                        disabled={isLoadingVideos}
                        {...form.getInputProps('selectedVideo')}
                        mb={20}
                        icon={isLoadingVideos ?
                            <FontAwesomeIcon icon={faLoader} spin/> : null}
                    />
                    <DatePickerInput
                        icon={<FontAwesomeIcon icon={faCalendar}/>}
                        allowDeselect
                        clearable
                        {...form.getInputProps('valid_until')}
                        label="Valid until"
                        withAsterisk
                        mb={20}
                    />
                    <TextInput
                        {...form.getInputProps('subscription')}
                        label="Subscription"
                        withAsterisk
                        mb={20}
                    />

                    <Button mt={250} type={'submit'}
                            disabled={isSaving || isSupervisor}
                            loading={isSaving} fullWidth={true}>
                      Add Video</Button>

                  </>
                  : <></>
              }
            </form>
          </Card>
        </Modal>

        <Box w={'100%'}>
          <DataTable
              minHeight={200}
              columns={[
                {
                  accessor: 'id',
                  title: 'ID',
                  render: (r: any) => <Text>{r.video.id}</Text>
                },
                {
                  accessor: 'icon',
                  title: 'Icon',
                  render: (r: any) => <Avatar src={r.video.image} size={24}/>
                },
                {
                  accessor: 'name',
                  title: 'Name',
                  render: (r: any) => <Text>{r.video.name}</Text>
                },
                {
                  accessor: 'category',
                  title: 'Category',
                  render: (r: any) => <Text>{r.video.category}</Text>
                },
                {
                  accessor: 'valid_until',
                  title: 'Valid Until',
                  render: (r: any) => <Text>{r.valid_until.slice(0, 10)}</Text>
                },
                { accessor: 'subscription', title: 'Subscription' },
                {
                  accessor: 'actions',
                  title: 'Actions',
                  render: (r: IVideo) => {
                    return <>
                      <Button onClick={() => setSelectedUserVideo(r)}
                              size={'xs'}><FontAwesomeIcon
                          icon={faWrench}/></Button>
                    </>
                  }
                },
              ]}
              records={advancedUserVideo?.videos}/>
        </Box>

        <UserHasVideoModal
            user_id={user?.id}
            callback={updateUserHasVideoCallback}
            setSelectedUserVideo={setSelectedUserVideo}
            selectedUserVideo={selectedUserVideo}/>

      </Card>

      <Card style={{ flex: matches ? 1 : 0.5 }} withBorder>
        <Flex justify={'space-between'}>
          <Title order={4} mb={16}><FontAwesomeIcon icon={faCreditCard}/> User
            subscriptions</Title>
          <ActionIcon disabled><FontAwesomeIcon icon={faPlus}/></ActionIcon>
        </Flex>
      </Card>

    </Flex>


    <Flex direction={matches ? 'column' : 'row'} gap={16} wrap={'wrap'} mt={16}>

      <Card style={{ flex: matches ? 1 : 0.5 }} withBorder>
        <Flex justify={'space-between'}>
          <Title order={4} mb={16}><FontAwesomeIcon
              icon={faShield}/> Security</Title>
          <ActionIcon disabled><FontAwesomeIcon icon={faPlus}/></ActionIcon>
        </Flex>
      </Card>

      <Card style={{ flex: matches ? 1 : 0.5 }} withBorder>
        <Flex justify={'space-between'}>
          <Title order={4} mb={16}><FontAwesomeIcon
              icon={faHeadSideGoggles}/> Devices</Title>
          <ActionIcon disabled><FontAwesomeIcon icon={faPlus}/></ActionIcon>
        </Flex>
      </Card>

    </Flex>
  </Modal>
}
export default UserModal;