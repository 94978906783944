import React from 'react';
import {ThemeIcon, UnstyledButton, Group, Text} from '@mantine/core'
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const NavbarLink = ({icon, color, label, onClick, path, disabled = false}: any) => {

    const location = useLocation();
    const {sidebarSmall} = useSelector((state: any) => state.theme)

    return (
        <UnstyledButton
            disabled={disabled}
            onClick={onClick}
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                opacity: disabled ? 0.5 : 1,
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                backgroundColor: location.pathname === path ?
                    ( theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.blue[1] )
                    :  'transparent',

                '&:hover': {
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                },
            })}
        >
            <Group>
                <ThemeIcon color={color} variant="light">
                    {icon}
                </ThemeIcon>

                {!sidebarSmall && <Text size="sm">{label}</Text>}
            </Group>
        </UnstyledButton>
    );
}
export default NavbarLink