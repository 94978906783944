import {createSlice} from '@reduxjs/toolkit'

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        color: 'light',
        fontSize: 14,
        sidebarOpened: true,
        sidebarSmall: false
    },
    reducers: {
        toggleColor: (state, action) => {
            state.color = action.payload || (state.color === 'dark' ? 'light' : 'dark')
            localStorage.setItem('theme', action.payload || (state.color === 'dark' ? 'light' : 'dark'));
        },
        setFontSize: (state, action) => {
            state.fontSize = action.payload
            localStorage.setItem('fontSize', action.payload);
        },
        toggleSidebar: (state) => {
            state.sidebarOpened = !state.sidebarOpened
        },
        setSidebarOpened: (state, action) => {
            state.sidebarOpened = action.payload
        },
        toggleSidebarSize: (state) => {
            state.sidebarSmall = !state.sidebarSmall
        },
        setSidebarSize: (state, action) => {
            state.sidebarSmall = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    toggleColor,
    setFontSize,
    toggleSidebar,
    toggleSidebarSize,
    setSidebarSize,
    setSidebarOpened
} = themeSlice.actions

export default themeSlice.reducer



