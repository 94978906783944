import React, {useEffect, useState} from "react";
import {
    Alert,
    Anchor,
    Breadcrumbs,
    Button,
    Card,
    Divider,
    Flex,
    Group,
    Image,
    Loader,
    LoadingOverlay,
    Modal,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
    Tooltip
} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import UserService from "../../services/UserService";
import {IUserInfo} from "../../interfaces/IUserInfo";
import {genericError} from "../../functions/genericError";
import {ICourse} from "../../interfaces/ICourse";
import CourseService from "../../services/CourseService";
import {useForm} from "@mantine/form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/pro-regular-svg-icons/faInfo";
import {professions} from "../../functions/professions";
import {discipline} from "../../functions/discipline";
import {faGraduationCap} from "@fortawesome/pro-regular-svg-icons/faGraduationCap";
import {useDocumentTitle, useFavicon, useMediaQuery} from "@mantine/hooks";
import {IUser} from "../../interfaces/IUser";

const CoursePage: React.FC = () => {

    useDocumentTitle('BECOME | Tutti i corsi ECM');
    useFavicon('/favicon.ico');

    const [user, setUser] = useState<IUser>();
    const [retrievingUser, setRetrievingUser] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [courses, setCourses] = useState<ICourse[]>([]);
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {contract: 'freelancer'} as IUserInfo,
        validate: {}
    });
    const [showModal, setShowModal] = useState<boolean>(false)
    const matches = useMediaQuery('(max-width: 900px)');
    const circleStyles = {
        width: '40px', // Adjust the size of the circle as needed
        height: '40px', // Make sure width and height are equal for a circle
        borderRadius: '50%', // Creates a circular shape
        borderColor: "black",
        borderWidth: "2px",
        borderStyle: 'solid',
        background: 'white', // Choose the background color you want for the circle
        position: 'absolute' as 'absolute', // Allows positioning of child elements
        left: 17,
        top: 165,
        zIndex: 2
    };
    useEffect(() => {

        setFetching(true)
        setRetrievingUser(true)

        UserService.get()
            .then((r) => {
                setUser(r);
                if (!r.info || !r.info.hasOwnProperty('fiscal_code')) {
                    setShowModal(true)
                }
            })
            .catch(genericError)
            .finally(() => setRetrievingUser(false))

        CourseService.list()
            .then(setCourses)
            .catch(genericError)
            .finally(() => setFetching(false))

    }, [])

    useEffect(() => {
        if (user && user.info) {
            form.setValues(user.info)
        }
    }, [user])

    useEffect(() => {
    }, [courses]);

    const onSubmit = (values: any) => {
        setSaving(true)
        UserService.update(user.id, values)
            .then((r) => {
                setUser(r)
                setShowModal(false)
            })
            .catch(genericError)
            .finally(() => setSaving(false))
    }

    return <>
        <div style={{display: 'relative'}}>

            {!matches &&
                <Breadcrumbs>
                    <Anchor href={'#'} onClick={() => navigate('/courses')}>
                        Tutti i corsi
                    </Anchor>
                    <span>Corsi con riconoscimento Crediti Formativi (ECM)</span>
                </Breadcrumbs>
            }
            <Flex justify={'space-between'} direction={matches ? 'column' : "row"} gap={16}>
                <Title>Corsi con riconoscimento Crediti Formativi (ECM)</Title>
                <Button variant={'outline'} onClick={() => setShowModal(true)}>Modifica dati personali</Button>
            </Flex>

            <Flex mt={50} gap={32} wrap={'wrap'} justify={matches ? 'center' : 'space-around'}>

                {fetching ?
                    <div style={{textAlign: 'center', flex: 1}}>
                        <Title order={2}>Recupero corsi...</Title>
                        <Loader variant={'bars'} size="xl" style={{margin: '36px auto'}}/>
                    </div>
                    :
                    courses.map((c: ICourse) => {
                            const disabled = user === undefined || !user.info || !user.info.hasOwnProperty('fiscal_code');
                            const unlocked = user?.courses?.some(uhc => uhc.id === c.id);
                            return <Card withBorder className={'flex-card'} key={c.id} style={{width: 220, minHeight: 365}}>
                                <Card.Section style={{flex: 1}}>
                                    <Flex justify={'space-between'} direction={'column'} style={{flex: 1}}>
                                        <div style={{flex: 1}}>
                                            {c.user_has_course?.[0]?.completed_at &&
                                                <Tooltip
                                                    withinPortal={true}
                                                    label={`Corso completato il ${c.user_has_course?.[0]?.completed_at}`}>
                                                    <FontAwesomeIcon
                                                        style={{
                                                            position: 'absolute',
                                                            color: 'black',
                                                            right: 20,
                                                            top: 20,
                                                            zIndex: 4
                                                        }}
                                                        size={'xl'}
                                                        icon={faGraduationCap}/>
                                                </Tooltip>
                                            }
                                            <Image src={c.image} alt={c.name} height={200} radius={12} width={200}/>
                                            <Text size="sm" weight={700} mt={12}>
                                                {c.name}
                                            </Text>
                                        </div>

                                        {unlocked ?
                                            (c.user_has_course?.[0]?.completed_at ?
                                                    <Button onClick={() => navigate(`./${c.id}`)}
                                                            variant="light"
                                                            color="teal"
                                                            fullWidth
                                                            disabled={disabled}
                                                            mt="md" radius="md">
                                                        Rivedi
                                                    </Button> :
                                                    <Button onClick={() => navigate(`./${c.id}`)}
                                                            variant="light"
                                                            color="blue"
                                                            fullWidth
                                                            disabled={disabled}
                                                            mt="md" radius="md">
                                                        Vai al corso
                                                    </Button>
                                            )
                                            :
                                            <Anchor href={c.product_url} title={c.product_url} target={'_blank'}
                                                    rel={'noreferrer'}>
                                                <Button variant="light" color="orange"
                                                        fullWidth
                                                        disabled={disabled}
                                                        mt="md" radius="md">
                                                    Sblocca
                                                </Button>
                                            </Anchor>
                                        }
                                        {disabled && <Text size={'xs'} color={'red'} mt={12}>
                                            Inserisci i tuoi dati nel form per proseguire
                                        </Text>
                                        }
                                    </Flex>
                                </Card.Section>
                            </Card>
                        }
                    )}

            </Flex>


            <Modal opened={showModal} onClose={() => setShowModal(false)} title={'Corsi ECM'}>
                <LoadingOverlay visible={retrievingUser || saving}/>
                <Alert icon={<FontAwesomeIcon icon={faInfo}/>} mb={12}>
                    Queste informazioni sono raccolte per essere inviate ad AGENAS per poter ricevere i crediti
                    formativi
                </Alert>
                <Divider/>
                <form style={{marginTop: 12}} onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <TextInput withAsterisk label={'Codice fiscale'}  {...form.getInputProps('fiscal_code')}/>
                        <TextInput withAsterisk label={'Nome'}    {...form.getInputProps('name')}/>
                        <TextInput withAsterisk label={'Cognome'}    {...form.getInputProps('surname')}/>

                        <Select searchable data={[
                            {label: 'Partecipante', value: 'participant'},
                            {label: 'Docente', value: 'teacher'},
                            {label: 'Tutor', value: 'tutor'},
                            {label: 'Relatore', value: 'speaker'},
                        ]}
                                withAsterisk label={'Ruolo'}    {...form.getInputProps('role')}/>

                        <Select searchable data={[
                            {label: 'Libero professionista', value: 'freelancer'},
                            {label: 'Dipendente convenzionato', value: 'contracted_employee'},
                            {label: 'Nessuna occupazione', value: 'no_occupation'},
                        ]}
                                withAsterisk label={'Tipo contratto'}    {...form.getInputProps('contract')}/>

                        <TextInput withAsterisk label={'Titolo'}
                                   placeholder={'Dott*'}  {...form.getInputProps('title')}/>

                        <Select searchable data={professions
                            .sort((s1, s2) => s1.label.toLowerCase().localeCompare(s2.label.toLowerCase()))
                            .map(s => {
                                return {label: s.label, value: s.value.toString()}
                            })}
                                withAsterisk label={'Professione'}    {...form.getInputProps('profession')}/>

                        <Select searchable data={
                            form.values.profession && discipline[form.values.profession] ?
                                discipline[form.values.profession]
                                    .sort((s1, s2) => s1.label.toLowerCase().localeCompare(s2.label.toLowerCase()))
                                    .map(s => {
                                        return {label: s.label, value: s.value.toString()}
                                    })
                                : []
                        }
                                withAsterisk label={'Disciplina'}    {...form.getInputProps('disciple')}/>
                    </Stack>

                    <Group position="right" mt="md">
                        <Button type="submit" disabled={saving} loading={saving}>Salva</Button>
                    </Group>

                </form>
            </Modal>
        </div>
    </>
}

export default CoursePage;