import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ColorSchemeProvider, MantineProvider} from '@mantine/core';
import {ModalsProvider} from "@mantine/modals";
import {setFontSize, toggleColor} from "./store/theme/themeSlice";
import AuthRouterWrapper from "./shared/AuthRouterWrapper";
import {BrowserRouter} from "react-router-dom";
import Pusher from 'pusher-js';
import {Notifications} from "@mantine/notifications";
import "./App.css";
import {setUser} from "./store/auth/authSlice";
import secureLocalStorage from "react-secure-storage";
import {setLanguage} from "./store/lang/langSlice";


const App: React.FC = () => {

    const dispatch = useDispatch();
    const {user} = useSelector((store: any) => store.auth);
    const {color, fontSize} = useSelector((state: any) => state.theme);

    useEffect(() => {
        const localUser = secureLocalStorage.getItem('user');
        const localToken = secureLocalStorage.getItem('token');
        const lang = localStorage.getItem('lang');
        if (lang) {
            dispatch(setLanguage(lang))
        }
        if (localUser) {
            try {
                dispatch(setUser({
                    authState: 'SIGNED_IN',
                    token: localToken,
                    user: typeof localUser === 'string' ? JSON.parse(localUser) : localUser,
                }))
            } catch (e) {
                localStorage.clear();
                window.location.reload();
            }

            const localTheme = localStorage.getItem('theme');
            const localFontSize = localStorage.getItem('fontSize');
            if (localTheme) {
                dispatch(toggleColor(localTheme))
            }
            if (localFontSize) {
                dispatch(setFontSize(parseInt(localFontSize)))
            }
        }

    }, [])


    useEffect(() => {
        let pusher: any;
        if (user) {
            pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
                cluster: 'eu',
                authEndpoint: `${process.env.REACT_APP_URL}/api/broadcasting/auth`,
                auth: {
                    headers: {
                        'Client-Id': process.env.REACT_APP_PUSHER_APP_ID,
                        'Authorization': "Bearer " + secureLocalStorage.getItem('token') ?? ''
                    }
                },
            });


            const channel = pusher.subscribe('private-channel.' + user.id);
            channel.bind('permissions', function (data: any) {
                setTimeout(() => {
                    window.location.reload();
                }, 300)
            });

            channel.bind('course', function (data: any) {
                setTimeout(() => {
                    window.location.reload();
                }, 300)
            });
        }
        return () => {
            if (pusher) {
                pusher.unsubscribe('channel-' + user.sub);
            }
        }
    }, [user])


    return (
        <ColorSchemeProvider colorScheme={color} toggleColorScheme={toggleColor}>
            <MantineProvider theme={{
                colorScheme: color,
                fontFamily: '"proxima-nova", sans-serif',
                headings: {fontFamily: '"proxima-nova", sans-serif'},
                fontSizes: {
                    xs: fontSize,
                    sm: fontSize + 2,
                    md: fontSize + 4,
                    lg: fontSize + 6,
                    xl: fontSize + 10,
                },
            }} withGlobalStyles withNormalizeCSS>
                <Notifications/>
                <ModalsProvider>
                    <BrowserRouter>
                        <AuthRouterWrapper/>
                    </BrowserRouter>
                </ModalsProvider>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;
