export function enTranslations() {
  return {
    "and": " and ",
    "IAccept": "I have read and accept the",
    "termsAndConditions": "terms and conditions",
    "privacyPolicy": "privacy policy",
    "resetPassword": "Check into your email inbox",
    "success": "Success",
    "noPower": "You have no power here.",
    "notFound": "Page not found",
    "dining_room": "Dining room",
    "restaurant": "Restaurant",
    "stadium": "Stadium",
    "notFoundtext": "The address you selected does not exist or has been removed.",
    "backHome": "Back to the homepage",
    "startFromLevel": "Start from Level:",
    "articoli": "Latest articles",
    "benvenuto": "Welcome ### to BECOME.",
    "sezione": "Certificates and materials section",
    "certificati": "Certificates:",
    "notification": "You will receive a notification as soon as the certificate is ready",
    "notificationText": "We have taken care of your request. You will get a notification in a few minutes.",
    "certificato": "Certificate:",
    "scarica": "Download",
    "genera": "Generate",
    "notificationTextTwo": "If you don't get it, try reloading this page.",
    "notAuth": "Not allowed",
    "notAuthText": "You do not have permission to access this content",
    "contattaci": "Contact us",
    "sendEmail": "Write us an email, you will be contacted within 24 hours.",
    "bug": "Report a bug",
    "titleBug": "Title",
    "descriptionBug": "Description",
    "sendReport": "Send report",
    "createAccount": "Create a free account",
    "invalidName": "Invalid Name",
    "invalidPw": "Invalid Password",
    "pwNotMatch": "Passwords do not match",
    "createAcc": "Create account",
    "fullName": "Full name",
    "emailR": "Email",
    "passwordR": "Password",
    "passwordLabel": "Enter at least 6 characters",
    "confirmPw": "Confirm Password",
    "registerR": "Sign in",
    "backLogin": "Back to the login page",
    "mfaValidate": "MFA code must be of 6 numbers",
    "mfaTitle": "Insert your MFA code.",
    "mfaText": "Enter your MFA code to complete the login",
    "mfaBackLogin": "Back to the login page",
    "mfaConfirm": "Confirm code",
    "loginValidateEmail": "Invalid Email address",
    "welcome": "Welcome!",
    "noAccount": "Don't have an account?",
    "signUp": "Sign up",
    "emailL": "Email",
    "passwordL": "Password",
    "passwordLPH": "Your Password",
    "rememberMeL": "Remember me",
    "entra": "Login",
    "expired": "Password expired",
    "forgotPw": "Forgot your password?",
    "validate": "Invalid email",
    "insertEmail": "Enter your email, you will receive a code to proceed with the reset.",
    "resetPw": "Reset password",
    "emailLabel": "Email",
    "reqPwLabelOne": "Includes number",
    "reqPwLabelTwo": "Includes lowercase letter",
    "reqPwLabelThree": "Includes uppercase letter",
    "reqPwLabelFour": "Includes special symbol",
    "insertPw": "Insert a new Password.",
    "insertPwText": "Choose a strong, unique password for security.",
    "pwLabel": "Your password",
    "confirmPwLabel": "Your password",
    "loginFCPw": "Login",
    "validateCode": "Verification code must be of 6 letters",
    "validateEmail": "Invalid email",
    "insertCodeTitle": "Insert your Verification code",
    "insertCodeText": "To complete the registration, enter the code we sent to your email address.",
    "codeField": "Verification code",
    "confirmReg": "Confirm",
    "firstStep": "First step",
    "firstStepDesc": "Setup MFA",
    "setupMfaTitle": "Setup your MFA code.",
    "firstStepText": "Increase your account security with an MFA code",
    "firstStepButton": "Setup MFA",
    "secondStep": "Second step",
    "connectAuth": "Connect an Authenticator",
    "connectAuthApp": "Connect an Authenticator App",
    "clickLink": "Click on the link for complete the authentication",
    "mobileLink": "Mobile Link for Authentication",
    "manualConnection": "Or add manually the following code inside your authentication app",
    "scanQR": "Or scan the QR Code",
    "authRequired": "Only after connecting an Authenticator you can proceed with the Verification",
    "backMfa": "Back",
    "goVerification": "Go to Verification",
    "lastStep": "Final step",
    "lastStepDesc": "Verify Code",
    "verifyMfa": "Verify your MFA.",
    "insertMfa": "Insert the confirmation code for conclude the MFA setup.",
    "alertMfa": "You can only Verify your code after successfully connecting an Authenticator",
    "validatePw": "Invalid Password",
    "modalRemoveMfa": "You are about to remove MFA from your account",
    "modalRemoveConfirm": "Please click to confirm your action",
    "modalLabelConfirm": "Disable",
    "modalLabelCancel": "Cancel",
    "mfaSetup": "You have successfully set up MFA for your account.",
    "mfaSetupText": "You have strengthened the security for your account.",
    "removeMfa": "Remove MFA",
    "removeMfaInstruction": "If you need to remove the MFA, insert your password and then press the button below\n",
    "emailS": "Email",
    "phoneS": "Phone",
    "addressS": "Address",
    "workS": "Working hours",
    "fontDecrease": "Decrease font size",
    "fontIncrease": "Increase font size",
    "selectLanguage": "Select language",
    "selectTheme": "Activate",
    "themeLight": "day mode",
    "themeDark": "night mode",
    "docTitle": "BECOME | All courses",
    "eLearning": "E-Learning Platform",
    "inThisPage": "On this page you can view the courses offered by BECOME.",
    "currentDisabled": "Section temporarily disabled",
    "corsiNoECM": "Asynchronous training courses",
    "whatYouFind": "Here you can find open access courses",
    "goCourses": "Go to free courses",
    "modalTitleP": "New patient",
    "patientAlert": "We prefer not to store your patient data on our servers for security and privacy. Remember to write the patient code in a physical book/agenda.",
    "labelCode": "Code",
    "myDevices": "My Devices",
    "setUpDevice": "Set up a new device",
    "serverConnect": "Connection to server:",
    "sConnecting": "Attempting to connect",
    "sOpen": "Open",
    "sClosing": "Closing",
    "sClose": "Closed",
    "sUninstantiated": "Uninstantiated",
    "deviceName": "Name:",
    "recordingStart": "Start recording",
    "recordingStop": "Stop recording",
    "heartRate": "Heart Rate",
    "foodListID": "Food list must exists",
    "patientID": "Patient must exists",
    "deviceID": "Device must exists",
    "ristorante": "Restaurant",
    "cucina": "Kitchen",
    "bedroom": "Bedroom",
    "stanza": "Select valid room",
    "bozza": "Save as draft",
    "coda": "Save and queue",
    "statusStatus": "Select valid status",
    "newConfig": "New configuration",
    "foodList": "Select the food list",
    "searchByName": "Search by name",
    "selectPatient": "Select patient",
    "searchPatient": "Search by code",
    "selectDevice": "Select the device",
    "pickOne": "Pick one",
    "labelRecord": "Record Fitbit results?",
    "labelStatus": "Status",
    "buttonCreate": "Create",
    "validateName": "Name must have at least 3 letters",
    "actionId": "Id: ",
    "actionName": "Name: ",
    "modalTitle": "New food list",
    "alertWarning": "Pay attention!",
    "warningText": "Once a list has been created, it can no longer be modified, otherwise it would invalidate the results already stored.",
    "food": "Food",
    "foodListName": "List name",
    "foodListCreate": "Create list",
    "deleteConfirm": "Confirm delete? ###",
    "deleteWarning": "Deleting this food list will also delete all configurations and related results.",
    "deleteBCancel": "Cancel",
    "deleteBDelete": "Delete",
    "breadcrumbFoodList": "Food Lists",
    "nameTable": "Name",
    "totalFoodTable": "Total food",
    "totalConfigurationsTable": "Total Configurations",
    "tableActions": "Actions",
    "fetchingLoading": "Loading...",
    "fetchingNoResults": "No results",
    "breadcrumbsAllApps": "All apps",
    "breadcrumbPatients": "Patients",
    "tableCode": "Code",
    "tableCreateAt": "Created at",
    "buttonEsplora": "Explore ",
    "allApps": "All apps",
    "alertUseApp": "To start using this application please contact our support at ",
    "baseline": "Baseline",
    "variation": "Variation",
    "labelRoom": "Select the room",
    "totalPatients": "Patients",
    "totalPatientsLabel": "Need more patients? Upgrade your current plan",
    "upgradePlan": "Upgrade plan",
    "allPatients": "All patients",
    "addPatient": "Add patient",
    "totatListsFood": "Food lists",
    "totatListsFoodLabel": "Need more food lists? Upgrade your current plan",
    "goToLists": "Go to lists",
    "createList": "Create new list",
    "deviceTotal": "Devices",
    "upgradeLabelDevice": "Need more devices? Upgrade your current plan",
    "goToDevice": "Go to Devices",
    "liveRegister": "Live recording",
    "sessioni": "Sessions",
    "upgradeLabelConfig": "Need more configurations? Upgrade your current plan",
    "oneList": "Do you have at least one food list?",
    "answerYes": "Yes",
    "answerNo": "No",
    "onePatient": "Do you have at least one patient?",
    "oneDevice": "Do you have at least one device?",
    "allSessionsB": "All sessions",
    "allSessionsLabel": "Add at least one patient and create a food list before creating a session",
    "newSession": "New session",
    "queuedConfigurations": "Sessions in queue",
    "titleRoom": "Room",
    "titlePatient": "Patient",
    "titleDevice": "Device",
    "titleFoodList": "Food list",
    "titleActions": "Actions",
    "fetchLoading": "Loading...",
    "fetchNoResults": "No results",
    "sidebarHome": "Home",
    "sidebarCourses": "Courses",
    "sidebarApp": "App",
    "sidebarMaterials": "Materials",
    "sidebarProfessionals": "Professionals page",
    "sidebarDevices": "Devices",
    "sidebarSecurity": "Security",
    "sidebarSupport": "Support",
    "aCDeleteConf": "Confirm delete configuration by id: ",
    "aCAlert": "Deleting this configuration will also delete all related results.",
    "aCCancel": "Cancel",
    "aCDelete": "Delete",
    "aCSessionId": "Configuration id:",
    "aCRoom": "Room",
    "aCPatient": "Patient code",
    "aCFoodList": "Food list name",
    "aCCompleted": "Completed at",
    "aCFood": "Food",
    "aCLevel": "Level",
    "aCDownload": "Download CSV",
    "aCBreadcrumbsApps": "All apps",
    "aCBreadcrumbsConfig": "Configurations",
    "aCPaziente": "Patient",
    "aCPatients": "Patients",
    "aCPatientsSearch": "Search patient...",
    "aCDevice": "Device",
    "aCfoodListT": "Food list",
    "aCStatus": "Status",
    "aCREC": "REC",
    "aCCreatedAt": "Created at:",
    "aCActions": "Actions",
    "aCLoading": "Loading...",
    "aCNoResults": "No results",
    "loginWithQR": "Login with QRCODE.",
    "devicesMain": "Devices",
    "devicesMainText": "On this page you will be able to find the access tokens for the Become applications.",
    "infoD": "Information",
    "warningAlert": "To authenticate on a device, enter a token's ### One Time Password ### (if this is \"available\"). ### If you have run out of available tokens and need to authenticate on a device, please contact our ",
    "warningAlertSupport": "support",
    "modalConnect": "Connect another device",
    "modalConnectButton": "Connect",
    "tableDeviceName": "Device name",
    "tableDeviceType": "Device type",
    "tableOneTime": "One Time Password",
    "tableConnections": "Connected devices",
    "tableConnect": "Connect",
    "tableQR": "QR Code",
    "corsiTitleTwo": "Subscribe to our newsletter to not miss out on upcoming courses for psychologists, doctors, psychotherapists, neuroscientists, researchers and university students.",
    "videoPlayer": "Course preview",
    "courseDetailsAuthor": "Authors:",
    "courseDetailsLastUpdate": "Last update:",
    "includeApp": "App included",
    "tax": "+ VAT",
    "appTitle": "OUR VIRTUAL REALITY APPS",
    "appSubTitle": "Our innovative Virtual Reality and Biosensory tools for mental health professionals - psychologists, doctors, psychotherapists, neuroscientists, researchers - are continuously updated.",
    "appSubTwoTitle": "Discover our apps, our immersive experiences and our psychotechnologies for clinical, rehabilitation, organizational, sports, school and wellness practice.",
    "categoryS": "Selected category:",
    "resetFilter": "Reset filters",
    "youCart": "Your cart",
    "labelRemoveCart": "Remove from cart",
    "removeCart": "Remove",
    "orderSummary": "Order Summary:",
    "subtotale": "Subtotal",
    "taxText": "Taxes",
    "taxToPay": "Calculate at checkout",
    "toOrder": "Proceed to Order",
    "productPrice": ".00",
    "inCart": "Already in the cart",
    "addCart": "Add to cart",
    "buyNow": "Buy now",
    "whatYouWLearn": "What you'll learn",
    "courseContent": "Course contents",
    "courseDescr": "Course description",
    "ambitoDelCorso": "Areas of the course",
    "becomeText": "BECOME creates innovative technological and educational solutions for mental health",
    "getStarted": "Get Started",
    "liveDemo": "Live demo",
    "myOrder": "My order:",
    "paginaprincipale": "Home",
    "corsi": "Courses",
    "applicazioni": "App",
    "profes": "Are you a professional?",
    "azienda": "Are you an enterprise?",
    "ricerca": "Research",
    "goToCart": "Go to Cart",
    "corsiTitle": "OUR COURSES",
    "filteR": "Filter",
    "topic": "Topic",
    "durata": "Duration",
    "dettagli": "Details",
    "soluzioni": "Solutions",
    "piattaforma": "Go to the platform",
    "accedi": "Login",
    "registrati": "Sign in",
    "notturna": "Dark theme",
    "diurna": "Light theme",
    "viewCart": "View cart",
    "professionistiTitle": "Certified Professionals",
    "professionistiPlaceholder": "Search by name, address or region...",
    "professionistiLabel": "Search",
    "profAddress": "No address entered",
    "professione": "Profession not indicated",
    "noCertificati": "No certificate",
    "profWebsite": "Personal Website",
    "aCSelectPH": "Filter by status…",
    "pACRooms": "Rooms",
    "selectRoom": "Select a room",
    "volcano": "Volcano",
    "ocean": "Ocean",
    "connectTitle": "Connect a new device",
    "loginFromMobile": "Login from mobile",
    "professionalTitle": "From this section you can enable your public profile on the professionals page",
    "labelProfile": "Public profile",
    "labelNAME": "Name",
    "labelSurname": "Surname",
    "labelAddress": "Address",
    "labelState": "State",
    "labelProfession": "Profession",
    "labelBachelor": "Qualification",
    "placeholderBachelor": "Doctor",
    "labelWebsite": "Website",
    "SAVE": "Save",
    "titleProfessional": "Professionals page",
    "bombolone": "Donut",
    "cannolo": "Cannoli",
    "cannoncino": "Cannoli",
    "caramelle": "Candies",
    "cioccolato": "Chocolate",
    "cono": "Cone",
    "cookies": "Cookies",
    "croissant": "Croissant",
    "crostata": "Tart",
    "cupcake": "Cupcakes",
    "donut": "Donuts",
    "macarons": "Macarons",
    "meringa": "Meringue",
    "muffin": "Muffin",
    "panettone": "Panettone",
    "pasticcino_frutta": "Fruit Pastry",
    "praline": "Pralines",
    "sacher": "Sacher cake",
    "saint_honoré": "Saint Honoré",
    "snack": "Snacks",
    "snack_cioccolato": "Chocolate Snack",
    "torta_margherita": "Margherita cake",
    "torta_paradiso": "Paradise Cake",
    "affogato": "Affogato",
    "baba": "Baba",
    "biscotti": "Cookies",
    "barrette": "Cereal Bars",
    "corn_flakes": "Corn flakes",
    "ciliege": "Cherries",
    "frutti_di_bosco": "Berries",
    "insalata": "Salad",
    "macedonia": "Fruit salad",
    "more": "Blackberries",
    "pere": "Pears",
    "pesche": "Peaches",
    "uva": "Grape",
    "yogurt": "Yogurt",
    "anguria": "Watermelon",
    "arancia": "Orange",
    "banana": "Banana",
    "carote": "Carrots",
    "verdure_grigliate": "Grilled vegetables",
    "pomodori": "Tomatoes",
    "minestrone": "Minestrone",
    "insalata_orzo": "Barley Salad",
    "tartare_tonno": "Tuna tartare",
    "pollo_ai_ferri": "Grilled chicken",
    "ricotta": "Ricotta",
    "riso": "Rice",
    "simmenthal": "Simmenthal",
    "sushi": "sushi",
    "pesce": "Fish",
    "pesce_alla_griglia": "Grilled fish",
    "pesce_spada": "Swordfish",
    "nighiri": "Nighiri",
    "fette_biscottate": "Rusks",
    "gamberi": "Shrimp",
    "pasta": "Pasta",
    "patatine_fritte": "French fries",
    "pizza": "Pizza",
    "salame": "Salami",
    "salatini": "Pretzels",
    "bresaola": "Bresaola",
    "salsiccia": "Sausage",
    "spiedini": "Skewers",
    "stinco": "Shin",
    "taco": "Tacos",
    "tortilla": "Tortilla",
    "uovo_e_bacon": "Egg and Bacon",
    "arachidi": "Peanuts",
    "arrosto": "Roast",
    "bistecca": "Steak",
    "bruschetta": "Bruschetta",
    "burger": "Burgers",
    "calzone": "Calzone",
    "chips": "Chips",
    "coscia_di_pollo": "Chicken thigh",
    "cotoletta": "Cutlet",
    "crackers": "Crackers",
    "crocchette": "Croquettes",
    "focaccia": "Focaccia",
    "formaggio": "Cheese",
    "fritto_misto": "Mixed Fried",
    "gorgonzola": "Gorgonzola",
    "hamburger": "Hamburger",
    "hot_dog": "Hot dog",
    "kebab": "Kebab",
    "lasagne": "lasagna",
    "parmigiana": "Eggplant parmesan",
    "a_cake": "A. Cake",
    "a_pizza": "A. Pizza",
    "a_chips": "A. Chips",
    "a_nuts": "A. Nuts",
    "a_cookies": "A. Cookies",
    "kitchen": "Kitchen",
    "performance_location": "Scene",
    "food_location": "Location",
    "a_ice_cream": "A. Ice Cream",
    "a_chocolate": "A. Chocolate",
    "a_bread": "A. Bread",
    "a_donuts": "A. Donuts",
    "a_brownies": "A. Brownies",
    "buffet": "Enable buffet",
    "a_spaghetti": "Ravioli",
    "b_pastel": "Pastel",
    "a_rice": "A. Rice",
    "a_fruit": "A. Fruits",
    "a_salad": "A. Salad",
    "a_yogurt": "A. Yogurt",
    "a_pancakes": "A. Pancakes",
    "a_lean_meat": "A. Lean Meat",
    "a_fried_chicken": "A. Fried Chicken",
    "a_peanut_butter": "A. Peanut Butter",
    "a_snacks": "A. Snacks",
    "a_popcorn": "A. Popcorn",
    "a_pastries": "A. Pastries",
    "a_cereal": "A. Cereals",
    "a_bacon": "A. Bacon",
    "a_steak": "A. Steak",
    "a_baked_potato": "A. Baked Potato",
    "a_cupcakes": "A. Cupcakes",
    "b_brigadeiro": "B. Brigadeiro",
    "b_joelho": "B. Joelho",
    "b_orange_juice": "B. Orange Juice",
    "b_soft_drink": "B. Soft Drink",
    "b_spaghetti_bolognese": "B. Rigattoni",
    "b_açai_bowl": "B. Açai Bowl",
    "b_paçoca": "B. Paçoca",
    "b_pão_de_queijo": "B. Pão de Queijo",
    "b_oreo": "B. Oreo",
    "b_beef_stroganoff": "B. Beef Stroganoff",
    "b_empada": "B. Empada",
    "b_coxinha_de_galinha": "B. Coxinha de Galinha",
    "difficulty": "Baseline recording time (in seconds)",
    "hrMin": "Percentage change",
    "hrMax": "Session duration (in seconds)"
  }
}