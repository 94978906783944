import React, {useEffect, useState} from "react";
import {
    ActionIcon,
    Alert,
    Avatar,
    Button,
    Flex,
    LoadingOverlay,
    Modal,
    Select,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {genericError} from "../../functions/genericError";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {faInfo} from "@fortawesome/pro-regular-svg-icons/faInfo";
import {sortBy} from "lodash";
import {useDisclosure, useDocumentTitle, useFavicon} from "@mantine/hooks";
import TokenLogin from "./TokenLogin";
import {DataTable, DataTableSortStatus} from "mantine-datatable";
import {IUserDevice} from "../../interfaces/IUserDevice";
import UserDevicesService from "../../services/UserDevicesService";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";
import {faMinus} from "@fortawesome/pro-regular-svg-icons/faMinus";
import {faQrcode} from "@fortawesome/pro-regular-svg-icons/faQrcode";
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync";
import {useSelector} from "react-redux";
import advancedLang from "../../functions/advancedLang";

const DevicesPage: React.FC = () => {
    useDocumentTitle('BECOME | Tokens');
    useFavicon('/favicon.ico');
    const navigate = useNavigate()
    const [fetching, setFetching] = useState<boolean>(false);
    const [tokens, setTokens] = useState<IUserDevice[]>([])
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({columnAccessor: 'name', direction: 'asc'});
    const [selectedToken, setSelectedToken] = useState<IUserDevice | undefined>()
    const [opened, {open, close}] = useDisclosure();
    const [opened2, setOpened2] = useState<boolean>(false);
    const [value, setValue] = useState<string | null>(null);
    const {translations} = useSelector((state: any) => state.lang);
    const [isConnecting, setIsConnecting] = useState<boolean>(false)
    const [disconnectingId, setDisconnectingId] = useState<number | undefined>(undefined)

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        setFetching(true);
        UserDevicesService.listUserDevices()
            .then(setTokens)
            .catch(genericError)
            .finally(() => setFetching(false))
    }


    useEffect(() => {
        const data = sortBy(tokens, sortStatus.columnAccessor);
        setTokens(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    return <>
        <div style={{display: 'relative'}}>
            <LoadingOverlay visible={fetching}/>
            <Title>{translations.devicesMain ?? "translations.devicesMain"}</Title>
            <Text>{translations.devicesMainText ?? "translations.devicesMainText"}</Text>

            <Alert icon={<FontAwesomeIcon icon={faInfo}/>} title={translations.infoD ?? "translations.infoD"}
                   color="cyan" mt={12} mb={12}>
                <div
                    dangerouslySetInnerHTML={translations.warningAlert ? {
                        __html: advancedLang(translations.warningAlert, ["<b>", "</b>", "<br/>"])
                    } : {__html: "translations.warningAlert"}}/>
                <span style={{marginLeft: 7, textDecoration: 'underline'}}
                      onClick={() => navigate('/support')}>{translations.warningAlertSupport ?? "translations.warningAlertSupport"}</span>.
            </Alert>

            <Modal opened={opened} onClose={close} title={translations.modalConnect ?? "translations.modalConnect"}>
                <Stack>
                    <Select
                        withinPortal={true}
                        value={value}
                        onChange={setValue}
                        data={
                            tokens
                                .filter(t => (t.id !== selectedToken?.id && t.device_type === 'fitbit'))
                                .filter(t => (!selectedToken?.connections.some(tc => tc.id === t.id)))
                                .map(t => ({
                                    label: t.device_name,
                                    value: t.id.toString()
                                }))}/>
                    <Button
                        fullWidth
                        loading={isConnecting}
                        disabled={isConnecting}
                        onClick={() => {
                            if (!selectedToken || !value) return;
                            setIsConnecting(true)
                            UserDevicesService.connect(
                                parseInt(value),
                                selectedToken.id
                            ).then(() => {
                                init()
                                setSelectedToken(undefined)
                                close()
                            })
                                .catch(genericError)
                                .finally(() => setIsConnecting(false))
                        }}>{translations.modalConnectButton ?? "translations.modalConnectButton"}</Button>
                </Stack>
            </Modal>


            <DataTable
                mt={24}
                minHeight={400}
                withBorder
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                records={tokens}
                columns={[
                    {
                        accessor: 'device_type', title: translations.tableDeviceType ?? "translations.tableDeviceType",
                        render: (e) => <Avatar src={e.device_type === 'oculus' ? '/oculus.png' : '/fitbit.png'}/>
                    },
                    {
                        accessor: 'device_name', title: translations.tableDeviceName ?? "translations.tableDeviceName",
                    },
                    {
                        accessor: 'one_time_password', title: translations.tableOneTime ?? "translations.tableOneTime",
                        render: (e) =>
                            e.last_login_at ?
                                <FontAwesomeIcon color={'red'}
                                                 icon={faCross}/> :
                                (e.device_type === 'oculus' ? '' : e.one_time_password)
                    },
                    {
                        accessor: 'connections',
                        title: translations.tableConnections ?? "translations.tableConnections",
                        render: (e: IUserDevice) => <Flex direction={'column'}>
                            {e.connections.map(c => (
                                <Flex key={'udv-' + c.id}>
                                    <ActionIcon style={{marginRight: 24}}
                                                disabled={disconnectingId === c.id}
                                                onClick={() => {
                                                    setDisconnectingId(c.id)
                                                    UserDevicesService.disconnect(e.id, c.id)
                                                        .then(() => init())
                                                        .catch(genericError)
                                                        .finally(() => setDisconnectingId(undefined))
                                                }}
                                                color={'red'} title={'Disconnect'}>
                                        <FontAwesomeIcon icon={disconnectingId === c.id ? faSync : faMinus}
                                                         spin={disconnectingId === c.id}/>
                                    </ActionIcon>
                                    <span>{c.device_name} | {c.device_type}</span>
                                </Flex>
                            ))}
                        </Flex>
                    },
                    {
                        accessor: 'actions', title: translations.tableConnect ?? "translations.tableConnect",
                        textAlignment: 'center',
                        width: 100,
                        render: (e) =>
                            e.device_type === 'oculus' ?
                                <ActionIcon style={{margin: 'auto'}}
                                            title={translations.connectTitle ?? "translations.connectTitle"}
                                            onClick={() => {
                                                setSelectedToken(e)
                                                open();
                                            }}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </ActionIcon> : 'N/A'
                    },
                    {
                        accessor: 'actions2', title: translations.tableQR ?? "translations.tableQR",
                        textAlignment: 'center',
                        width: 90,
                        render: (e) =>
                            <ActionIcon style={{margin: 'auto'}}
                                        title={translations.loginFromMobile ?? "translations.loginFromMobile"}
                                        onClick={() => {
                                            setSelectedToken(e)
                                            setOpened2(true);
                                        }}>
                                <FontAwesomeIcon icon={faQrcode}/>
                            </ActionIcon>
                    },
                ]}
                striped
                highlightOnHover
            />
            {selectedToken ?
                <TokenLogin setSelectedToken={setSelectedToken} token={selectedToken} opened={opened2}
                            setOpened={setOpened2}/>
                : null
            }
        </div>
    </>
}
export default DevicesPage;