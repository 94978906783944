import SetupMFA from "./components/setup_mfa";
import {useSelector} from "react-redux";
import DisableMFA from "./components/disable_mfa";

export default function Security(){
    const {user} = useSelector((store: any) => store.auth);

return( <>
    {user && user.two_factor_confirmed_at ? <DisableMFA/> : <SetupMFA/>}

    </>
)}