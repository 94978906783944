import React, {useEffect, useRef, useState} from "react";
import {
    Affix,
    Alert,
    Anchor,
    Box,
    Breadcrumbs,
    Button,
    createStyles,
    Flex,
    Image,
    Loader,
    LoadingOverlay,
    Modal,
    ScrollArea,
    Stepper,
    Tabs,
    Text,
    Title,
    Transition,
    useMantineTheme
} from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";
import {ICourse} from "../../interfaces/ICourse";
import CourseService from "../../services/CourseService";
import {genericError} from "../../functions/genericError";
import {useDispatch, useSelector} from "react-redux";
import {setSidebarSize, toggleSidebarSize} from "../../store/theme/themeSlice";
import {ICourseSection} from "../../interfaces/ICourseSection";
import VideoSection from "./components/VideoSection";
import {useDocumentTitle, useFavicon, useViewportSize, useWindowScroll} from "@mantine/hooks";
import {IUserCourseProgress} from "../../interfaces/IUserCourseProgress";
import {showNotification} from "@mantine/notifications";
import CourseQuestions from "./components/CourseQuestions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import Certificate from "./components/Certificate";
import {faCheckCircle} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import {faCircleX} from "@fortawesome/pro-regular-svg-icons/faCircleX";
import {faCircleExclamationCheck} from "@fortawesome/pro-regular-svg-icons/faCircleExclamationCheck";
import {faHandHoldingSeedling} from "@fortawesome/pro-regular-svg-icons/faHandHoldingSeedling";
import {faCircleExclamation} from "@fortawesome/pro-regular-svg-icons/faCircleExclamation";
import {faInfoCircle} from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import FeedbackAgenasModal from "./FeedbackAgenasModal";
import FeedbackAgenas from "./FeedbackAgenas";

const useStyles = createStyles((theme) => ({
    stepperMobile: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
    stepperTablet: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        }, [theme.fn.largerThan('lg')]: {
            display: 'none',
        },
    },
    stepperDesktop: {
        [theme.fn.smallerThan('lg')]: {
            display: 'none',
        },
    },

}));

const SingleCourseECM: React.FC = () => {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const [scroll, scrollTo] = useWindowScroll();


    const {color, sidebarSmall} = useSelector((state: any) => state.theme)
    const [currentUserCourseProgress, setCurrentUserCourseProgress] = useState<IUserCourseProgress>()

    const [course, setCourse] = useState<ICourse>();
    useDocumentTitle(course?.name);
    useFavicon(course?.image);

    const [fetchingCourse, setFetchingCourse] = useState<boolean>(false);
    const [fetchingCurrentUserCourseProgress, setFetchingCurrentUserCourseProgress] = useState<boolean>(false);
    const {id} = useParams();
    const [step, setStep] = useState(0);

    const [videoReady, setVideoReady] = useState<boolean>(false)
    const [isSavingEnd, setIsSavingEnd] = useState<boolean>(false)
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

    const [isModalOpened, setIsModalOpened] = useState<any>()
    const {width} = useViewportSize();
    const videoRef: any = useRef();
    const [maxAttemptsReached, setMaxAttemptsReached] = useState<boolean>(false);

    const [activeTab, setActiveTab] = useState<string | null>('test');
    const [modalOpened, setModalOpened] = useState(false);
    const openModal = () => {
        setModalOpened(true);
    };
    const closeModal = () => {
        setModalOpened(false);
    };
    const loadingOnId = async () => {
        setFetchingCourse(true)
        setFetchingCurrentUserCourseProgress(true)
        if (!sidebarSmall) {
            dispatch(toggleSidebarSize())
        }

        await CourseService.getCurrentUserCourseProgress(id)
            .then(setCurrentUserCourseProgress)
            .catch((error) => {
                genericError(error);
                setMaxAttemptsReached(true)
            })
            .finally(() => setFetchingCurrentUserCourseProgress(false))

        await CourseService.get(id)
            .then((r) => {
                setCourse(r);
            })
            .catch(genericError)
            .finally(() => {
                setFetchingCourse(false);
            })
    }

    useEffect(() => {
        return () => {
            setStep(0)
            setCourse(undefined)
            dispatch(setSidebarSize(false))
            setVideoReady(false)
            setIsFirstLoad(true)
        }
    }, [])

    useEffect(() => {
        if (id) {
            loadingOnId()
        }
    }, [id])

    useEffect(() => {
        if (currentUserCourseProgress) {
            setStep(currentUserCourseProgress.step)
        }
    }, [currentUserCourseProgress])

    useEffect(() => {
        if (videoReady) {
            videoRef.current?.initialize(currentUserCourseProgress);
            setIsFirstLoad(true);
        }
    }, [videoReady])

    const endSectionCallback = (userCourseProgress: IUserCourseProgress) => {
        setCurrentUserCourseProgress(userCourseProgress)
        showNotification({
            color: 'teal',
            message: 'Lezione completata'
        })
    }

    const answeredTheQuestions = (uhc: any, data: any, userCourseProgress: IUserCourseProgress = null,) => {
        const oldCourse = {...course};
        oldCourse.user_has_course = uhc;
        setCourse(oldCourse)
        if (data && Object.keys(data).length > 0) {
            setIsModalOpened(data);
        } else {
            loadingOnId()
        }
        if (userCourseProgress) {
            setCurrentUserCourseProgress(userCourseProgress)
        }
    }

    const switchComponent = () => {
        if (!course) return null;
        if (step >= course.sections.length) {
            if (step === course.sections.length) {
                return <Flex direction={'column'} w={{base: '100%', lg: '65%'}} gap={'xl'}>

                    <Tabs variant={"pills"} value={activeTab} onTabChange={setActiveTab}>
                        <Tabs.List grow>
                            <Tabs.Tab
                                style={

                                    course?.user_has_course.answered_the_questions
                                        ? {border: '1px solid rgb(64, 192, 87)'}
                                        : course?.user_has_course.wrong_test_answers ? {border: '1px solid #fa5252'} : {border: '1px solid rgb(134, 142, 150)'}
                                }
                                color={

                                    course?.user_has_course.answered_the_questions
                                        ? 'green'
                                        : course?.user_has_course.wrong_test_answers ? 'red' : 'gray'
                                }
                                value="test">Test fine corso</Tabs.Tab>
                            <Tabs.Tab
                                style={
                                    course?.user_has_course.feedback_sent
                                        ? {border: '1px solid rgb(64, 192, 87)'}
                                        : {border: '1px solid rgb(134, 142, 150)'}
                                }
                                color={
                                    course?.user_has_course.feedback_sent
                                        ? 'green'
                                        : 'gray'
                                }

                                value="questionary"
                            >
                                Questionario di gradimento
                            </Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="test">
                            <CourseQuestions
                                course={course}
                                callback={answeredTheQuestions}
                                goBack={() => setStep(step - 1)}/>
                        </Tabs.Panel>
                        <Tabs.Panel value="questionary">
                            <FeedbackAgenas goBack={() => setStep(step - 1)} callback={answeredTheQuestions}/>
                        </Tabs.Panel>
                    </Tabs>
                    {course?.user_has_course.answered_the_questions && course?.user_has_course.feedback_sent ? <>
                        <Button onClick={() => setStep(step + 1)} color={'green'}>Vai al certificato</Button>
                    </> : <></>}

                </Flex>
            }
            if (step === course.sections.length + 1) {
                return <Flex direction={"column"} w={{base: '100%', lg: '65%'}}><Certificate
                    userHasCourse={course.user_has_course}/>
                    <Button variant={"outline"} onClick={() => setStep(step - 1)}>Indietro</Button>
                </Flex>

            }
        }
        const activeSection: ICourseSection = course.sections[step];
        if (!activeSection) return null;
        switch (activeSection.section_type) {
            case "pdf":
                return <>PDF</>
            case "video":
                return <VideoSection
                    step={step}
                    ref={videoRef}
                    section={activeSection}
                    setIsSavingEnd={setIsSavingEnd}
                    setIsVideoReady={setVideoReady}
                    currentUserCourseProgress={currentUserCourseProgress}
                    isFirstLoad={isFirstLoad}
                    callback={endSectionCallback}/>
        }
    }

    return maxAttemptsReached ? <>
            <Alert icon={<FontAwesomeIcon icon={faCircleExclamation}/>}
                   title="Hai raggiunto il numero massimo di tentativo per questo corso." color="red">
                Per favore contatta il nostro supporto a: <Text fw={700}>support@become-hub.com</Text>
            </Alert>
        </>
        :

        <>
            <Box style={{display: 'relative'}}>
                <LoadingOverlay visible={isSavingEnd} title={'Salvataggio in corso, attendere.'}/>
                {width >= 1200 &&
                    <Breadcrumbs>
                        <Anchor href={'#'} onClick={() => navigate('/courses')}>
                            Tutti i corsi
                        </Anchor>
                        <Anchor href={'#'} onClick={() => navigate('/courses/ecm')}>
                            {width >= 1600 ? "Corsi con riconoscimento Crediti Formativi (ECM)" : "Corsi ECM"}
                        </Anchor>
                        <span>{course?.name}</span>
                    </Breadcrumbs>
                }
                <Flex direction={{base: 'column', xl: 'row'}} justify={'space-between'} my={24} gap={'xl'}>
                    <Title order={2}>Corso: {course?.name}</Title>
                    <Flex direction={'column'} gap={'xl'}>
                        <Flex align={'center'} gap={'xs'}>
                            <Box h={'100%'}>
                                <Image
                                    width={'auto'}
                                    height={'30px'}
                                    src={course?.provider_logo}
                                    alt={course?.provider_name}
                                />
                            </Box>

                            <Text>Provider: {course?.provider_name}</Text>
                        </Flex>
                        <Flex align={'center'} gap={'xs'}>
                            <Button w={45} p={1} disabled={true}><FontAwesomeIcon
                                icon={faHandHoldingSeedling}/></Button>
                            {/*   </Tooltip>*/}
                            <Flex direction={"column"}>
                                <Text
                                >Sponsor: {course?.sponsor}</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                {course?.user_has_course.number_of_attempts > 0 && !course?.user_has_course?.feedback_sent ?
                    <Alert icon={<FontAwesomeIcon icon={faInfoCircle}/>}
                           mb={24} mt={24}
                           title="Sondaggio AGENAS: Scheda della qualità percepita" color="orange"
                           variant="outline">
                        <Button onClick={openModal} color={'teal'}>Compila il questionario di gradimento</Button>
                        <FeedbackAgenasModal isOpen={modalOpened} onClose={closeModal}/>
                    </Alert> : null
                }
                {fetchingCourse || fetchingCurrentUserCourseProgress ?
                    <div style={{textAlign: 'center', marginTop: 60}}>
                        <Title order={2}>Recupero lezioni in corso...</Title>
                        <Loader variant={'bars'} size="xl" style={{margin: '36px auto'}}/>
                    </div>
                    : <Flex mt={50} gap={50} direction={{base: 'column-reverse', lg: 'row'}} w={'100%'}>
                        {course && switchComponent()}


                        {step < course?.sections.length &&
                            <>
                                <Flex justify={'center'}
                                      gap={16}
                                      style={{
                                          position: 'fixed',
                                          bottom: 0, left: 0, right: 0, padding: 10,
                                          backgroundColor: `${color === 'light' ? theme.colors.gray[2] : theme.colors.dark[6]}`
                                      }}>

                                    <Button variant={'outline'}
                                            onClick={() => setStep(Math.max(0, step - 1))}
                                            disabled={step <= 0}>Indietro</Button>

                                    <Button disabled={
                                        step >= currentUserCourseProgress?.step || step === course?.sections.length - 1
                                    }
                                            loading={isSavingEnd}
                                            onClick={() => setStep(Math.min(step + 1, course?.sections.length - 1))}
                                    >
                                        Avanti
                                    </Button>
                                </Flex>
                            </>
                        }


                        <ScrollArea w={{base: '50%', xl: '35%'}} className={classes.stepperDesktop}>
                            <Stepper active={step} onStepClick={setStep} orientation="vertical">
                                {course?.sections.map((s: ICourseSection, order: number) =>
                                    <Stepper.Step label={s.title}
                                                  key={s.id}
                                                  disabled={currentUserCourseProgress?.step < order && currentUserCourseProgress?.step !== course?.sections.length}
                                                  description={s.description}/>)}
                                <Stepper.Step label={'Scheda e test'}
                                              disabled={currentUserCourseProgress?.step < course?.sections.length}/>
                                <Stepper.Step label={'Certificato fine corso'}
                                              disabled={!course?.user_has_course.answered_the_questions}/>
                            </Stepper>
                        </ScrollArea>
                        <ScrollArea className={classes.stepperTablet}>
                            <Stepper size={'xs'} active={step} onStepClick={setStep} orientation="horizontal">
                                {course?.sections.map((s: ICourseSection, order: number) =>
                                    <Stepper.Step
                                        label={s.title}
                                        key={s.id}
                                        disabled={currentUserCourseProgress?.step < order && currentUserCourseProgress?.step !== course?.sections.length}
                                    />)}

                                <Stepper.Step label={'Scheda e test'}
                                              disabled={currentUserCourseProgress?.step < course?.sections.length}/>
                                <Stepper.Step label={'Certificato fine corso'}
                                              disabled={!course?.user_has_course.answered_the_questions}/>
                            </Stepper>
                        </ScrollArea>


                        <ScrollArea className={classes.stepperMobile}>
                            <Stepper size={'xs'} active={step} onStepClick={setStep} orientation="vertical">
                                {course?.sections.map((s: ICourseSection, order: number) =>
                                    <Stepper.Step
                                        label={s.title}
                                        key={s.id}
                                        disabled={currentUserCourseProgress?.step < order && currentUserCourseProgress?.step !== course?.sections.length}
                                    />)}

                                <Stepper.Step label={'Scheda e test'}
                                              disabled={currentUserCourseProgress?.step < course?.sections.length}/>
                                <Stepper.Step label={'Certificato fine corso'}
                                              disabled={!course?.user_has_course.answered_the_questions}/>
                            </Stepper>
                        </ScrollArea>


                        <Affix position={{bottom: 20, right: 20}}>
                            <Transition transition="slide-up" mounted={scroll.y > 0}>
                                {(transitionStyles) => (
                                    <Button
                                        leftIcon={<FontAwesomeIcon icon={faArrowUp}/>}
                                        style={transitionStyles}
                                        onClick={() => scrollTo({y: 0})}
                                    >
                                        Scorri in alto
                                    </Button>
                                )}
                            </Transition>
                        </Affix>
                    </Flex>
                }
            </Box>
            <Modal opened={isModalOpened !== undefined}
                   withinPortal={true}
                   style={{zIndex: 999}}
                   title={isModalOpened?.error ? 'Test non valido' : 'Risultati del test'}
                   onClose={() => {
                       setIsModalOpened(undefined);
                       if (isModalOpened.counter < isModalOpened.min) {
                           loadingOnId();
                       } else {
                           loadingOnId();
                       }
                   }} size={'80%'}>
                {isModalOpened?.error ? <><Text>Il progresso del corso attuale è inferiore a quello necessario a
                    sostenere il test</Text></> : <>
                    <Text><FontAwesomeIcon icon={faCheckCircle} color={'green'}/> Totale risposte
                        corrette: {isModalOpened?.counter}</Text>
                    <Text><FontAwesomeIcon icon={faCircleX} color={'red'}/> Totale risposte
                        errate: {isModalOpened?.total - isModalOpened?.counter}</Text>
                    <Text><FontAwesomeIcon icon={faCircleExclamationCheck} color={'yellow'}/> Minimo risposte corrette
                        necessarie al completamento del test: {isModalOpened?.min}</Text></>}
            </Modal>


        </>
}

export default SingleCourseECM;