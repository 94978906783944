import axios, {AxiosError, AxiosInstance, InternalAxiosRequestConfig} from "axios";
import axiosRetry from 'axios-retry';
import secureLocalStorage from "react-secure-storage";


let jwt: string = '';
let lang: string = 'it';
const interceptor = axios.create({
    baseURL: `${process.env["REACT_APP_URL"]}`,
    timeout: 60000,
});
const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;
let refreshing_token = false;


axiosRetry(interceptor, {
    retries: 3,
    retryDelay: (retryCount: number) => {
        return retryCount * 200
    },
    retryCondition: (error: AxiosError) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return error.response?.status === 425;
    },
});


export const Interceptor = async (): Promise<AxiosInstance> => {

    const expiration = '';
    /**
     * refreshing_token = true;
     *  await AuthService (refresh token).... refreshing_token = false
     */

        // logic: retrieve the token
    const t = secureLocalStorage.getItem('token') ?? '';
    jwt = (typeof t === 'string') ? t : '';

    const t2 = localStorage.getItem('lang') ?? 'it';
    lang = (typeof t2 === 'string') ? t2 : 'it';


    return new Promise((resolve, reject) => {
        (function waitForFoo() {
            if (!refreshing_token) return resolve(interceptor);
            setTimeout(waitForFoo, 30);
        })();
    });

}

// Add a request interceptor
interceptor.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
    if (jwt) {
        config.headers['Authorization'] = 'Bearer ' + jwt;
    }
    if (lang) {
        config.headers['X-localization'] = lang;
    }

    return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                PENDING_REQUESTS++;
                clearInterval(interval);
                resolve(config);
            }
        }, INTERVAL_MS);
    });

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


interceptor.interceptors.response.use(response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
}, async (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
    }
    return Promise.reject(error);
});
