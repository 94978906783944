import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store/store";
import {Provider} from "react-redux";
import './index.css'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";


if (process.env.REACT_APP_URL === "https://api-v23.become-hub.com") {
    Sentry.init({
        dsn: "https://ccb42932a32c4c66b67fc19c8e4ce8cd@o4504841405530112.ingest.sentry.io/4504841465036800",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
