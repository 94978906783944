import {showNotification} from "@mantine/notifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {List} from "@mantine/core";
import {isObject} from "lodash";

const switchError = (status: number): string => {
    switch (status) {
        case 500:
            return 'Errore del server';
        case 400:
            return 'Errore nella richiesta'
        case 422:
            return 'Impossibile accettare la richiesta'
        default:
            return 'Errore ' + status.toString();
    }
}

export function genericError(e: any) {
    const obj = {
        color: 'red',
        message: '',
        icon: <FontAwesomeIcon icon={faCross}/>,
        title: switchError(e.response?.status ?? 400),
        autoClose: 7000
    }

    if (e.response && e.response.data && isObject(e.response.data)) {
        const data = e.response.data;
        if (data.errors) {
            Object.values(data.errors).forEach((errors: string[]) => {
                errors.forEach((error: string) => {
                    const cObj = {...obj};
                    cObj.message = error;
                    setTimeout(() => {
                        showNotification(cObj)
                    }, 200)
                })
            })
            return;
        }
        obj.message = data.message ?? <List>
            {Object.entries(e.response.data).map((err: any, i: number) => <List.Item key={i}>{err[1]}</List.Item>)}
        </List>;
    }
    if (e instanceof TypeError) {
        obj.message = e.message;
    }

    showNotification(obj)

}
