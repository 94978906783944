import ForceChangePw from "./force_change_pw";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {setForceChangePwState} from "../../store/auth/authSlice";

export default function ResetPassword() {
    const dispatch = useDispatch();
    const location = useLocation();


    useEffect(() => {
        const search = location.search;
        const token = new URLSearchParams(search).get("token");
        const key = new URLSearchParams(search).get("key");
        const email = new URLSearchParams(search).get("email");
        dispatch(setForceChangePwState({
            tempKey: key,
            tempValue: token,
            email: email
        }))
    }, []);

    return <>
        <ForceChangePw/>
    </>
}
