import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Center, Group, Modal, MultiSelect, Text, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import AugmentedFoodService from "../../../services/apps/AugmentedFoodService";
import {genericError} from "../../../functions/genericError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {faGripDotsVertical} from "@fortawesome/pro-regular-svg-icons/faGripDotsVertical";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {faSpinner} from "@fortawesome/pro-regular-svg-icons/faSpinner";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {useSelector} from "react-redux";

const NewFoodList: React.FC<{
    opened: any,
    close: any
}> = ({opened, close}) => {

    const [food, setFood] = useState<any[]>([])
    const [fetchingFood, setFetchingFood] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')
    const {translations, lang} = useSelector((state: any) => state.lang);
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const form = useForm({
        initialValues: {
            name: '',
            food: [],
        },

        validate: {
            name: (value) => value.length < 3 ? (translations.validateName ?? "translations.validateName") : null
        },
    });

    useEffect(() => {
        setFetchingFood(true)
        AugmentedFoodService
            .listFood()
            .then((r) => {
                setFood(r);
            })
            .catch(genericError)
            .finally(() => setFetchingFood(false))
    }, [])

    useEffect(() => {
        if (food.length > 0) {
            const orderedList = food.sort((a, b): any => {
                const name1 = translations[a.name.toLowerCase()];
                const name2 = translations[b.name.toLowerCase()];
                if (name1 < name2) {
                    return -1;
                }
                if (name1 > name2) {
                    return 1;
                }
                return 0;
            });
            setFood(orderedList);
        }
    }, [opened, food, lang]);

    useEffect(() => {
        if (opened) {
            form.reset();
        }
    }, [opened])

    const onSubmit = (values: any) => {
        setIsSaving(true)
        AugmentedFoodService
            .createFoodList(values)
            .then((r) => {
                close(r);
            })
            .catch(genericError)
            .finally(() => setIsSaving(false))
    }


    const fields = form.values.food.map((i, index) => (
        <Draggable key={index} index={index} draggableId={index.toString()}>
            {(provided) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                        textAlign: 'center',
                        padding: theme.spacing.xs,
                        marginTop: 6,
                        marginBottom: 6,
                        borderRadius: theme.radius.md,
                    })}
                >
                    <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
                        <Center {...provided.dragHandleProps}>
                            <FontAwesomeIcon icon={faGripDotsVertical}/>
                        </Center>
                        <Text>
                            {translations.actionId ?? "translations.actionId"} {i}, {translations.actionName ?? "translations.actionName"} {food.find(o => o.id === i).name}
                        </Text>
                        <Button variant={'subtle'} color={'red'} ml={'auto'}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Group>
                </Box>
            )}
        </Draggable>
    ));

    return (
        <>
            <Modal opened={opened}
                   onClose={() => close(null)}
                   title={translations.modalTitle ?? "translations.modalTitle"}
                   closeOnClickOutside={false}
                   zIndex={999}
                   lockScroll={false}
                   size={'100%'}>

                <Alert icon={<FontAwesomeIcon icon={faCircleInfo}/>}
                       title={translations.alertWarning ?? "translations.alertWarning"} color="cyan"
                       withCloseButton
                       mb={12}
                       variant="outline">
                    {translations.warningText ?? "translations.warningText"}
                </Alert>


                <form onSubmit={form.onSubmit(onSubmit)}>

                    <TextInput
                        label={translations.foodListName ?? "translations.foodListName"}
                        required    {...form.getInputProps('name')}/>
                    <Text size={'sm'} mt={24}>{translations.food ?? "translations.food"}</Text>

                    {translations &&
                        <MultiSelect
                            mb={12}
                            value={value} onChange={setValue}
                            data={
                                food
                                    .map((f) => ({
                                        value: (f.id),
                                        label: translations[f.name.toLowerCase()] ?? f.name.toLowerCase()
                                    }))
                            }
                            withinPortal
                            zIndex={1000}
                            searchable
                            {...form.getInputProps('food')}
                            icon={<FontAwesomeIcon icon={fetchingFood ? faSpinner : faSearch} spin={fetchingFood}/>}
                            disabled={fetchingFood}/>
                    }


                    <Box style={{position: 'relative', minHeight: 250}}>
                        <DragDropContext
                            onDragEnd={({destination, source}) =>
                                form.reorderListItem('food', {from: source.index, to: destination.index})
                            }
                        >
                            <Droppable
                                droppableId="dnd-list"
                                ignoreContainerClipping={false}
                                direction="vertical">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {fields}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>
                    <Group position="right" mt="md">
                        <Button type="submit"
                                loading={isSaving}
                                disabled={isSaving}
                        >
                            {translations.foodListCreate ?? "translations.foodListCreate"}
                        </Button>
                    </Group>
                </form>
            </Modal>
        </>
    );
}
export default NewFoodList;