import React, {useEffect, useState} from "react";
import {Button, Flex, Group, LoadingOverlay, Modal, Select, Stack, Text, TextInput} from "@mantine/core";
import {genericError} from "../../functions/genericError";
import {DataTable} from "mantine-datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {PaginatedResponse} from "../../interfaces/PaginatedResponse";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";
import {useForm} from "@mantine/form";
import {dateToAwsDate} from "../../functions/dateToAwsDate";
import UserSelect from "../../components/UserSelect";
import {showNotification} from "@mantine/notifications";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {useDebouncedValue} from "@mantine/hooks";
import {faSync} from "@fortawesome/pro-regular-svg-icons";
import AdminUserDevicesService from "../../services/UserDevicesService";
import {modals} from "@mantine/modals";
import {IUserDevice} from "../../interfaces/IUserDevice";

const AdminTokenPage: React.FC = () => {

    const [page, setPage] = useState<number>(1)

    const [fetching, setFetching] = useState<boolean>(false);
    const [response, setResponse] = useState<PaginatedResponse>()

    const [selectedItems, setSelectedItems] = useState<IUserDevice[]>([])
    const [selectedItem, setSelectedItem] = useState<IUserDevice>()

    const [isSaving, setIsSaving] = useState<boolean>(false)

    const form = useForm<IUserDevice>({
        validate: {},
    });

    const [value, setValue] = useState('');
    const [deviceType, setDeviceType] = useState<string | null>(null);
    const [debounced] = useDebouncedValue(value, 600);

    useEffect(() => {
        setFetching(true);
        if (debounced !== '') {
            AdminUserDevicesService.search(debounced, page)
                .then(setResponse)
                .catch(genericError)
                .finally(() => setFetching(false))
        } else {
            AdminUserDevicesService.list(page)
                .then(setResponse)
                .catch(genericError)
                .finally(() => setFetching(false))
        }
    }, [debounced])

    useEffect(() => {
        if (selectedItem) {
            const c = {...selectedItem};
            form.setValues(c)
        }
        return () => {
            form.reset();
        }
    }, [selectedItem])

    const formOnSubmit = async (values) => {
        setIsSaving(true)
        const clone = {...values}
        clone.device_type = deviceType;
        if (clone.expires_at) {
            clone.expires_at = dateToAwsDate(clone.expires_at)
        }
        if (selectedItem?.id) {
            AdminUserDevicesService.update(clone, selectedItem.id)
                .then((r: IUserDevice) => {
                    const cR = {...response};
                    cR.data = [r, ...cR.data.filter((i: IUserDevice) => i.id !== r.id)];
                    setResponse(cR)
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        title: 'Success',
                        message: 'User device updated'
                    })
                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                    setSelectedItem(undefined)
                });
        } else {
            AdminUserDevicesService.create(clone)
                .then((r: IUserDevice) => {
                    const cR = {...response};
                    cR.data = [r, ...cR.data];
                    setResponse(cR)
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        title: 'Success',
                        message: 'User device created'
                    })
                    setSelectedItem(undefined)
                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                });
        }
    }

    const destroy = () => {
        setIsSaving(true)
        AdminUserDevicesService.destroy(selectedItem.id)
            .then(() => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    title: 'Success',
                    message: 'User device deleted'
                })
                setSelectedItem(undefined)
            })
            .catch(genericError)
            .finally(() => {
                setIsSaving(false)
            });
    }

    const openModal = () => modals.openConfirmModal({
        title: 'Please confirm your action',
        withinPortal: true,
        children: (
            <Text size="sm">
                This action is so important that you are required to confirm it with a modal. Please click
                one of these buttons to proceed.
            </Text>
        ),
        zIndex: 999,
        labels: {confirm: 'Confirm', cancel: 'Cancel'},
        onConfirm: () => destroy(),
    });

    return <>
        <div style={{display: 'relative'}}>
            <LoadingOverlay visible={fetching}/>

            <Flex justify={'space-between'}>
                <TextInput

                    onChange={(event) => setValue(event.currentTarget.value)}
                    icon={
                        <FontAwesomeIcon icon={fetching ? faSync : faSearch} spin={fetching}/>
                    } placeholder={'Search'} style={{width: '90%'}}/>
                <Button.Group>
                    <Button onClick={() => setSelectedItem({})}><FontAwesomeIcon icon={faPlus}/></Button>
                </Button.Group>
            </Flex>


            <Modal opened={selectedItem !== undefined}
                   title={selectedItem?.id ? `Modifica device ${selectedItem.id}` : 'Crea nuovo User Device'}
                   onClose={() => setSelectedItem(undefined)}>
                <form onSubmit={form.onSubmit(formOnSubmit)}>
                    <Stack>
                        <Select
                            data={['fitbit', 'oculus']}
                            label={'Device type'}
                            required
                            value={deviceType} onChange={setDeviceType}/>

                        <TextInput
                            label="Device Name"
                            required
                            {...form.getInputProps('device_name')}
                        />
                        <UserSelect
                            defaultValue={selectedItem?.id ? selectedItem.user.email : null}
                            {...form.getInputProps("user_id")}/>
                    </Stack>

                    <Group position="right" mt="md">
                        {selectedItem?.id ?
                            <Button color={'red'}
                                    loading={isSaving}
                                    onClick={openModal}
                                    disabled={isSaving}>
                                Destroy
                            </Button> : null}
                        <Button type="submit"
                                loading={isSaving}
                                disabled={isSaving}>
                            Salva
                        </Button>
                    </Group>
                </form>
            </Modal>

            <DataTable
                mt={24}
                minHeight={400}
                selectedRecords={selectedItems}
                onSelectedRecordsChange={setSelectedItems}
                withBorder
                //sortStatus={sortStatus}
                //onSortStatusChange={setSortStatus}
                onRowClick={setSelectedItem}
                records={response?.data}
                columns={[
                    {
                        accessor: 'id', title: 'ID'
                    },
                    {
                        accessor: 'email', title: 'Email', render: (e: IUserDevice) => {
                            return e.email ?? e.user?.email
                        }
                    },
                    {
                        accessor: 'device_name', title: 'Device name'
                    },
                    {
                        accessor: 'one_time_password', title: 'One time password'
                    },
                    {
                        width: 140,
                        accessor: 'last_login_at', title: 'Disponibile', render: (e) =>
                            <FontAwesomeIcon color={e.last_login_at ? 'red' : 'teal'}
                                             icon={e.last_login_at ? faCross : faCheck}/>
                    },
                ]}
                totalRecords={response?.total}
                recordsPerPage={response?.per_page}
                page={page}
                onPageChange={setPage}

                striped
                highlightOnHover

                //idAccessor={'id'}

                loadingText="Caricamento in corso..."
                noRecordsText="Nessun token disponibile"
                // uncomment the next line to use a custom pagination text
                // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
                // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
                // paginationColor="grape"
                // uncomment the next line to use a custom pagination size
                // paginationSize="md"
            />
        </div>
    </>
}
export default AdminTokenPage