import {Flex, LoadingOverlay, Modal, Text} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AdminUserService from "../../services/admin/UserService";
import {genericError} from "../../functions/genericError";
import {showNotification} from "@mantine/notifications";
import {IGroupedTestAnswers, ITestAnswers} from "../../interfaces/ITestAnswers";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/pro-regular-svg-icons/faCircleCheck";
import {faCircleX} from "@fortawesome/pro-regular-svg-icons/faCircleX";

const UserHasTestQuestionsModal = (props: any) => {

    const {selectedUserCourseTests, setSelectedUserCourseTests, user_id} = props;
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const {user} = useSelector((state: any) => state.auth)
    const [data, setData] = useState<ITestAnswers>(null);
    const [groupData, setGroupData] = useState<any>(null);
    const [groupDataWithKey, setGroupDataWithKey] = useState<IGroupedTestAnswers>(null);
    const [noAnswers, setNoAnswers] = useState<boolean>(false)

    const retrieveCourseTests = () => {
        if (user) {
            setIsSaving(true)
            AdminUserService.retrieveCourseTests({user_id: user_id, course_id: selectedUserCourseTests})
                .then((r: any) => {
                    setData(r)
                })
                .catch((e) => {
                    genericError(e)
                    setIsSaving(false)
                    showNotification({
                        color: 'red',
                        message: 'There are no tests completed for this test'
                    })
                    setNoAnswers(true)
                })
                .finally(() => setIsSaving(false))
        }
    }

    useEffect(() => {
        if (selectedUserCourseTests) {
            retrieveCourseTests()
        }
    }, [selectedUserCourseTests]);

    useEffect(() => {
        if (data !== null) {
            setGroupData(_.groupBy(data, item => `${item.attempt}-${item.created_at}`))
        }
    }, [data]);

    useEffect(() => {
        if (groupData !== null && groupData) {
            setGroupDataWithKey(Object.entries(groupData).reduce<IGroupedTestAnswers>(
                (acc, [key, items]) => {
                    const [attempt, created_at] = key.split('-');
                    acc[key] = [{
                        attempt: parseInt(attempt, 10),
                        created_at: key.split('-').slice(1).join('-'),
                        data: items as ITestAnswers[]
                    }];
                    return acc;
                },
                {}
            ));
        }
    }, [groupData]);

    useEffect(() => {
    }, [groupDataWithKey]);

    return <Modal
        size={"xl"}
        title={
            <Text fw={700} fz={{base: 'md', md: 'lg'}} px={{base: 10, md: 30}} mt={'sm'}>
                Test answers
            </Text>
        }
        opened={selectedUserCourseTests}
        onClose={() => {
            setSelectedUserCourseTests(undefined)
            setNoAnswers(false)
        }}>
        <LoadingOverlay visible={isSaving}/>
        {!noAnswers ? <>
                {groupDataWithKey ? <Flex direction={'column'} gap={'sm'} px={{base: 10, md: 30}} pb={30}>
                    {Object.entries(groupDataWithKey).map(([key, groups]) => (
                        <Flex key={key} direction={'column'} gap={'xs'} pt={{base: 'md', md: 'xl'}}>
                            <Text p={'xs'} fw={700} fz={{base: 'sm', md: 'md'}} style={{border: '1px solid grey'}}>Attempt
                                n. {groups[0].attempt + 1} of {new Date(groups[0].created_at).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}</Text>
                            {/* Map over the 'data' array in each group */}
                            {groups.map((group, index) => (
                                <Flex direction={'column'} key={index} style={{border: '1px solid grey'}} fz={{base: 'sm', md: 'md'}}>
                                    {/* Render your component here using the 'group' data */}
                                    {group.data.map((item) => (
                                        <Flex direction={'column'} key={item.id}>
                                            <Flex gap={'sm'} p={'xs'}>
                                                <Text fw={700}>Question: </Text>
                                                <Text maw={500}>{item.description}</Text>
                                            </Flex>
                                            <Flex p={'xs'} justify={'space-between'} align={'center'}
                                                  sx={(theme) => ({

                                                      backgroundColor:
                                                          (item.is_correct ? theme.colors.green[1] : theme.colors.red[1])


                                                  })}>
                                                <Flex gap={'xl'}>
                                                    <Text fw={700}>Answer:</Text>
                                                    <Text>{item.answer}</Text>
                                                </Flex>
                                                <FontAwesomeIcon color={item.is_correct ? 'green' : 'red'}
                                                                 icon={item.is_correct ? faCircleCheck : faCircleX}/>

                                            </Flex>


                                        </Flex>

                                    ))}
                                </Flex>
                            ))}
                        </Flex>
                    ))}
                </Flex> : <></>}
            </> :
            <Text>There are no tests completed for this test</Text>}
    </Modal>
}

export default UserHasTestQuestionsModal;