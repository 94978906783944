import {
    Loader,
    Select, SelectProps
} from '@mantine/core';
import React, {useEffect, useState} from 'react';
import {IUser} from "../interfaces/IUser";
import AdminUserService from "../services/admin/UserService";
import {genericError} from "../functions/genericError";
import {useDebouncedValue} from "@mantine/hooks";
import {PaginatedResponse} from "../interfaces/PaginatedResponse";

interface ExtendedSelectProps extends SelectProps {
    defaultValue?: string
}

type Props = Omit<ExtendedSelectProps, 'data'>;
const UserSelect: React.FunctionComponent<Props> = props => {
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [data, setData] = useState<{ value: string; label: string }[]>([])
    const [value, setValue] = useState<string>('')
    const [debounced] = useDebouncedValue(value, 600);

    useEffect(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue)
        }
    }, [props])

    const fetchData = (v: string) => {
        setIsSearching(true)
        AdminUserService.search(v, 1)
            .then((r: PaginatedResponse) => {
                setData(r.data.map((user: IUser) => {
                    return {
                        value: user.id.toString(),
                        label: user.email
                    };
                }))
            })
            .catch(genericError)
            .finally(() => {
                setIsSearching(false)
            })
    }


    useEffect(() => {
        if (debounced.length > 2) {
            if (props.defaultValue) {
                if (props.defaultValue !== debounced) {
                    fetchData(debounced);
                }
            } else {
                const item = data.find(i => i.value === props.value);
                if (item && item.label === debounced) {
                    return;
                } else {
                    fetchData(debounced);
                }
            }
        }
    }, [debounced])


    if (isSearching) {
        return (
            <Select
                {...props}
                data={[]}
                label={'User'}
                disabled
                rightSection={<Loader size={20}/>}
            />
        );
    }

    return (
        <Select
            {...props}
            data={data}
            label={'User'}
            description={'You need to enter at least 3 characters to search'}
            searchable
            clearable
            required
            filterDataOnExactSearchMatch={false}
            onSearchChange={setValue}
            onSelect={(e) => {
                e.preventDefault()
            }}
            searchValue={value}
            nothingFound={'Nothing found'}
            maxDropdownHeight={150}
            limit={20}
        />
    );
};

export default UserSelect;