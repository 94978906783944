import {createSlice} from '@reduxjs/toolkit'
import secureLocalStorage from "react-secure-storage";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authState: 'SIGN_IN',
        user: null,
        token: null,
        tempKey: null,
        tempValue: null,
        email: null
    },
    reducers: {
        setForceChangePwState: (state, action) => {
            state.authState = 'FORCE_CHANGE_PW';
            state.tempKey = action.payload.tempKey;
            state.tempValue = action.payload.tempValue;
            state.email = action.payload.email;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setAuthState: (state, action) => {
            state.authState = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
            secureLocalStorage.setItem('token', action.payload);
        },
        setTokenAndState: (state, action) => {
            state.authState = action.payload.authState;
            state.token = action.payload.token;
            secureLocalStorage.setItem('token', action.payload.token);
        },
        setUser: (state, action) => {
            state.authState = action.payload.authState;
            state.user = action.payload.user;
            state.token = action.payload.token;
            secureLocalStorage.setItem('token', action.payload.token);
            secureLocalStorage.setItem('user', JSON.stringify(action.payload.user));
        },
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
            state.tempKey = null;
            state.tempValue = null;
            state.email = null;
            state.authState = 'SIGN_IN';
            secureLocalStorage.removeItem('token');
            secureLocalStorage.removeItem('user');
        }
    }
});
export const {
    setAuthState
    , setUser
    , setEmail
    , logout
    , setToken
    , setTokenAndState
    , updateUser
    , setForceChangePwState
} = authSlice.actions;

export default authSlice.reducer;