import {useHover} from '@mantine/hooks';
import {Button, createStyles, Paper, rem, Text, Title} from '@mantine/core';


const useStyles = createStyles((theme) => ({
    card: {
        height: rem(280),
        width: rem(280),
        borderRadius: rem(0),
        padding: rem(1.5),
        display: 'flex',
        alignItems: 'flex-end',
        background: 'white',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxSizing: "border-box",
        transition: "0.41s ease-out all",
        position: "relative",
    },

    cardHover: {
        transform: "translateY(12px)",
    },

    cardBefore: {
        opacity: 1,
    },

}));

// TODO creare interfaccia Video
function VideoCard({video, setSelectedVideo, image}: any) {
    const {classes} = useStyles();
    const {hovered, ref} = useHover();

    return (
        <Paper
            shadow="md"
            ref={ref}
            p="xl"
            onClick={() => setSelectedVideo(video)}
            radius="md"
            sx={{backgroundImage: `url('${image ?? video.image}')`}}
            className={classes.card + ' ' + (hovered ? classes.cardHover : '') + ' videoCardBefore'}
        >

            <div className="info">
                <Text size="xs" tt={'uppercase'} fw={700} c={'gray.2'}>
                    {video.category}
                </Text>
                <Title order={4}>{video.name}</Title>
                <Text lineClamp={2} mt={6} fz="xs">{video.short_description}</Text>
                <Button className="button" mt={12}>
                    Apri il video
                </Button>
            </div>
        </Paper>
    );
}

export default VideoCard;