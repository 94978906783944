import {Alert, Avatar, Button, Flex, Modal, Stack, Text, TextInput, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import AdminUserService from "../../services/admin/UserService";
import {genericError} from "../../functions/genericError";
import {format} from 'date-fns'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {IRole} from "../../interfaces/IRole";
import {faTriangleExclamation} from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";
import {DatePickerInput} from "@mantine/dates";
import {faCalendar} from "@fortawesome/pro-regular-svg-icons/faCalendar";

const UserHasVideoModal = (props: any) => {

    const {selectedUserVideo, setSelectedUserVideo, callback, user_id} = props;
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const {user} = useSelector((state: any) => state.auth)
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [shortData, setShortData] = useState();
    const form = useForm<any>({
        initialValues: {
            valid_until: null,
            subscription: "0",
        },
        validate: {
            valid_until: (value: any) => {
                if (!value) {
                    return 'Valid until is required';
                }
                return undefined;
            },
            subscription: (value: any) => {
                if (!value) {
                    return 'subscription is required';
                }
                return undefined;
            },
        }
    });

    useEffect(() => {
        if (selectedUserVideo) {
            const data = selectedUserVideo;
            form.setValues({
                subscription: data.subscription,
            });
            setShortData(selectedUserVideo.valid_until.slice(0, 10))
        } else {
            form.reset();
        }
    }, [selectedUserVideo]);

    useEffect(() => {
        if (user?.roles?.some((r: IRole) => r.name === 'Supervisor')) {
            setIsSupervisor(true)
        }
    }, [user]);


    const formOnSubmit = async (values) => {
        const clone = structuredClone(values);
        clone.video_id = selectedUserVideo.video.id;
        clone.user_id = user_id;
        clone.subscription = values.subscription;
        //TODO Prende "no sub" al momento...
        setIsSaving(true)
        if (values.valid_until) {
            clone.valid_until = format(values.valid_until, 'yyyy-MM-dd');
        }
        AdminUserService.updateUserHasVideo(clone)
            .then((r) => {
                AdminUserService.getUser(r)
                    .then(callback)
                    .catch(genericError)
                    .finally(() => {
                        setIsSaving(false)
                        setSelectedUserVideo(undefined)
                    })

            })
            .catch((e) => {
                genericError(e)
                setIsSaving(false)
            })
    };

    return <Modal
        title={<Flex gap={16}>
            <Avatar src={selectedUserVideo?.video.image} size={24}/>
            <Title size={'h4'}>{selectedUserVideo?.video.name}</Title>

        </Flex>
        }
        opened={selectedUserVideo}
        onClose={() => setSelectedUserVideo(undefined)}>
        <form onSubmit={form.onSubmit(formOnSubmit)}>
            <Stack style={{minHeight: 550}}>
                <Text c={"dimmed"}>Category: {selectedUserVideo?.video.category}</Text>
                <Text ta={"center"}>Current Valid Until</Text>
                <Text ta={"center"}>{shortData}</Text>
                <DatePickerInput
                    icon={<FontAwesomeIcon icon={faCalendar}/>}
                    allowDeselect
                    clearable
                    {...form.getInputProps('valid_until')}
                    label="Valid until"
                    withAsterisk
                    required={true}
                    //          valueFormat={"yyyy-MM-dd HH:mm:ss"}
                />
                <TextInput
                    {...form.getInputProps('subscription')}
                    label="Subscription"
                    placeholder={"0"}
                    withAsterisk
                    required={true}
                />
                <Flex style={{flex: 1}} align={'flex-end'} direction={"column"}>
                    {isSupervisor ?
                        <Alert icon={<FontAwesomeIcon icon={faTriangleExclamation}/>} title="Warning!" color="orange"
                               w={"100%"}>
                            As Supervisor you cannot change User info
                        </Alert> : <></>}
                    <Button
                        fullWidth
                        type={'submit'}
                        mt={25}
                        disabled={isSaving || isSupervisor}
                        loading={isSaving}>
                        Save
                    </Button>
                </Flex>
            </Stack>

        </form>
    </Modal>
}

export default UserHasVideoModal;