import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import CHeader from "../shared/CHeader";
import Sidebar from "../shared/Sidebar";
import {AppShell, Box} from "@mantine/core";

const ProtectedLayout: React.FC = () => {


    return <AppShell
        navbarOffsetBreakpoint="lg"
        asideOffsetBreakpoint="sm"
        navbar={<Sidebar/>}
        header={<CHeader/>}
    >
        <Box style={{maxWidth: '100%'}}>
            <Outlet/>
        </Box>
    </AppShell>
}

export default ProtectedLayout;