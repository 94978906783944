import React, {useEffect, useState} from "react";
import {genericError} from "../../functions/genericError";
import {Button, Card, Flex, Image, Loader, Text, Title} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMediaQuery} from "@mantine/hooks";
import TestService from "../../services/admin/TestService";
import {faCheckCircle} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import {faXmarkCircle} from "@fortawesome/pro-regular-svg-icons/faXmarkCircle";
import {useNavigate} from "react-router-dom";


const TestCourses = () => {

    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 900px)');
    const [fetching, setFetching] = useState<boolean>(false);
    const [courses, setCourses] = useState<any[]>([]);
    const [inProgress, setInProgress] = useState<boolean>(false);

    const init = () => {
        setFetching(true)
        TestService.checkCourses()
            .then(setCourses)
            .catch(genericError)
            .finally(() => {
                setFetching(false)
            })
    }

    useEffect(() => {
        init();
    }, [])


    const launch = () => {
        setInProgress(true)
        TestService.launchTests()
            .then(() => {
                init();
            })
            .catch(genericError)
            .finally(() => {
                setInProgress(false)
            })
    }

    return <>
        <Flex align={'flex-end'}>
            <Button onClick={() => launch()} loading={inProgress} disabled={inProgress}>Launch tests</Button>
        </Flex>
        <Flex mt={50} gap={16} wrap={'wrap'} justify={matches ? 'center' : 'space-around'}>
            {
                fetching ?
                    <div style={{textAlign: 'center', flex: 1, marginTop: 24}}>
                        <Title order={2}>Recupero corsi...</Title>
                        <Loader variant={'bars'} size="xl" style={{margin: '36px auto'}}/>
                    </div> :
                    <>
                        {
                            courses.length === 0 ? <Title>Nessun test presente</Title> :
                                courses.map((c: any) => {
                                    return <Card withBorder className={'flex-card'}
                                                 onClick={() => navigate('/admin/tests/testCourses/' + c.course_id)}
                                                 key={c.id}
                                                 style={{width: 220, cursor: "pointer", minHeight: 380}}>
                                        <Card.Section style={{flex: 1}}>
                                            <Flex justify={'space-between'} direction={'column'} style={{flex: 1}}>
                                                <div style={{flex: 1, marginBottom: 12}}>
                                                    <Image src={c.image} alt={c.name} height={200} radius={12}
                                                           width={200}/>
                                                    <Text size="sm" weight={700} mt={12}>
                                                        {c.name}
                                                    </Text>
                                                </div>
                                                <FontAwesomeIcon
                                                    size={'2x'}
                                                    icon={c.passed ? faCheckCircle : faXmarkCircle}
                                                    color={c.passed ? 'green' : 'red'}
                                                />
                                            </Flex>
                                        </Card.Section>
                                    </Card>
                                })}
                    </>
            }
        </Flex>
        <br/>
    </>
}
export default TestCourses;