import React, {useEffect, useState} from "react";
import {ICourse} from "../../interfaces/ICourse";
import {useParams} from "react-router-dom";
import CourseService from "../../services/CourseService";
import {genericError} from "../../functions/genericError";
import {Button, Group, Loader, Notification, Radio, Stack, Text, Textarea, Title} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import {useForm} from "@mantine/form";
import {useDocumentTitle, useFavicon} from "@mantine/hooks";
import {showNotification} from "@mantine/notifications";

const FeedbackAgenas: React.FC<any> = (props) => {
    const [saving, setSaving] = useState<boolean>(false);
    const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
    const [feedbackAlreadySend, setFeedbackAlreadySend] = useState<boolean>(false);
    const [feedbackAlreadySended, setFeedbackAlreadySended] = useState<any>();
    const [course, setCourse] = useState<ICourse>();
    useDocumentTitle(!course ? 'Loading' : 'Feedback ' + course?.name);
    useFavicon(course?.image);
    const [fetchingCourse, setFetchingCourse] = useState<boolean>(true);
    const {id} = useParams();
    const form = useForm({
        initialValues: {notes: '', relevance: '', quality: '', utility: '', time: '', sponsor: ''},
        validate: {
            notes: (value: any) => {
                if (form.values.sponsor === '4' || form.values.sponsor === '5') {
                    if (!value) {
                        return "Campo note richiesto per risposte con influenza 'più che rilevante' o 'molto rilevante'.";
                    }
                }
                if (value && value.length > 500) {
                    return "La lunghezza del campo note deve essere al massimo di 500 caratteri.";
                }
                return null;
            },
            relevance: (value: any) => ((value !== null && value !== undefined && value !== '') ? null : 'Devi seleziona almeno una risposta per questa domanda'),
            quality: (value: any) => ((value !== null && value !== undefined && value !== '') ? null : 'Devi seleziona almeno una risposta per questa domanda'),
            utility: (value: any) => ((value !== null && value !== undefined && value !== '') ? null : 'Devi seleziona almeno una risposta per questa domanda'),
            time: (value: any) => ((value !== null && value !== undefined && value !== '') ? null : 'Devi seleziona almeno una risposta per questa domanda'),
            sponsor: (value: any) => ((value !== null && value !== undefined && value !== '') ? null : 'Devi seleziona almeno una risposta per questa domanda'),
        }
    });

    useEffect(() => {
        if (id) {
            CourseService.get(id)
                .then(setCourse)
                .catch(genericError)
                .finally(() => setFetchingCourse(false))
        }
    }, [id])

    useEffect(() => {
        if (course?.user_has_course?.feedback_sent) {
            setFeedbackAlreadySend(true)
        }
    }, [course]);

    const onSubmit = (values: any) => {
        if (!id) return;
        setSaving(true)
        CourseService.sendFeedback(values, parseInt(id))
            .then((data) => {
                setFeedbackSent(true)
                showNotification({
                    color: 'teal',
                    message: data.message,
                    autoClose: 12000
                })
                setFeedbackAlreadySended(data.uhc)
                //props.callback(data.uhc, data.data)
            })
            .catch(genericError)
            .finally(() => setSaving(false))
    }

    return <>

        {fetchingCourse ?
            <div style={{textAlign: 'center', marginTop: 60}}>
                <Title order={2}>Recupero informazioni in corso...</Title>
                <Loader variant={'bars'} size="xl" style={{margin: '36px auto'}}/>
            </div> :
            <>
                <Group position="apart">
                    <Title my={24} order={2}>Scheda della qualità percepita</Title>
                    <Button variant={'outline'}
                            leftIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            onClick={props.goBack}
                    >Torna alle lezioni</Button>
                </Group>
                {
                    feedbackAlreadySend ? <>
                            <Text>Hai già inviato il feedback per questo corso</Text>
                        </>
                        :
                        <>
                            <form onSubmit={form.onSubmit(onSubmit)}>
                                <Stack>
                                    <Radio.Group
                                        {...form.getInputProps('relevance')}
                                        label="Come valuta la rilevanza degli argomenti trattati rispetto alle sue necessità di aggiornamento?"
                                        withAsterisk
                                    >
                                        <Radio value="1" label="Non rilevante" mt={5} mb={5}/>
                                        <Radio value="2" label="Poco rilevante" mt={5} mb={5}/>
                                        <Radio value="3" label="Rilevante" mt={5} mb={5}/>
                                        <Radio value="4" label="Più che rilevante" mt={5} mb={5}/>
                                        <Radio value="5" label="Molto rilevante" mt={5} mb={5}/>
                                    </Radio.Group>

                                    <Radio.Group
                                        {...form.getInputProps('quality')}
                                        label="Come valuta la qualità educativa del programma ECM?"
                                        withAsterisk
                                    >
                                        <Radio value="1" label="Insufficiente" mt={5} mb={5}/>
                                        <Radio value="2" label="Parziale" mt={5} mb={5}/>
                                        <Radio value="3" label="Sufficiente" mt={5} mb={5}/>
                                        <Radio value="4" label="Buono" mt={5} mb={5}/>
                                        <Radio value="5" label="Eccellente" mt={5} mb={5}/>
                                    </Radio.Group>

                                    <Radio.Group
                                        {...form.getInputProps('utility')}
                                        label="Come valuta l’utilità di questo evento per la sua formazione/aggiornamento?"
                                        withAsterisk
                                    >
                                        <Radio value="1" label="Insufficiente" mt={5} mb={5}/>
                                        <Radio value="2" label="Parziale" mt={5} mb={5}/>
                                        <Radio value="3" label="Utile" mt={5} mb={5}/>
                                        <Radio value="4" label="Più che utile" mt={5} mb={5}/>
                                        <Radio value="5" label="Molto utile" mt={5} mb={5}/>
                                    </Radio.Group>

                                    <Radio.Group
                                        {...form.getInputProps('time')}
                                        label="Il tempo che ha dedicato ad acquisire le informazioni contenute nel programma FAD rispetto alle ore previste è stato:"
                                        withAsterisk
                                    >
                                        <Radio value="1" label="Molto inferiore" mt={5} mb={5}/>
                                        <Radio value="2" label="Poco inferiore" mt={5} mb={5}/>
                                        <Radio value="3" label="Uguale" mt={5} mb={5}/>
                                        <Radio value="4" label="Poco superiore" mt={5} mb={5}/>
                                        <Radio value="5" label="Molto superiore" mt={5} mb={5}/>
                                    </Radio.Group>

                                    <Radio.Group
                                        {...form.getInputProps('sponsor')}
                                        label={(course.sponsor === 'Nessuno sponsor') ? "Questo programma FAD NON è stato preparato con il supporto di uno sponsor. Ritiene che nel programma ci siano riferimenti, indicazioni e/o informazioni non equilibrate o non corrette per influenza di sponsor o altri interessi commerciali ?" : `Questo programma FAD è stato preparato con il supporto di '${course.user_has_course.sponsor}'. Ritiene che nel programma ci siano riferimenti, indicazioni e/o informazioni non equilibrate o non corrette per influenza dello sponsor o di altri interessi commerciali?`}
                                        withAsterisk
                                    >
                                        <Radio value="1" label="Nessuna influenza" mt={5} mb={5}/>
                                        <Radio value="2" label="Influenza poco rilevante" mt={5} mb={5}/>
                                        <Radio value="3" label="Influenza rilevante" mt={5} mb={5}/>
                                        <Radio value="4" label="Influenza più che rilevante" mt={5} mb={5}/>
                                        <Radio value="5" label="Influenza molto rilevante" mt={5} mb={5}/>
                                    </Radio.Group>


                                    <Textarea
                                        maxLength={500}
                                        description={`${form.values.notes.length} / 500 caratteri`}
                                        label={'In caso di risposte di influenza “più che rilevante” o “molto rilevante” indicare qualche esempio.\n'}
                                        {...form.getInputProps('notes')}
                                        autosize
                                        minRows={2}
                                    />
                                </Stack>
                                <Group position="right" mt="md">
                                    <Button disabled={saving} loading={saving} onClick={() => {
                                        window.print();
                                    }} variant={"outline"}>Stampa</Button>
                                    {feedbackSent ?
                                        <Button
                                            onClick={() => props.callback(feedbackAlreadySended, "")}>Aggiorna</Button> :
                                        <Button type="submit" disabled={saving || feedbackSent}
                                                loading={saving}>Salva</Button>}

                                </Group>
                            </form>

                            <Notification title={'Informazione'} mt={20}>
                                Se lo ritiene opportuno potrà segnalare il contenuto della presente scheda,
                                evidenziando eventuali anomalie, con particolare riferimento al punto 5,
                                utilizzando l’indirizzo e-mail ecmfeedback@agenas.it o, in alternativa, il
                                portale myECM tramite il quale è possibile procedere alla compilazione online
                                della scheda
                            </Notification>
                        </>

                }
            </>
        }
    </>


}
export default FeedbackAgenas;