import {Alert, Avatar, Button, Flex, Modal, NumberInput, Stack, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import AdminUserService from "../../services/admin/UserService";
import {genericError} from "../../functions/genericError";
import {DatePickerInput} from "@mantine/dates";
import {format} from 'date-fns'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/pro-regular-svg-icons/faCalendar";
import {useSelector} from "react-redux";
import {IRole} from "../../interfaces/IRole";
import {faTriangleExclamation} from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";

const UserHasCourseModal = (props: any) => {

    const {selectedUserCourse, setSelectedUserCourse, callback, user_id} = props;
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const {user} = useSelector((state: any) => state.auth)
    const [isSupervisor, setIsSupervisor] = useState(false);
    const form = useForm({
        initialValues: {
            completed_at: null,
            step: 0,
            current_time: 0,
            number_of_attempts: 0
        },
    });

    useEffect(() => {
        if (selectedUserCourse) {
            form.setValues({
                completed_at: selectedUserCourse.completed_at ? new Date(selectedUserCourse.completed_at) : null,
                step: selectedUserCourse.step,
                current_time: selectedUserCourse.current_time,
                number_of_attempts: selectedUserCourse.number_of_attempts
            })
        } else {
            form.reset();
        }
    }, [selectedUserCourse]);

    useEffect(() => {
        if (user?.roles?.some((r: IRole) => r.name === 'Supervisor')) {
            setIsSupervisor(true)
        }
    }, [user]);


    const formOnSubmit = async (values) => {
        const clone = structuredClone(values);
        clone.course_id = selectedUserCourse.id;
        clone.user_id = user_id;
        setIsSaving(true)
        if (values.completed_at) {
            clone.completed_at = format(values.completed_at, 'yyyy-MM-dd');
        }
        AdminUserService.updateUserHasCourse(clone)
            .then((r) => {
                AdminUserService.getUser(r)
                    .then(callback)
                    .catch(genericError)
                    .finally(() => {
                        setIsSaving(false)
                        setSelectedUserCourse(undefined)
                    })

            })
            .catch((e) => {
                genericError(e)
                setIsSaving(false)
            })
    };

    return <Modal
        title={<Flex gap={16}>
            <Avatar src={selectedUserCourse?.image} size={24}/>
            <Title size={'h4'}>{selectedUserCourse?.name}</Title>
        </Flex>
        }
        opened={selectedUserCourse}
        onClose={() => setSelectedUserCourse(undefined)}>
        <form onSubmit={form.onSubmit(formOnSubmit)}>
            <Stack style={{minHeight: 500}}>
                <DatePickerInput
                    icon={<FontAwesomeIcon icon={faCalendar}/>}
                    allowDeselect
                    clearable
                    {...form.getInputProps('completed_at')}
                    label="Course completed at"
                />
                <NumberInput
                    {...form.getInputProps('step')}
                    label="Current step"
                    max={selectedUserCourse?.sections}
                    withAsterisk
                />
                <NumberInput
                    {...form.getInputProps('current_time')}
                    label="Current time"
                    withAsterisk
                />
                <NumberInput
                    {...form.getInputProps('number_of_attempts')}
                    label="Number of attempts"
                    withAsterisk
                    min={0}
                    max={5}
                />
                <Flex style={{flex: 1}} align={'flex-end'} direction={"column"}>
                    {isSupervisor ?
                        <Alert icon={<FontAwesomeIcon icon={faTriangleExclamation}/>} title="Warning!" color="orange"
                               w={"100%"}>
                            As Supervisor you cannot change User info
                        </Alert> : <></>}
                    <Button
                        fullWidth
                        type={'submit'}
                        mt={12}
                        disabled={isSaving || isSupervisor}
                        loading={isSaving}>
                        Save
                    </Button>
                </Flex>
            </Stack>

        </form>
    </Modal>
}

export default UserHasCourseModal;