import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {ICourseSection} from "../../../interfaces/ICourseSection";
import {ActionIcon, Flex, Progress, Text, useMantineTheme} from "@mantine/core";
import {useFullscreen, useViewportSize} from "@mantine/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolume} from "@fortawesome/pro-regular-svg-icons/faVolume";
import {faPlay} from "@fortawesome/pro-regular-svg-icons/faPlay";
import {faExpand} from "@fortawesome/pro-regular-svg-icons/faExpand";
import {faPause} from "@fortawesome/pro-regular-svg-icons/faPause";
import CourseService from "../../../services/CourseService";
import {useParams} from "react-router-dom";
import {genericError} from "../../../functions/genericError";
import {isObject} from "lodash";
import {useSelector} from "react-redux";
import {IUserCourseProgress} from "../../../interfaces/IUserCourseProgress";

const VideoSection = forwardRef((props: {
    step: number,
    section: ICourseSection,
    callback: any,
    setIsSavingEnd: any,
    setIsVideoReady: any,
    isFirstLoad: boolean,
    currentUserCourseProgress: IUserCourseProgress
}, cpRef) => {


    const theme = useMantineTheme();
    const {height, width} = useViewportSize();
    const {ref, toggle, fullscreen} = useFullscreen();
    const {id} = useParams();
    const {color} = useSelector((state: any) => state.theme)
    const [currentTime, setCurrentTime] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [totalTime, setTotalTime] = useState<number>(1)
    const [firstPaused, setFirstPaused] = useState<boolean>(false)

    const [isSavingProgress, setIsSavingProgress] = useState<boolean>(false)


    const videoRef = useRef<HTMLVideoElement>();
    const maxHeight = height - 290;


    useImperativeHandle(cpRef, () => ({
        initialize(i: IUserCourseProgress) {
            videoRef.current.currentTime = i.current_time;
            setCurrentTime(i.current_time);
        }
    }));

    useEffect(() => {
        if (!isLoading && videoRef.current) {
            //setTotalTime(Math.max(1, Math.min(props.section.duration, videoRef.current.duration)))
            // setTotalTime(Math.max(1, props.section.duration))
            if (props.isFirstLoad) {
                props.setIsVideoReady(true)
            }
        }
    }, [isLoading, videoRef])

    useEffect(() => {
        setCurrentTime(0)
        videoRef.current.pause();
        videoRef.current.src = props.section.url;
        if (props.currentUserCourseProgress && props.currentUserCourseProgress.active_section === props.section.id) {
            videoRef.current.currentTime = props.currentUserCourseProgress.current_time;
            setCurrentTime(props.currentUserCourseProgress.current_time);
        } else {
            videoRef.current.currentTime = 0;
            setCurrentTime(0)
        }
        setTotalTime(Math.max(1, props.section.duration))
        return () => {
            setCurrentTime(0)
        }
    }, [props.section])

    const playPause = () => {
        if (isLoading) return;
        if (videoRef.current) {
            if (!firstPaused) {
                videoRef.current.pause()
                setFirstPaused(true)
            } else {
                videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
            }
        } else {
            console.warn('No Video ref')
        }
    }

    const endVideo = () => {
        if (!props.currentUserCourseProgress) return;
        console.info('FUNCTION::endVideo')
        if (props.currentUserCourseProgress.step < props.section.order) {
            console.info('FUNCTION::endVideo -> exit, already saved')
            return;
        }

        props.setIsSavingEnd(true)
        CourseService.store(
            parseInt(id),
            props.step + 1,
            Math.round(currentTime),
        )
            .then((r) => {
                props.callback(r)
                setCurrentTime(0)
                videoRef.current.currentTime = 0;
            })
            .catch((e) => {
                genericError(e)
                if (e.response && e.response.data && isObject(e.response.data)) {
                    const data = e.response.data;
                    if ('current_time' in data) {
                        const serverCurrenTime = data.current_time;
                        videoRef.current.pause();
                        videoRef.current.currentTime = serverCurrenTime;
                    }
                }
            })
            .finally(() => props.setIsSavingEnd(false));
    }

    const saveProgress = () => {
        if (!props.currentUserCourseProgress) return;
        console.info('FUNCTION::saveProgress')
        if (!id || isSavingProgress) {
            console.info('FUNCTION::saveProgress -> exit, isSavingProgress')
            return;
        }

        if (Math.round(currentTime) === 0) {
            console.info('FUNCTION::saveProgress -> exit, currentTime === 0')
            return;
        }

        if (props.currentUserCourseProgress.active_section === props.section.id
            && Math.round(currentTime) === props.currentUserCourseProgress.current_time) {
            console.info('FUNCTION::saveProgress -> exit, active_section current time === current time')
            return;
        }

        if (props.section.order < props.currentUserCourseProgress.step) {
            console.info('FUNCTION::saveProgress -> exit, already saved')
            return;
        }

        setIsSavingProgress(true)
        CourseService.store(
            parseInt(id),
            props.step,
            Math.round(currentTime),
        ).catch((e) => {
            genericError(e)
            if (e.response && e.response.data && isObject(e.response.data)) {
                const data = e.response.data;
                if ('current_time' in data) {
                    const serverCurrenTime = data.current_time;
                    videoRef.current.pause();
                    videoRef.current.currentTime = serverCurrenTime;
                }
            }
        }).finally(() => setIsSavingProgress(false));
    }

    return <div>
        <Flex
            direction={'column'}
            justify={'center'}
            align={'center'}
            ref={ref}
            style={{
                position: 'relative',
                maxWidth: (maxHeight * 16 / 9),
            }}>

            <video
                controlsList="nodownload"
                onClick={playPause}
                controls={props.currentUserCourseProgress?.step > props.step}
                onLoadedData={() => setIsLoading(false)}
                onTimeUpdate={(e) => {
                    setCurrentTime(videoRef.current.currentTime)
                    if (
                        props.currentUserCourseProgress &&
                        Math.round(videoRef.current.currentTime % 10) === 0 &&
                        videoRef.current.currentTime !== props.currentUserCourseProgress.current_time && !isSavingProgress
                    ) {
                        console.info('saving progress...', props.currentUserCourseProgress, videoRef.current.currentTime)
                        saveProgress();
                    }
                }}
                onEnded={endVideo}
                ref={videoRef}
                style={{
                    width: '100%',
                    maxHeight: fullscreen ? 'unset' : maxHeight,
                    border: fullscreen ? 'none' : `1px solid ${color === 'light' ? theme.colors.gray[2] : theme.colors.dark[6]}`,
                    borderRadius: 6,
                    cursor: 'pointer'
                }}>
                <source src={''}
                        type={'video/mp4'}/>
            </video>

            {props.currentUserCourseProgress?.step > props.step ? <> </> : <>
                <Flex align={'center'} gap={16} mt={16} style={{
                    position: 'absolute',
                    left: 15,
                    right: 15,
                    bottom: 20
                }}>

                    <Progress
                        style={{flex: 1}}
                        size="sm"
                        value={currentTime * 100 / totalTime}
                    />
                </Flex>

                <Flex align={'center'} justify={'space-between'} style={{
                    position: 'absolute',
                    left: 15,
                    right: 15,
                    bottom: 40,
                    fontSize: '80%',
                }}>
                    <Flex align={'center'}>
                        <ActionIcon disabled={isLoading} onClick={playPause} mr={15}>
                            <FontAwesomeIcon icon={videoRef.current && videoRef.current.paused ? faPlay : faPause}
                                             size={'lg'}/>
                        </ActionIcon>
                        <Text
                            style={{
                                background: "rgba(255, 255, 255, 0.2)",
                            }}>{new Date(currentTime * 1000).toISOString().slice(11, 19)} / {new Date(totalTime * 1000).toISOString().slice(11, 19)}</Text>
                    </Flex>
                    <Flex>
                        <ActionIcon disabled={isLoading}>
                            <FontAwesomeIcon icon={faVolume} size={'lg'}/>
                        </ActionIcon>

                        <ActionIcon disabled={isLoading} onClick={toggle} ml={15}>
                            <FontAwesomeIcon icon={faExpand} size={'lg'}/>
                        </ActionIcon>
                    </Flex>
                </Flex>
            </>
            }
        </Flex>
    </div>
});
export default VideoSection;