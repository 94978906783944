import React, {useEffect, useState} from "react";
import {Alert, Anchor, Breadcrumbs, Button, Divider, Flex, List, Modal, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {genericError} from "../../functions/genericError";
import AugmentedFoodService from "../../services/apps/AugmentedFoodService";
import {DataTable} from "mantine-datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons/faEmptySet";
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync";
import {useDisclosure} from "@mantine/hooks";
import {useSelector} from "react-redux";
import advancedLang from "../../functions/advancedLang";

const AllFoodLists: React.FC = () => {
    const [opened, {open, close}] = useDisclosure(false);
    const navigate = useNavigate();
    const [fetchingFoodLists, setFetchingFoodLists] = useState<boolean>(false)
    const [foodLists, setFoodLists] = useState<any[]>([])
    const [selectedFoodList, setSelectedFoodList] = useState<any>();
    const [deletingId, setDeletingId] = useState<number>(-1);
    const {translations} = useSelector((state: any) => state.lang);

    useEffect(() => {
        setFetchingFoodLists(true)
        AugmentedFoodService.listFoodLists()
            .then(setFoodLists)
            .catch(genericError)
            .finally(() => setFetchingFoodLists(false))
    }, [])

    useEffect(() => {
        if (selectedFoodList) {
            open();
        } else {
            close();
        }
    }, [selectedFoodList])

    const deleteList = () => {
        if (!selectedFoodList) return;
        close();
        setDeletingId(selectedFoodList.id)
        AugmentedFoodService.deleteFoodList(selectedFoodList.id)
            .then(() => {
                const clone = structuredClone(foodLists);
                setFoodLists([...clone.filter(i => i.id !== selectedFoodList.id)])
            })
            .catch(genericError)
            .finally(() => setDeletingId(-1))
    }


    return <>
        <div style={{display: 'relative'}}>

            <Modal
                opened={opened}
                onClose={close}
                title={advancedLang(translations.deleteConfirm ?? ("translations.deleteConfirm " + selectedFoodList?.name), [selectedFoodList?.name])}
                transitionProps={{transition: 'fade', duration: 200}}
                withCloseButton
            >
                <Alert color={'yellow'} mb={24}>
                    {translations.deleteWarning ?? "translations.deleteWarning"}
                </Alert>
                <Flex justify={'space-between'}>
                    <Button color={'gray'}
                            onClick={close}>{translations.deleteBCancel ?? "translations.deleteBCancel"}</Button>
                    <Button color={'red'}
                            onClick={deleteList}>{translations.deleteBDelete ?? "translations.deleteBDelete"}</Button>
                </Flex>
            </Modal>


            <Breadcrumbs>
                <Anchor href={'#'} onClick={() => navigate('/apps')}>
                    {translations.breadcrumbsAllApps ?? "translations.breadcrumbsAllApps"}
                </Anchor>
                <Anchor href={'#'} onClick={() => navigate('/apps/food_exposure')}>
                    Food Exposure
                </Anchor>
                <span>{translations.breadcrumbFoodList ?? "translations.breadcrumbFoodList"}</span>
            </Breadcrumbs>

            <Divider mt={12} mb={12}/>

            <Title mb={24}>{translations.breadcrumbFoodList ?? "translations.breadcrumbFoodList"}</Title>
            <DataTable
                columns={
                    [
                        {accessor: 'id'},
                        {
                            accessor: 'name',
                            title: translations.nameTable ?? "translations.nameTable"
                        },
                        {
                            accessor: 'food',
                            title: translations.totalFoodTable ?? "translations.totalFoodTable",
                            render: (i) => {
                                return <>
                                    {i.food.length}
                                </>
                            }
                        },
                        {
                            accessor: 'state',
                            title: translations.totalConfigurationsTable ?? "translations.totalConfigurationsTable",
                            render: (i) => {
                                return <>
                                </>
                            }
                        },
                        {
                            accessor: 'actions',
                            title: translations.tableActions ?? "translations.tableActions",
                            render: (i) => {
                                return <>
                                    <Button variant={'subtle'} color={'red'}
                                            disabled={deletingId === i.id}
                                            loading={deletingId === i.id}
                                            onClick={() => setSelectedFoodList(i)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Button>
                                </>
                            }
                        }
                    ]
                }
                records={foodLists}
                minHeight={400}
                noRecordsIcon={
                    <FontAwesomeIcon icon={fetchingFoodLists ? faSync : faEmptySet}
                                     spin={fetchingFoodLists}
                                     size={'xl'} style={{marginBottom: 12}}/>
                }
                noRecordsText={
                    fetchingFoodLists ? (translations.fetchingLoading ?? "translations.fetchingLoading") : (translations.fetchingNoResults ?? "translations.fetchingNoResults")
                }
                rowExpansion={{
                    collapseProps: {
                        transitionDuration: 260,
                        animateOpacity: false,
                        transitionTimingFunction: 'ease-out',
                    },
                    content: ({record, collapse}) => (
                        <List
                            children={record.food.map(i => <List.Item>
                                    {i.name}
                                </List.Item>
                            )}
                        />
                    ),
                    // ...
                }}
            />
        </div>
    </>
}
export default AllFoodLists;